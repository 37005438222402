import { Select, SxProps } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Typography } from '../../1-primative';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { MenuItem } from '../menuItem/menuItem';

interface Option {
  label: string,
  type: string,
  avatar?: string,
}

interface SelectorOptionProps {
  option: Option,
  selectedOptionType?: string,
}

interface SelectorProps {
  options: Option[],
  selectedOptionType: string,
  onChange: (event: any) => void,
  renderValue?: (label: string) => string,
  sx?: SxProps,
  disabled?: boolean,
  optionsPosition?: 'right' | 'left'
}

const SelectorOption = ({ option, selectedOptionType }: SelectorOptionProps) => (
  <Box display='flex' alignItems='center' sx={{ width: selectedOptionType === option.type ? 'calc(100% - 16px)' : '100%' }}>
    {option.avatar && (
      <Box
        sx={{
          mr: 1,
          height: 20,
          width: 20,
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          borderRadius: 10,
          overflow: 'hidden',
        }}
      >
        <img alt={option.label} src={option.avatar} style={{ objectFit: 'cover', height: '100%' }} />
      </Box>
    )}
    <Typography
      variant='bodyMedium'
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...(option.avatar ? { width: 'calc(100% - 28px)' } : {}),
      }}
    >
      {option.label}
    </Typography>
  </Box>
);

export const Selector = ({
  options, selectedOptionType, onChange, sx, disabled, renderValue, optionsPosition = 'left',
}: SelectorProps) => {
  const { sys } = useThemeTokens();
  return (
    <Select
      value={selectedOptionType}
      disabled={disabled}
      onChange={onChange}
      renderValue={(value) => {
        const option = options.find((elem) => value === elem.type);
        if (option) {
          let newOption = { ...option };
          if (renderValue) newOption = { ...newOption, label: renderValue(newOption.label) };
          return <SelectorOption option={newOption} />;
        }
        return <></>;
      }}
      sx={{
        height: 32,
        borderRadius: sys.borderRadius.md,
        backgroundColor: sys.color.surfaceContainerVariant,
        '.MuiOutlinedInput-notchedOutline': { border: 'none' },
        '.MuiSelect-outlined': {
          padding: 0,
          paddingLeft: '12px',
          paddingRight: disabled ? '8px !important' : '28px !important',
          display: 'flex',
          alignItems: 'center',
        },
        '.MuiSvgIcon-root': {
          right: '4px',
          color: sys.color.onSurface,
          display: disabled ? 'none' : undefined,
        },
        ...sx,
      }}
      MenuProps={{
        anchorOrigin: { horizontal: optionsPosition, vertical: 33 },
        transformOrigin: { horizontal: optionsPosition, vertical: 0 },
        MenuListProps: {
          sx: {
            padding: 0,
            '&& .Mui-selected': { backgroundColor: sys.color.background },
            '&& .Mui-selected:focus': { backgroundColor: sys.color.background },
            '&& .Mui-selected:hover': { backgroundColor: sys.color.disabled },
            '& .MuiMenuItem-root': { backgroundColor: sys.color.background },
            '& .MuiMenuItem-root:hover': { backgroundColor: sys.color.disabled },
          },
        },
        PaperProps: {
          sx: { borderRadius: sys.borderRadius.md },
        },
      }}
    >
      {options.map((option, index) => (
        <MenuItem
          key={option.type}
          value={option.type}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '200px',
            paddingX: '12px',
            paddingY: '5px',
          }}
        >
          <SelectorOption option={option} selectedOptionType={selectedOptionType} />
          {selectedOptionType === option.type && (
            <Box
              sx={{
                width: '16px', height: '16px', fontSize: '16px', display: 'flex', alignItems: 'center',
              }}
            >
              <CheckIcon fontSize='inherit' />
            </Box>)
          }
        </MenuItem>
      ))}
    </Select>
  );
};
