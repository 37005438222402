import React, { useState } from 'react';
import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu } from '../../../2-component';
import { PageObjectType } from '../../../5-page';
import { EditGoal } from './pageTypes/editGoal';
import { EditSubAccount } from './pageTypes/editSubAccount';
import { EditAccount } from './pageTypes/editAccount';
import { usePermissions } from '../../../../providers/userContextProvider';
import { EditClient } from './pageTypes/editClient';

export const EditButton = ({
  objectId, objectType, options = {}, setActiveWorkflowCompletionId,
}: {
  objectId: string, objectType: PageObjectType, options?: any, setActiveWorkflowCompletionId?: (id: string) => void,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { permissions } = usePermissions();
  const open = Boolean(anchorEl);
  const { canForceOpenAccount } = options;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editMenu = () => {
    switch (objectType) {
      case PageObjectType.GOAL:
        return (<EditGoal objectId={objectId} onClose={handleClose} canClose={options.canCloseGoal === true || !('canCloseGoal' in options)} />);
      case PageObjectType.ACCOUNT:
        return (<EditAccount objectId={objectId} onClose={handleClose} options={options} />);
      case PageObjectType.SUB_ACCOUNT:
        return <EditSubAccount objectId={objectId} onClose={handleClose}/>;
      case PageObjectType.INDIVIDUAL:
      case PageObjectType.NON_INDIVIDUAL:
        return <EditClient objectId={objectId} options={options} onClose={handleClose} setActiveWorkflowCompletionId={setActiveWorkflowCompletionId}/>;
      default:
        return null;
    }
  };

  if (!editMenu()) return null;

  // sub-account & account edit menu button only has one option (close sub-account/account). removed the menu drop down if the
  // option "close sub-account/account" is not to be shown.
  const canEditAccount = (permissions.includes('write:account_number') && (!!options?.nickName?.enabled || (!!options?.custodianAccountNumber?.enabled && permissions.includes('read:account_number'))))
    || (!!options?.state?.enabled && permissions.includes('transition:account') && permissions.includes('read:account_basic'))
    || (('canCloseAccount' in options) && options.canCloseAccount && (permissions.includes('write:account_basic') || permissions.includes('transition:account')));
  if (objectType === PageObjectType.ACCOUNT && (!!options.enableAccountEditing === false || !canEditAccount) && (!!options.enableAccountEditing === false || !canForceOpenAccount)) return (<></>);
  if (objectType === PageObjectType.SUB_ACCOUNT && (('canCloseSubAccount' in options) && options.canCloseSubAccount === false)) return (<></>);
  if ([PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType)
    && !options.canTriggerWorkflows
    && !options.canChangeOrganization
    && !options.canChangeHousehold
    && !options.canFreezeClient
    && !options.canDeactivateClient) {
    return (<></>);
  }

  return (
    <>
      <IconButton data-testid='edit-menu' onClick={handleClick} sx={{ ml: 1 }}>
        <MoreVert />
      </IconButton>
      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        id="edit-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {editMenu()}
      </Menu>
    </>
  );
};
