/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  Box, Grid, Skeleton, Typography,
} from '../../../1-primative';
import {
  Card, CardContent,
  DateField,
  MenuItem,
  Pagination,
  SelectField,
  Table, TableBody, TableCell, TableHeadCell, TableRow,
} from '../../../2-component';
import {
  SubAccountSelect, AccountTypeSelect, FilterModal, DownloadButton,
  Column,
  ExportApiReportFormatters,
} from '../../../3-pattern';
import { TransactionTypes } from '../../../../interfaces/transaction';
import { PageObjectType } from '../../../5-page';
import { translateBackend } from '../../../../assets/i18n/config';
import { LedgerCell } from './components/ledgerCell';
import { usePermissions } from '../../../../providers/userContextProvider';
import NewTransaction from './components/newTransaction';
import UpdateTransaction from './components/updateTransaction';

const FETCH_TRANSACTIONS_QUERY = `query fetchTransactions($input: FetchTransactionsInput!) {
  fetchTransactions(input: $input) {
    transactions {
      id
      valueCents
      date
      description
      currency
      type
      quantity
      priceCents
      bookValueCents
      financialProduct {
        id
        ticker
      }
      account {
        id
        type
        user {
          id
          firstName
          lastName
        }
      }
      subAccount {
        id
        goal {
          name
          type
        }
      }
    }
    totalCount
  }
}`;
const FETCH_LEDGER = gql`${FETCH_TRANSACTIONS_QUERY}`;

const FETCH_CUSTODIAN_TRANSACTIONS_QUERY = `query fetchCustodianTransactions($input: FetchCustodianTransactionsInput!) {
  fetchCustodianTransactions(input: $input) {
    transactions {
      valueCents
      date
      description
      currency
      type
      quantity
      priceCents
      bookValueCents
      custodianTransactionType
      financialProduct {
        ticker
      }
      account {
        id
        type
        user {
          id
          firstName
          lastName
        }
      }
    }
    totalCount
  }
}`;
const FETCH_CUSTODIAN_LEDGER = gql`${FETCH_CUSTODIAN_TRANSACTIONS_QUERY}`;

const DEFAULT_TABLE = [
  {
    label: {
      en: 'Account',
      fr: 'Compte',
    },
    type: 'account',
  },
  {
    label: {
      en: 'Type',
      fr: 'Type',
    },
    type: 'type',
  },
  {
    label: {
      en: 'Currency',
      fr: '',
    },
    type: 'currency',
  },
  {
    label: {
      en: 'Cost Price',
      fr: 'Prix d\'achat',
    },
    type: 'costPrice',
  },
  {
    label: {
      en: 'Quantity',
      fr: 'Quantité',
    },
    type: 'quantity',
  },
  {
    label: {
      en: 'Value',
      fr: 'Valeur',
    },
    type: 'value',
  },
  {
    label: {
      en: 'Date',
      fr: 'Date',
    },
    type: 'date',
  },
];

const baseFilter = (objectType: PageObjectType, objectId: string) => {
  switch (objectType) {
    case PageObjectType.INDIVIDUAL:
      return { userId: objectId };
    case PageObjectType.NON_INDIVIDUAL:
      return { userId: objectId };
    case PageObjectType.HOUSEHOLD:
      return { clientGroupId: objectId };
    case PageObjectType.GOAL:
      return { goalIds: [objectId] };
    case PageObjectType.ACCOUNT:
      return { accountIds: [objectId] };
    case PageObjectType.SUB_ACCOUNT:
      return { subAccountIds: [objectId] };
    default:
      return {};
  }
};

const baseSubAccountFilter = (objectType: PageObjectType, objectId: string, filter: any) => {
  const availableAccountType = filter.accountType && filter.accountType !== 'ANY' ? filter.accountType : undefined;
  switch (objectType) {
    case PageObjectType.INDIVIDUAL:
      return { userId: objectId, accountType: availableAccountType };
    case PageObjectType.NON_INDIVIDUAL:
      return { userId: objectId, accountType: availableAccountType };
    case PageObjectType.HOUSEHOLD:
      return { clientGroupId: objectId, accountType: availableAccountType };
    case PageObjectType.GOAL:
      return { goalId: objectId, accountType: availableAccountType };
    default:
      return { accountType: availableAccountType };
  }
};

export const Ledger = ({ objectId, objectType, options }: { objectId: string, objectType: PageObjectType, options: any }) => {
  const { t } = useTranslation(['components', 'shared', 'accountTypes']);
  const { permissions } = usePermissions();
  const [filter, setFilter] = useState<any>({});
  const [page, setPage] = useState<any>(1);
  const [pagination, setPagination] = useState<any>({
    offSet: 0, perPage: 15,
  });
  const [transactions, setTransactions] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [activeTransaction, setActiveTransaction] = useState({
    id: '',
    type: TransactionTypes.ADJUSTMENT,
  });

  const useCustodianData = [PageObjectType.ACCOUNT, PageObjectType.HOUSEHOLD, PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType) && options.useCustodianStatistics;
  const enableAccountTypeFilter = [PageObjectType.HOUSEHOLD, PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType);
  const [fetchLedger, { loading, refetch }] = useLazyQuery(FETCH_LEDGER, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      setTransactions(data.fetchTransactions.transactions);
      setTotalCount(Number(data.fetchTransactions.totalCount || 0));
    },
  });

  const [fetchCustodianLedger, { loading: custodianLoading }] = useLazyQuery(FETCH_CUSTODIAN_LEDGER, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: any) => {
      setTransactions(data.fetchCustodianTransactions.transactions);
      setTotalCount(Number(data.fetchCustodianTransactions.totalCount || 0));
    },
  });

  const dateAfter = dayjs(filter?.dateAfter).format('YYYY-MM-DD');
  const dateBefore = dayjs(filter?.dateBefore).format('YYYY-MM-DD');

  const queryFilter = {
    ...(useCustodianData ? {} : { subAccountIds: filter.subAccount ? [filter.subAccount.id] : undefined }),
    ...baseFilter(objectType, objectId),
    ...(!enableAccountTypeFilter ? {} : { accountType: filter.accountType || undefined }),
    types: filter.type ? [filter.type] : undefined,
    dateAfter: filter.dateAfter && dayjs(dateAfter).isValid() ? dateAfter : undefined,
    dateBefore: filter.dateBefore && dayjs(dateBefore).isValid() ? dateBefore : undefined,
  };

  useEffect(() => {
    const variables = {
      input: {
        filter: queryFilter,
        pagination: {
          ...pagination, sortField: 'date', sortDesc: false,
        },
      },
    };
    if (useCustodianData) {
      fetchCustodianLedger({ variables });
    } else {
      fetchLedger({ variables });
    }
  }, [objectType, objectId, filter, pagination]);

  useEffect(() => {
    setPagination({ offSet: (page - 1) * pagination.perPage, perPage: pagination.perPage });
  }, [page]);

  const columns: Column[] = (options.table || DEFAULT_TABLE).map((elem: any) => {
    switch (elem.type) {
      case 'account':
        return {
          gqlAlias: 'account.type',
          header: translateBackend(elem.label),
        };
      case 'type':
        return {
          gqlAlias: 'type',
          header: translateBackend(elem.label),
        };
      case 'currency':
        return {
          gqlAlias: 'currency',
          header: translateBackend(elem.label),
        };
      case 'costPrice':
        return {
          gqlAlias: 'priceCents',
          header: translateBackend(elem.label),
          formatter: ExportApiReportFormatters.DIVIDE_BY_100,
        };
      case 'quantity':
        return {
          gqlAlias: 'quantity',
          header: translateBackend(elem.label),
        };
      case 'value':
        return {
          gqlAlias: 'valueCents',
          header: translateBackend(elem.label),
          formatter: ExportApiReportFormatters.DIVIDE_BY_100,
        };
      case 'date':
        return {
          gqlAlias: 'date',
          header: translateBackend(elem.label),
        };
      case 'bookValue':
        return {
          gqlAlias: 'bookValueCents',
          header: translateBackend(elem.label),
          formatter: ExportApiReportFormatters.DIVIDE_BY_100,
        };
      case 'description':
        return {
          gqlAlias: 'description',
          header: translateBackend(elem.label),
        };
      case 'custodianType':
        return {
          gqlAlias: 'custodianTransactionType',
          header: translateBackend(elem.label),
        };
      default:
        return {};
    }
  });

  return (
    <>
      <Typography variant='headingSmall'>{options.customTitle ? translateBackend(options.customTitle) : t('components:transaction.title')}</Typography>
      <Card sx={{ mt: 2 }}>
        <CardContent sx={{ pb: 2 }}>
          <Box display='flex' justifyContent='end' width='100%' alignItems='end' >
            <Box display='flex'>
              <FilterModal filterExists={filter.type || filter.subAccount || filter.dateAfter || filter.dateBefore}>
                <SelectField
                  value={filter.type}
                  label={t('components:type')}
                  onChange={(e: any) => setFilter({ ...filter, type: e.target.value })}
                  fullWidth
                  placeholder={t('components:any')}
                  sx={{ mb: 2 }}
                  clearable
                >
                  {Object.values(TransactionTypes).map((x: TransactionTypes) => (
                    <MenuItem value={x} key={x}>
                      {t(`components:transaction.types.${x}`)}
                    </MenuItem>
                  ))}
                </SelectField>
                {enableAccountTypeFilter && (
                  <Grid container spacing={1} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={12} md={12}>
                      {![PageObjectType.SUB_ACCOUNT, PageObjectType.ACCOUNT].includes(objectType) && (
                        <AccountTypeSelect
                          omitAny
                          householdId={objectType === PageObjectType.HOUSEHOLD ? objectId : undefined}
                          entityId={objectType === PageObjectType.INDIVIDUAL ? objectId : undefined}
                          size='small'
                          value={filter.accountType}
                          onChange={(accountType: any) => setFilter({ ...filter, accountType })}
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
                {objectType !== PageObjectType.SUB_ACCOUNT && !useCustodianData && (
                  <SubAccountSelect
                    sx={{ mb: 2 }}
                    filter={baseSubAccountFilter(objectType, objectId, filter)}
                    selectedSubAccount={filter.subAccount}
                    onSubAccountSelect={(subAccount: any) => setFilter({ ...filter, subAccount })}
                    label={t('components:subAccount')}
                  />
                )}
                <DateField label={t('components:fromDate')} value={filter.dateAfter} onChange={(e: any) => setFilter({ ...filter, dateAfter: e })} fullWidth sx={{ mb: 2 }} />
                <DateField label={t('components:toDate')} value={filter.dateBefore} onChange={(e: any) => setFilter({ ...filter, dateBefore: e })} fullWidth sx={{ mb: 2 }} />
              </FilterModal>
              {(options.enableDownload && permissions.includes('read:api_exports')) && <Box sx={{ ml: 1 }}>
                <DownloadButton
                  gql={useCustodianData ? FETCH_CUSTODIAN_TRANSACTIONS_QUERY : FETCH_TRANSACTIONS_QUERY}
                  fileName={`${t('components:transaction.fileName')}.${dayjs().format('YYYY-MM-DD')}`}
                  filter={queryFilter}
                  queryPath={useCustodianData ? 'fetchCustodianTransactions' : 'fetchTransactions'}
                  datasetPath='transactions'
                  columns={columns}
                  sortField='date'
                  sortDesc={false}
                  progressTitle={t('components:transaction.progressTitle')}
                />
              </Box>}
              { options.canAdd && (
                  <Box sx={{ ml: 1 }}>
                    <NewTransaction
                      forObject={objectType}
                      forId={objectId}
                      afterCreate={refetch}
                    />
                  </Box>
              )}
            </Box>
          </Box>
        </CardContent>
        <Box sx={{ overflowX: 'auto' }}>
          <Table>
            <TableBody>
              <TableRow>
                { (options.table || DEFAULT_TABLE).map((x: any, idx: number) => (
                  <TableHeadCell key={x} isFirst={idx === 0} right={![
                    'account', 'type', 'date',
                    'currency', 'description', 'custodianType',
                    'bookValue', 'security',
                  ].includes(x.type)}>{translateBackend(x.label)}</TableHeadCell>
                ))}
              </TableRow>
              { (useCustodianData ? custodianLoading : loading) ? (
                <>
                  {[...Array(15)].map((x, i) => (
                    <TableRow key={i}>
                      { (options.table || DEFAULT_TABLE).map((_: any, idx: number) => (
                        <TableCell dense isFirst={idx === 0}><Skeleton width='100%' height='16px' /></TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              ) : (
                <>
                  {transactions.map((transaction: any) => (
                    <TableRow key={transaction.id} hover pointer={options.canEdit} onClick={() => {
                      if (options.canEdit) {
                        setActiveTransaction(transaction);
                        setOpen(true);
                      }
                    }}>
                      { (options.table || DEFAULT_TABLE).map((x: any, idx: number) => (
                        <LedgerCell isFirst={idx === 0} key={x.key} transaction={transaction} type={x.type} view={objectType} />
                      ))}
                    </TableRow>
                  ))}
                </>
              )}
              {transactions.length === 0 && !(useCustodianData ? custodianLoading : loading) && (
                <TableRow>
                  <TableCell colSpan={(options.table || DEFAULT_TABLE).length} sx={{ textAlign: 'center' }}>{t('components:transaction.noTransactions')}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
        <UpdateTransaction open={open} handleClose={() => setOpen(false)} transaction={activeTransaction} afterUpdate={refetch} options={options} />
        <Box display='flex' justifyContent='flex-end' sx={{ p: 1 }}>
          <Pagination
            size='small'
            page={page}
            perPage={pagination.perPage}
            count={Math.ceil(totalCount / pagination.perPage)}
            onChange={(e, newPage) => setPage(newPage)}
            onChangePerPage={(newPerPage) => setPagination({ ...pagination, perPage: newPerPage })}
          />
        </Box>
      </Card>
    </>
  );
};
