export const WIDGET_TYPES: any = {
  INDIVIDUAL: [
    'MARKET_VALUE_CHART',
    'RATE_OF_RETURNS',
    'ACCOUNTS',
    'GOALS',
    'HOLDINGS',
    'LEDGER',
    'PROJECTED_INCOME_REPORT',
    'FEES',
    'HTML_TEMPLATE',
  ],
  NON_INDIVIDUAL: [
    'MARKET_VALUE_CHART',
    'RATE_OF_RETURNS',
    'ACCOUNTS',
    'GOALS',
    'HOLDINGS',
    'LEDGER',
    'PROJECTED_INCOME_REPORT',
    'FEES',
    'HTML_TEMPLATE',
  ],
  GOAL: [
    'MARKET_VALUE_CHART',
    'RATE_OF_RETURNS',
    'HOLDINGS',
    'LEDGER',
    'SUB_ACCOUNTS',
    'HTML_TEMPLATE',
  ],
  ACCOUNT: [
    'MARKET_VALUE_CHART',
    'RATE_OF_RETURNS',
    'HOLDINGS',
    'LEDGER',
    'SUB_ACCOUNTS',
    'PROJECTED_INCOME_REPORT',
    'FEES',
    'HTML_TEMPLATE',
  ],
  SUB_ACCOUNT: [
    'MARKET_VALUE_CHART',
    'RATE_OF_RETURNS',
    'HOLDINGS',
    'LEDGER',
    'FEES',
    'HTML_TEMPLATE',
  ],
  HOUSEHOLD: [
    'MARKET_VALUE_CHART',
    'RATE_OF_RETURNS',
    'ACCOUNTS',
    'GOALS',
    'HOLDINGS',
    'LEDGER',
    'PROJECTED_INCOME_REPORT',
    'FEES',
    'HTML_TEMPLATE',
  ],
};
