import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { OpenInNew } from '@mui/icons-material';
import { FETCH_FILE_DOCUMENTS } from '../documents.queries';
import { Box, Skeleton, Typography } from '../../../../1-primative';
import { Pagination } from '../../../../2-component';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { UploadDocument } from './uploadDocument';
import { useLocalization } from '../../../../../util/useLocalization';
import { PageObjectType } from '../../../../5-page';

export const UploadedDocuments = ({
  objectId, documentTypes, open, setOpen, objectType, userId,
}: {
  objectId: string, documentTypes: string[], open: boolean, setOpen: (o: boolean) => void, objectType?: PageObjectType, userId?: string,
}) => {
  const { t } = useTranslation(['document']);
  const { localizedDate } = useLocalization();
  const { sys } = useThemeTokens();
  const [page, setPage] = useState<number>(1);

  const { data, loading, refetch } = useQuery(FETCH_FILE_DOCUMENTS, {
    variables: {
      input: {
        filter: {
          ...(objectType === PageObjectType.ACCOUNT && { objectIds: [objectId] }),
          ...(objectType === PageObjectType.ACCOUNT ? { userIds: userId ? [userId] : undefined } : { userIds: [objectId] }),
          types: documentTypes,
        },
        pagination: {
          offSet: (page - 1) * 15,
          perPage: 15,
        },
      },
    },
  });

  if (loading) {
    return (
      <>
        <Box width='100%' pt={2}>
          <Skeleton width='120px' height='24px' sx={{ display: 'inline-block', ml: 1 }} />
          <Skeleton width='200px' height='24px' sx={{ display: 'inline-block', ml: 1 }} />
        </Box>
        <Box width='100%' pt={2}>
          <Skeleton width='120px' height='24px' sx={{ display: 'inline-block', ml: 1 }} />
          <Skeleton width='200px' height='24px' sx={{ display: 'inline-block', ml: 1 }} />
        </Box>
        <Box width='100%' pt={2}>
          <Skeleton width='120px' height='24px' sx={{ display: 'inline-block', ml: 1 }} />
          <Skeleton width='200px' height='24px' sx={{ display: 'inline-block', ml: 1 }} />
        </Box>
      </>
    );
  }

  if (!data) return <></>;

  return (
    <Box width='100%'>
      <UploadDocument open={open} setOpen={setOpen} refetch={refetch} objectId={objectId} objectType={objectType} userId={userId} />
      {
        data.fetchFileDocuments.totalCount === 0 && (
          <Typography variant='bodyLarge' sx={{ textAlign: 'center', mt: 2 }}>{t('document:noDocuments')}</Typography>
        )
      }
      {
        data.fetchFileDocuments.fileDocuments.map((document: any) => (
          <Box
            key={document.id}
            display='flex'
            pl={2}
            pt={1}
            pb={1}
            pr={2}
            justifyContent='space-between'
            alignItems='center'
            onClick={() => window.open(document.downloadUrl, '_blank')}
            sx={{
              borderRadius: sys.borderRadius.md,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: sys.color.surfaceContainerVariant,
              },
            }}
          >
            <Box width='100%' display='flex'>
              <Typography variant='bodyMedium' sx={{ minWidth: '120px', color: sys.color.onSurfaceVariant }}>
                {localizedDate(document.uploadedAt)}
              </Typography>
              <Typography variant='bodyMedium'>{document.fileName}</Typography>
            </Box>
            <OpenInNew sx={{ color: sys.color.onSurfaceVariant }} />
          </Box>
        ))
      }
      <Box display='flex' justifyContent='end' mt={2}>
        <Pagination page={page} onChange={(e: any, newPage: number) => setPage(newPage)} count={Math.ceil(data.fetchFileDocuments.totalCount / 15)} size='small' />
      </Box>
    </Box>
  );
};
