/* eslint-disable operator-linebreak */
/* eslint-disable no-return-assign */
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PreviewIcon from '@mui/icons-material/Preview';
import DraftsIcon from '@mui/icons-material/Drafts';
import EditIcon from '@mui/icons-material/Edit';
import { gql, useQuery } from '@apollo/client';
import DOMPurify from 'dompurify';
import { FormTemplate } from '../../../interfaces';
import {
  Badge,
  Button,
  Card,
  IconButton,
} from '../../2-component';
import { Box, Skeleton, Typography } from '../../1-primative';
import { translateBackend } from '../../../assets/i18n/config';
import TemplateViewerModal from './components/templateViewerModal';
import TitleEdit from './components/titleEdit';

const FETCH_FORM_TEMPLATE = gql`
  query fetchFormTemplate($input: FetchFormTemplateInput!) {
    fetchFormTemplate(input: $input) {
      id
      organization {
        id
        name
      }
      state
      type
      templateUrl
      minVersion
      translatedHtml {
        en
        fr
      }
      updatedAt
      translatedDisplayName {
        en
        fr
      }
    }
  }
`;

const EditFormTemplate = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(['shared', 'client', 'orgSettings', 'components']);
  const [viewRecord, setViewRecord] = useState<FormTemplate | null | undefined>(null);
  const [selectedRecord, setSelectedRecord] = useState<FormTemplate>();
  const editorRef = useRef<any>(null);
  const [openTitleEdit, setOpenTitleEdit] = useState(false);
  const [content, setContent] = useState('');
  const { loading, data } = useQuery(FETCH_FORM_TEMPLATE, {
    variables: {
      input: {
        formTemplateId: id,
      },
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const safeHTML: any = data?.fetchFormTemplate?.translatedHtml?.en;
    setContent(DOMPurify.sanitize(safeHTML, { RETURN_TRUSTED_TYPE: true }).toString());
    setSelectedRecord(data?.fetchFormTemplate);
  }, [data]);

  const onEditorChange = (htmlContent: string, editor: any) => {
    setContent(DOMPurify.sanitize(htmlContent, { RETURN_TRUSTED_TYPE: true }).toString());
  };

  return (
    <>
      {loading ? (
        <Box m={1}>
          <Skeleton width='100%' height='34px' variant='rectangular' animation='wave' />
        </Box>
      ) : (
        <>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Card sx={{ p: 2 }}>
                <Box display='flex' justifyContent='space-between'>
                  <Box display={'flex'} alignItems={'center'} gap={2}>
                    <Typography variant='headingLarge'>
                      {selectedRecord?.translatedDisplayName ? translateBackend(selectedRecord?.translatedDisplayName) : t('orgSettings:documentManagement.noTitle')}
                    </Typography>
                    <Badge label={t(`orgSettings:documentManagement.templateState.${selectedRecord?.state}`)} color={selectedRecord?.state === 'ACTIVE' ? 'positive' : 'neutral'} />
                  </Box>
                  <IconButton
                    onClick={() => {
                      setOpenTitleEdit(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant='headingXSmall'>
                    {t('orgSettings:documentManagement.minVersionLable')} {selectedRecord?.minVersion}
                  </Typography>
                </Box>
            </Card>
          </Box>
          <Box mt={2}>
            <Editor
              id='mceEditor'
              onEditorChange={onEditorChange}
              value={content}
              apiKey={process.env.REACT_APP_TINYMCE_API_KEY ?? ''} // will be coming from env variable
              onInit={(evt: any, editor: any) => (editorRef.current = editor)}
              init={{
                hidden_input: true,
                height: 577,
                menubar: true,
                plugins: [
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'charmap',
                  'preview',
                  'anchor',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  'fullscreen',
                  'insertdatetime',
                  'table',
                  'code',
                ],
                toolbar:
                  'variable | save | undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                branding: false,
              }}
            />
          </Box>
          <Box bottom={0} height='80px' display='flex' justifyContent='end' sx={{
            padding: '24px 8px 24px 20px',
          }}>
            <Button
              sx={{ ml: 1, mr: 1 }}
              onClick={() => {
                // save draft
              }}
              type='button'
              variant='outlined'
              leadingIcon={DraftsIcon}
              label={''}
            >
              {t('orgSettings:documentManagement.action.draft')}
            </Button>
            <Button
              sx={{
                ml: 1,
                mr: 1,
              }}
              onClick={() => {
                setViewRecord(selectedRecord);
              }}
              type='button'
              variant='tonal'
              leadingIcon={RemoveRedEyeIcon}
              label={''}
            >
              {t('orgSettings:documentManagement.action.preview')}
            </Button>
            <Button
              sx={{ ml: 1 }}
              onClick={() => {
                // publish logic will goes here
              }}
              type='button'
              variant='filled'
              leadingIcon={PreviewIcon}
              label={''}
            >
              {t('orgSettings:documentManagement.action.publish')}
            </Button>
          </Box>
        </>
      )}
      {viewRecord && (
        <TemplateViewerModal
          formTemplate={viewRecord}
          origin='EDITOR'
          onModalClose={() => {
            setViewRecord(null);
          }}
        />
      )}
      <TitleEdit
        formTemplate={selectedRecord}
        openDialog={openTitleEdit}
        setOpenDialog={setOpenTitleEdit}
        value={selectedRecord?.translatedDisplayName ?? {}}
        onChange={(titleData: any) => {
          if (selectedRecord) {
            setSelectedRecord({
              ...selectedRecord,
              translatedDisplayName: titleData,
            });
          }
        }}
      />
    </>
  );
};
export default EditFormTemplate;
