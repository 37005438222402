import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useClientReportContext } from '../../../../providers/clientReportContextProvider';
import { RefBox, Typography } from '../../../1-primative';
import { Card } from '../../../2-component/card/card';
import { PageObjectType } from '../../../5-page';
import { chooseStatsQuery } from './rateOfReturns.queries';
import { translateBackend } from '../../../../assets/i18n/config';
import { RateOfReturnsTable } from './components/rateOfReturnTable';
import { PrintContext, PAGE_HEIGHT } from '../../../5-page/viewClientReport/components/printReport';

export enum GroupOn {
  USER = 'user',
  HOUSEHOLD = 'household',
  ACCOUNT = 'account',
  GOAL = 'goal',
  SUB_ACCOUNT = 'subAccount',
}

export const RateOfReturns = ({
  objectType, objectId, options, i = 0, print = false,
}: { objectType: PageObjectType; objectId: string; options?: any; i?: number; print?: boolean }) => {
  const STATS_ARGS = `
    mwrr: statistics @include(if: $mwrr) {
      ${options.oneMonth ? 'oneMonth: moneyWeightedReturn(startDate: $oneMonthStart, endDate: $endDate)' : ''}
      ${options.threeMonth ? 'threeMonth: moneyWeightedReturn(startDate: $threeMonthStart, endDate: $endDate)' : ''}
      ${options.sixMonth ? 'sixMonth: moneyWeightedReturn(startDate: $sixMonthStart, endDate: $endDate)' : ''}
      ${options.oneYear ? 'oneYear: moneyWeightedReturn(startDate: $oneYearStart, endDate: $endDate)' : ''}
      ${options.threeYear ? 'threeYear: moneyWeightedReturn(startDate: $threeYearStart, endDate: $endDate)' : ''}
      ${options.fiveYear ? 'fiveYear: moneyWeightedReturn(startDate: $fiveYearStart, endDate: $endDate)' : ''}
      ${options.yearToDate ? 'yearToDate: moneyWeightedReturn(startDate: $ytdStart, endDate: $endDate)' : ''}
      ${options.sinceInception ? 'sinceInception: moneyWeightedReturn(startDate: $sinceInceptionStart, endDate: $endDate)' : ''}
    }
    twrr: statistics @include(if: $twrr) {
      ${options.oneMonth ? 'oneMonth: timeWeightedReturn(startDate: $oneMonthStart, endDate: $endDate)' : ''}
      ${options.threeMonth ? 'threeMonth: timeWeightedReturn(startDate: $threeMonthStart, endDate: $endDate)' : ''}
      ${options.sixMonth ? 'sixMonth: timeWeightedReturn(startDate: $sixMonthStart, endDate: $endDate)' : ''}
      ${options.oneYear ? 'oneYear: timeWeightedReturn(startDate: $oneYearStart, endDate: $endDate)' : ''}
      ${options.threeYear ? 'threeYear: timeWeightedReturn(startDate: $threeYearStart, endDate: $endDate)' : ''}
      ${options.fiveYear ? 'fiveYear: timeWeightedReturn(startDate: $fiveYearStart, endDate: $endDate)' : ''}
      ${options.yearToDate ? 'yearToDate: timeWeightedReturn(startDate: $ytdStart, endDate: $endDate)' : ''}
      ${options.sinceInception ? 'sinceInception: timeWeightedReturn(startDate: $sinceInceptionStart, endDate: $endDate)' : ''}
    }`;

  const tableMap = [
    { freq: 'oneMonth' },
    { freq: 'threeMonth' },
    { freq: 'sixMonth' },
    { freq: 'oneYear' },
    { freq: 'threeYear' },
    { freq: 'fiveYear' },
    { freq: 'yearToDate' },
    { freq: 'sinceInception' },
  ].filter(({ freq }) => options[freq]);

  const { timePeriod, objectCreatedAt } = useClientReportContext();
  const {
    groupOnIndividualNonIndividual, groupOnAccount, groupOnGoal, groupOnHousehold,
  } = options;
  const [mwrrPageBreak, setMwrrPageBreak] = useState(false);
  const [twrrPageBreak, setTwrrPageBreak] = useState(false);
  const [totalPageBreak, setTotalPageBreak] = useState(false);
  const mwrrRef = useRef<HTMLInputElement | null>(null);
  const twrrRef = useRef<HTMLInputElement | null>(null);
  const totalRef = useRef<HTMLInputElement | null>(null);
  const [previousPageHeight, setPreviousPageHeight] = useState(0);
  const { setOptions, options: printOptions } = useContext(PrintContext);
  const [groupOn, setGroupOn] = useState('');
  const [objectPath, setObjectPath] = useState<any>();
  const [tableLayout, setTableLayout] = useState('row');
  const { data: statsData, loading: statsLoading } = useQuery(chooseStatsQuery({ objectType, statistics: STATS_ARGS, options }), {
    variables: {
      id: objectId,
      oneMonthStart: dayjs(timePeriod.endDate).date(1).format('YYYY-MM-DD'),
      threeMonthStart: dayjs(timePeriod.endDate).subtract(2, 'month').date(1).format('YYYY-MM-DD'),
      sixMonthStart: dayjs(timePeriod.endDate).subtract(5, 'month').date(1).format('YYYY-MM-DD'),
      oneYearStart: dayjs(timePeriod.endDate).subtract(1, 'year').date(1).format('YYYY-MM-DD'),
      threeYearStart: dayjs(timePeriod.endDate).subtract(3, 'year').date(1).format('YYYY-MM-DD'),
      fiveYearStart: dayjs(timePeriod.endDate).subtract(5, 'year').date(1).format('YYYY-MM-DD'),
      ytdStart: dayjs().month(0).date(1).format('YYYY-MM-DD'),
      sinceInceptionStart: dayjs(objectCreatedAt).format('YYYY-MM-DD'),
      endDate: dayjs(timePeriod.endDate).format('YYYY-MM-DD'),
      mwrr: options?.showMwrr,
      twrr: options?.showTwrr,
    },
  });

  useEffect(() => {
    if (tableMap.length <= 4) {
      setTableLayout('row');
    } else {
      setTableLayout('column');
    }
  }, [tableMap.length, options]);

  useEffect(() => {
    switch (objectType) {
      case PageObjectType.INDIVIDUAL:
      case PageObjectType.NON_INDIVIDUAL:
        setGroupOn(groupOnIndividualNonIndividual);

        if (groupOn === GroupOn.USER) {
          setObjectPath(statsData?.fetchUser?.user);
        }
        if (groupOn === GroupOn.GOAL) {
          setObjectPath(statsData?.fetchUser?.user?.goals);
        }
        if (groupOn === GroupOn.ACCOUNT) {
          setObjectPath(statsData?.fetchUser?.user?.accounts);
        }
        if (groupOn === GroupOn.SUB_ACCOUNT) {
          setObjectPath(statsData?.fetchUser?.user?.subAccounts);
        }
        break;
      case PageObjectType.HOUSEHOLD:
        setGroupOn(groupOnHousehold);
        if (groupOn === GroupOn.HOUSEHOLD) {
          setObjectPath(statsData?.fetchClientGroup?.clientGroup);
        }
        if (groupOn === GroupOn.USER) {
          setObjectPath(statsData?.fetchClientGroup?.clientGroup?.primaryUser);
        }
        if (groupOn === GroupOn.GOAL) {
          setObjectPath(statsData?.fetchClientGroup?.clientGroup?.goals);
        }
        if (groupOn === GroupOn.ACCOUNT) {
          setObjectPath(statsData?.fetchClientGroup?.clientGroup?.accounts);
        }
        if (groupOn === GroupOn.SUB_ACCOUNT) {
          setObjectPath(statsData?.fetchClientGroup?.clientGroup?.subAccounts);
        }
        break;
      case PageObjectType.ACCOUNT:
        setGroupOn(groupOnAccount);
        if (groupOn === GroupOn.ACCOUNT) {
          setObjectPath(statsData?.fetchAccount?.account);
        }
        if (groupOn === GroupOn.SUB_ACCOUNT) {
          setObjectPath(statsData?.fetchAccount?.account?.subAccounts);
        }
        break;
      case PageObjectType.GOAL:
        setGroupOn(groupOnGoal);
        if (groupOn === GroupOn.GOAL) {
          setObjectPath(statsData?.fetchGoal?.goal);
        }
        if (groupOn === GroupOn.SUB_ACCOUNT) {
          setObjectPath(statsData?.fetchGoal?.goal?.subAccounts);
        }
        break;
      case PageObjectType.SUB_ACCOUNT:
        setGroupOn(GroupOn.SUB_ACCOUNT);
        setObjectPath(statsData?.fetchSubAccount?.subAccount);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupOn, objectType, options, statsLoading]);

  useEffect(() => {
    const heightLeft = i === 0 ? PAGE_HEIGHT - 104 : printOptions[i - 1]?.heightLeftOnLastPage || 0;
    if (heightLeft !== previousPageHeight) {
      setPreviousPageHeight(heightLeft);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printOptions]);

  useEffect(() => {
    if (statsData) {
      const copy = [...printOptions];
      let heightLeft = previousPageHeight;
      const mwrrHeight = mwrrRef.current?.offsetHeight;
      const twrrHeight = twrrRef.current?.offsetHeight;
      const totalHeight = totalRef.current?.offsetHeight;

      if (options.showMwrr && mwrrHeight && !options.showTwrr) {
        if (previousPageHeight < mwrrHeight) {
          setMwrrPageBreak(true);
          heightLeft = PAGE_HEIGHT - mwrrHeight;
        } else {
          setMwrrPageBreak(false);
          heightLeft = previousPageHeight - mwrrHeight;
        }
      }

      if (options.showTwrr && twrrHeight && !options.showMwrr) {
        if (previousPageHeight < twrrHeight) {
          setTwrrPageBreak(true);
          heightLeft = PAGE_HEIGHT - twrrHeight;
        } else {
          setTwrrPageBreak(false);
          heightLeft = previousPageHeight - twrrHeight;
        }
      }

      if (options.showTwrr && options.showMwrr && totalHeight && mwrrHeight) {
        if (previousPageHeight < totalHeight && PAGE_HEIGHT > totalHeight) {
          setTotalPageBreak(true);
          heightLeft = PAGE_HEIGHT - totalHeight;
        } else if (totalHeight > PAGE_HEIGHT) {
          setTotalPageBreak(false);
          setMwrrPageBreak(true);
          setTwrrPageBreak(true);
          heightLeft = PAGE_HEIGHT - mwrrHeight;
        } else {
          setTotalPageBreak(false);
          heightLeft = previousPageHeight - totalHeight;
        }
      }

      copy[i] = {
        heightLeftOnLastPage: heightLeft,
        loading: false,
      };

      setOptions(copy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousPageHeight, mwrrRef.current, twrrRef.current]);

  return (
    <>
      <RefBox ref={totalRef} display={'flex'} sx={{
        flexDirection: tableLayout, breakBefore: totalPageBreak ? 'page' : '', pt: totalPageBreak ? '40px' : '', overflowX: 'auto',
      }} gap={2}>
        {options.showMwrr && (
          <>
          <RefBox ref={mwrrRef} display={'flex'} flexDirection={'column'} gap={2} flex={1} sx={{ breakBefore: mwrrPageBreak ? 'page' : '', pt: mwrrPageBreak ? '40px' : '' }}>
            <Typography variant='headingSmall'>{translateBackend(options.mwrrTitle)}</Typography>
            <Card variant={print ? 'outlined' : 'elevated'} sx={{ overflowX: 'auto' }}>
              <RateOfReturnsTable
                data-testid='rate-of-returns-widget-mwrr-table'
                statsLoading={statsLoading}
                statsData={statsData}
                objectPath={objectPath}
                returnType='mwrr'
                groupOn={groupOn}
                objectType={objectType}
                objectCreatedAt={objectCreatedAt}
                tableMap={tableMap}
              />
            </Card>
          </RefBox>
          </>
        )}
        {options.showTwrr && (
          <RefBox ref={twrrRef} display={'flex'} flexDirection={'column'} gap={2} flex={1} sx={{ breakBefore: twrrPageBreak ? 'page' : '', pt: twrrPageBreak ? '40px' : '' }}>
            <Typography variant='headingSmall'>{translateBackend(options.twrrTitle)}</Typography>
            <Card variant={print ? 'outlined' : 'elevated'} sx={{ overflowX: 'auto' }}>
              <RateOfReturnsTable
                data-testid='rate-of-returns-twrr-table'
                statsLoading={statsLoading}
                statsData={statsData}
                objectPath={objectPath}
                returnType='twrr'
                groupOn={groupOn}
                objectType={objectType}
                objectCreatedAt={objectCreatedAt}
                tableMap={tableMap}
              />
            </Card>
          </RefBox>
        )}
      </RefBox>
    </>
  );
};

export default RateOfReturns;
