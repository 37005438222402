import { useCallback, useContext, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';
import {
  Typography, Box, Link as MuiLink,
  Skeleton,
} from '../../1-primative';
import {
  Table,
  TableRow, TableCell, TableBody, Pagination, TextField, MenuItem, Tooltip,
  TableHeadCell,
  Card,
  CardContent,
} from '../../2-component';
import { FilterModal, ModelPortfoliosSelect } from '../../3-pattern';
import { formatMoneyValue } from '../../../util';
import { usePageState } from '../../../util/usePageState';
import { UserContext } from '../../../providers/userContextProvider';
import PortfolioAdjustmentsModal, { PortfolioAdjustmentActiveItem, getObjectType } from './components/modal';
import WarningOnOff from '../../../components/misc/warningOnOff';
import { Goal as GoalType, SubAccount as SubAccountType } from '../../../interfaces';
import { BulkTradeRunReason, BulkTradeRunReasons, PortfolioReport } from '../../../interfaces/portfolioReport';

const FETCH_PORTFOLIO_REPORTS = gql`
  query fetchPortfolioReports($input: FetchPortfolioReportsInput!) {
    fetchPortfolioReports(input: $input) {
      portfolioReports {
        driftRebalanceRequired
        expectedCashCents
        cashAvailableForTradeCents
        holdings {
          expectedValueCents
          expectedPercentage
          financialProduct {
            id
            ticker
            isCash
            currentPriceCents
            name
            taxRank
            translatedName {
              en
              fr
            }
          }
        }
        id
        liquidateRebalanceRequired
        transferRebalanceRequired
        pendingSubTradeRequests
        modelPortfolio {
          id
          translatedName {
            en
            fr
          }
        }
        lastOptimizedBy {
          id
          createdAt
        }
        subAccounts {
          subAccount {
            id
            state
            account {
              taxRank
              type
              user {
                firstName
              }
            }
          }
          expectedCashCents
          cashAvailableForTradeCents
          marketValueCents
          pendingWithdrawCents
        }
        modelPortfolioType
        bulkTradeRunReasons {
          reason
          total
          unblockedSince
        }
        object {
          ... on Goal {
            id
            type
            statistics {
              marketValueCents
            }
            __typename
            name
            user {
              id
              firstName
              lastName
              entityName
              organization {
                name
              }
            }
            householdClientGroup {
              id
              name
            }
          }
          ... on SubAccount {
            id
            __typename
            statistics {
              marketValueCents
            }
            goal {
              id
              name
              type
              householdClientGroup {
                id
                name
              }
            }
            account {
              id
              type
              user {
                id
                firstName
                lastName
                entityName
                organization {
                  name
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

const createLink = (to: string, content: string) => (
  <MuiLink component={Link} to={to} target='_blank' onClick={(e: any) => e.stopPropagation()}>
    {content}
  </MuiLink>
);

const generateClientUrl = (id: string) => `/clients/${id}`;
const generateHouseholdUrl = (id: string) => `/households/${id}`;
const generateGoalUrl = (clientId: string, goalId: string) => `/clients/${clientId}/goal/${goalId}`;
const generateSubAccountUrl = (clientId: string, goalId: string, subAccountId: string) => `/clients/${clientId}/goal/${goalId}/subaccount/${subAccountId}`;

const isGoal = (object: GoalType | SubAccountType): object is GoalType => object.__typename === 'Goal';
const isSubAccount = (object: GoalType | SubAccountType): object is SubAccountType => object.__typename === 'SubAccount';

const translateModelPortfolioType = (t: any, modelPortfolioType: string): string => t(`types.${modelPortfolioType}`);
const translateGoalType = (t: any, goalType: string | undefined): string => (goalType ? t(`goalType.${goalType}`) : '');

const generateDisplayName = (firstName?: string, lastName?: string, entityName?: string): string => {
  if (firstName && lastName) return `${firstName} ${lastName}`;
  return entityName || '';
};

export const generateClientSourceLink = (line: PortfolioReport): JSX.Element | string => {
  const { object } = line;

  if (isSubAccount(object)) {
    const {
      id, firstName, lastName, entityName,
    } = object.account.user;
    const displayName = generateDisplayName(firstName, lastName, entityName);
    return createLink(generateClientUrl(id), displayName);
  }
  if (isGoal(object)) {
    if (object.householdClientGroup) {
      const { id, name } = object.householdClientGroup;
      return createLink(generateHouseholdUrl(id), name || '');
    }
    if (object.user) {
      const {
        id, firstName, lastName, entityName,
      } = object.user;
      const displayName = generateDisplayName(firstName, lastName, entityName);
      return createLink(generateClientUrl(id), displayName);
    }
  }

  return '';
};

export const GenerateModelPortfolioTypeSourceLink = ({ line }: { line: PortfolioReport }): JSX.Element => {
  const { object } = line;
  const { t } = useTranslation(['rebalanceReport']);

  if (isSubAccount(object)) {
    const clientId = object.account.user?.id;
    const goalId = object.goal?.id;
    const subAccountId = line.id;
    if (clientId && goalId && subAccountId) {
      return createLink(
        generateSubAccountUrl(clientId, goalId, subAccountId),
        translateModelPortfolioType(t, line.modelPortfolioType),
      );
    }
  }
  if (isGoal(object)) {
    return <>{translateModelPortfolioType(t, line.modelPortfolioType)}</>;
  }

  return <></>;
};

export const GenerateGoalSourceLink = ({ line }: { line: PortfolioReport }): JSX.Element => {
  const { object } = line;
  const { t } = useTranslation(['rebalanceReport']);

  if (isSubAccount(object)) {
    const { id } = object.account.user;
    const goalName = object.goal?.name || translateGoalType(t, object.goal?.type);
    return createLink(generateGoalUrl(id, object.goal?.id), goalName);
  }
  if (isGoal(object)) {
    const goalName = object.name || translateGoalType(t, object?.type);
    return createLink(generateGoalUrl(object.user?.id, object?.id), goalName);
  }

  return <></>;
};

export const RebalanceReport = () => {
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['rebalanceReport']);
  const [modalPortfolioType, setModalPortfolioType] = usePageState('ANY', 'filter');
  const [modelPortfolioId, setModelPortfolioId] = usePageState('ANY', 'modelPortfolioId');
  const [modalOpen, setModalOpen] = useState(false);
  const [activeItem, setActiveItem] = useState<PortfolioAdjustmentActiveItem | undefined>();
  const [triggerType, setTriggerType] = usePageState('ANY', 'trigger');
  const [tradeRequestsFilter, setTradeRequestsFilter] = usePageState('ANY', 'tradeRequests');
  const [bulkTradeRequiredReasonFilter, setBulkTradeRequiredReasonFilter] = usePageState('ANY', 'bulkTradeRequiredReason');
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 15;
  const {
    loading, error, data, previousData, refetch,
  } = useQuery(FETCH_PORTFOLIO_REPORTS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        filter: {
          modelPortfolioType: modalPortfolioType !== 'ANY' ? modalPortfolioType : undefined,
          modelPortfolioId: modelPortfolioId !== 'ANY' ? modelPortfolioId : undefined,
          anyRebalanceRequired: (triggerType !== 'ANY' && triggerType === 'BOTH') ? true : undefined,
          cashTrigger: (triggerType !== 'ANY' && triggerType === 'CASH') ? true : undefined,
          driftRebalanceRequired: (triggerType !== 'ANY' && triggerType === 'DRIFT') ? true : undefined,
          pendingSubTradeRequests: (tradeRequestsFilter === 'ANY' ? undefined : tradeRequestsFilter === 'YES'),
          organizationId: activeOrganization.id,
          bulkTradeRequiredReason: (bulkTradeRequiredReasonFilter === 'ANY' ? undefined : bulkTradeRequiredReasonFilter),
        },
        pagination: {
          sortField: 'id', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
    onCompleted: (result: any) => {
      if (activeItem) {
        const currentPortfolioReport = result?.fetchPortfolioReports?.portfolioReports?.find((portfolioReport: any) => portfolioReport.id === activeItem?.portfolioReport?.id) ?? null;

        if (!currentPortfolioReport) return;

        setActiveItem({
          type: getObjectType(currentPortfolioReport.object?.__typename),
          id: currentPortfolioReport.object?.id,
          isCashRebalanceRequired: currentPortfolioReport.transferRebalanceRequired || currentPortfolioReport.liquidateRebalanceRequired,
          portfolioReport: currentPortfolioReport,
        });
      }
    },
  });

  const getBulkTradeRequiredReasonTitle = (reasons: BulkTradeRunReason[]): string | null => {
    if (!reasons.length) return null;

    if (reasons.length > 1) return t('tooltips.UNPAIRED');

    return t(`tooltips.${reasons[0].reason}`);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const postponedRefetch = useCallback(
    debounce(() => {
      refetch();
    }, 1000),
    [],
  );

  if (error) (<Typography>Error</Typography>);

  return (
    <Card loading={loading}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <FilterModal>
            <Box display='flex' flexDirection='column' gap={2}>
              <TextField
                select
                fullWidth
                label={t('filters.triggerType')}
                value={triggerType}
                onChange={(e: any) => {
                  setTriggerType(e.target.value);
                }}
              >
                <MenuItem value='BOTH'>{t('filters.anyRebalanceRequired')}</MenuItem>
                <MenuItem value='DRIFT'>{t('filters.driftRebalance')}</MenuItem>
                <MenuItem value='CASH'>{t('filters.cash')}</MenuItem>
                <MenuItem value='ANY'>{t('any')}</MenuItem>
              </TextField>
              <TextField
                select
                fullWidth
                label={t('filters.modalportfolioType')}
                value={modalPortfolioType}
                onChange={(e: any) => {
                  setModalPortfolioType(e.target.value);
                }}
              >
                <MenuItem value='ANY'>{t('any')}</MenuItem>
                <MenuItem value='GOAL'>{t('goal')}</MenuItem>
                <MenuItem value='SUB_ACCOUNT'>{t('subAccount')}</MenuItem>
              </TextField>
              <ModelPortfoliosSelect label={t('filters.selectPortfolio')} value={modelPortfolioId} onChange={(e) => setModelPortfolioId(e.target.value)} includeAnyOption />
              <TextField
                select
                fullWidth
                label={t('filters.tradeRequests')}
                value={tradeRequestsFilter}
                onChange={(e: any) => {
                  setTradeRequestsFilter(e.target.value);
                }}
              >
                <MenuItem value='YES'>{t('filters.yes')}</MenuItem>
                <MenuItem value='NO'>{t('filters.no')}</MenuItem>
                <MenuItem value='ANY'>{t('any')}</MenuItem>
              </TextField>
              <TextField
                select
                fullWidth
                label={t('filters.bulkTradeRequiredReason')}
                value={bulkTradeRequiredReasonFilter}
                onChange={(e: any) => {
                  setBulkTradeRequiredReasonFilter(e.target.value);
                }}
              >
                <MenuItem value='ANY'>{t('any')}</MenuItem>
                {Object.keys(BulkTradeRunReasons).map((reason: string) => (
                  <MenuItem value={reason}>{t(`tooltips.${reason}`)}</MenuItem>
                ))}
              </TextField>
            </Box>
          </FilterModal>
        </Box>
      </CardContent>
      <Table aria-label='table'>
        <TableBody>
          <TableRow>
            <TableHeadCell>{t('table.client')}</TableHeadCell>
            <TableHeadCell>{t('table.type')}</TableHeadCell>
            <TableHeadCell>{t('table.goal')}</TableHeadCell>
            <TableHeadCell>{t('table.portfolio')}</TableHeadCell>
            <TableHeadCell>{t('table.cashAvailableForTrade')}</TableHeadCell>
            <TableHeadCell>{t('table.expectedCash')}</TableHeadCell>
            <TableHeadCell>{t('table.totalMarketValue')}</TableHeadCell>
            <TableHeadCell>{t('table.driftRebalanceRequired')}</TableHeadCell>
            <TableHeadCell>{t('table.cashTrigger')}</TableHeadCell>
            <TableHeadCell>{t('table.tradeRequests')}</TableHeadCell>
            <TableHeadCell>{t('table.bulkTradeRequired')}</TableHeadCell>
            <TableHeadCell>{t('table.organization')}</TableHeadCell>
          </TableRow>
          { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
            <TableRow key={i}>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
            </TableRow>
          ))}
          {(data || previousData)?.fetchPortfolioReports?.portfolioReports?.map((line: PortfolioReport) => (
            <TableRow
              hover
              key={line.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
              onClick={() => {
                setActiveItem({
                  type: getObjectType(line.object?.__typename),
                  id: line.object?.id,
                  isCashRebalanceRequired: line.transferRebalanceRequired || line.liquidateRebalanceRequired,
                  portfolioReport: line,
                });
                setModalOpen(true);
              }}
            >
                  <TableCell>{generateClientSourceLink(line)}</TableCell>
                  <TableCell><GenerateModelPortfolioTypeSourceLink line={line}/></TableCell>
                  <TableCell><GenerateGoalSourceLink line={line}/></TableCell>
              <TableCell>{line.modelPortfolio?.translatedName?.en}</TableCell>
              <TableCell>
                <Tooltip title={t('tooltips.cashAvailableForTrade')} placement='top'>
                  <span>{formatMoneyValue(line.cashAvailableForTradeCents)}</span>
                </Tooltip>
              </TableCell>
              <TableCell>{formatMoneyValue(line.expectedCashCents)}</TableCell>
              <TableCell>{formatMoneyValue(line.object?.statistics?.marketValueCents || 0)}</TableCell>
              <TableCell>
                <WarningOnOff on={line.driftRebalanceRequired} />
              </TableCell>
              <TableCell>
                <WarningOnOff on={line.transferRebalanceRequired || line.liquidateRebalanceRequired} />
              </TableCell>
              <TableCell>{line.pendingSubTradeRequests}</TableCell>
              <TableCell>
                <Tooltip title={getBulkTradeRequiredReasonTitle(line.bulkTradeRunReasons)} placement='top'>
                  <span>
                    <WarningOnOff on={!!line.bulkTradeRunReasons?.length} useDot />
                  </span>
                </Tooltip>
              </TableCell>
              <TableCell>{line.object?.__typename === 'Goal' ? line.object?.user?.organization?.name : line.object?.account?.user?.organization?.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={Math.ceil(((data || previousData)?.fetchPortfolioReports?.totalCount ?? 0) / pageSize)}
        page={page}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />

      {activeItem && <PortfolioAdjustmentsModal item={activeItem} open={modalOpen} handleClose={() => setModalOpen(false)} onRefetchRequired={() => postponedRefetch()} />}
    </Card>
  );
};

export default RebalanceReport;
