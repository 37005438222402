/* eslint-disable react-hooks/exhaustive-deps */
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { NewTab } from '../../3-pattern';
import { translateBackend } from '../../../assets/i18n/config';
import { ClientReportTemplate, ClientReportTemplate as ClientReportTemplateType, ReportVariableMeta } from '../../../interfaces';
import {
  Box, Skeleton, Stack, Typography,
} from '../../1-primative';
import {
  Badge, Card, CardContent, IconButton,
} from '../../2-component';
import { Rules } from './components/rules';
import { Frequency } from './components/frequency';
import { Editor, editorHeight } from './components/editor';
import { PageConfiguration } from './components/pageConfiguration/pageConfiguration';
import { useClientReportContext } from '../../../providers/clientReportContextProvider';

export const ClientReportTemplateVisual = ({
  template,
  setTemplate,
  templateHtml,
  setTemplateHtml,
  availableVariables,
  save,
  saving,
}: {
  template?: ClientReportTemplate,
  setTemplate: (newTemplate: ClientReportTemplateType) => void,
  templateHtml?: string,
  setTemplateHtml: (newValue: string) => void,
  availableVariables?: ReportVariableMeta[],
  save: () => void
  saving: boolean
}) => {
  const { t } = useTranslation('clientReportTemplate');
  const [tab, setTab] = useState<number>(0);
  const { setReportFrequency } = useClientReportContext();

  const setFrequency = (newFreq: ClientReportTemplateType['scheduler']['frequency']) => {
    if (!template) return;
    setTemplate({
      ...template,
      scheduler: {
        ...template.scheduler,
        frequency: newFreq,
      },
    });
  };

  useEffect(() => {
    setReportFrequency(template?.scheduler.frequency);
  }, [setFrequency]);
  return <>
    <Card>
      <CardContent>
        {template
          ? <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
                <Typography variant='titleLarge'>{translateBackend(template.translatedName)}</Typography>
                <Badge label={template.type} color='neutral' />
                {template.state === 'DRAFT' && <Badge label={t('orgSettings:clientReportTemplates.states.DRAFT')} color='negative' />}
                {template.state === 'ACTIVE' && <Badge label={t('orgSettings:clientReportTemplates.states.ACTIVE')} color='neutral' />}
                {template.state === 'INACTIVE' && <Badge label={t('orgSettings:clientReportTemplates.states.INACTIVE')} color='warning' />}
              </Stack>
              <IconButton disabled /* TODO */>
                <EditIcon />
              </IconButton>
            </Box>
            <Typography variant='bodyMedium'>{translateBackend(template.translatedDescription)}</Typography>
          </>
          : <Skeleton height={60} animation='wave' />
        }
      </CardContent>
    </Card>

    <Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'start',
        flexDirection: 'row',
        mt: 2,
        mb: 2,
        overflow: 'auto',
        width: '100%',
      }}>
        <NewTab i={0} label={t('editor')} isActive={tab === 0} setActiveTab={() => setTab(0)} />
        <NewTab i={1} label={t('frequency')} isActive={tab === 1} setActiveTab={() => setTab(1)}/>
        <NewTab i={2} label={t('rules')} isActive={tab === 2} setActiveTab={() => setTab(2)}/>
      </Box>
      { tab === 0 && (template?.reportType === 'HTML'
        ? (templateHtml !== undefined
          ? <Editor {...{
            templateHtml, setTemplateHtml, disabled: saving, availableVariables,
          }} />
          : <Skeleton height={editorHeight} animation='wave' />)
        : <PageConfiguration
          widgets={template?.clientReportTemplatePageConfiguration?.widgets}
          type={template?.clientReportTemplatePageConfiguration?.type}
          clientReport={template}
        />
      )}
      { tab === 1 && template
        && <Frequency template={template} setFrequency={setFrequency} />
      }
      { tab === 2 && template
        && <Rules template={template} />
      }
    </Box>
  </>;
};
