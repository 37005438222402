import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash/fp';
import SubTradeRequestsTableRow from './subTradeRequestsTableRow';
import { UserContext, usePermissions } from '../../../../../providers/userContextProvider';
import { Skeleton } from '../../../../1-primative';
import {
  Checkbox, Table, TableBody, TableRow, Tooltip, TableHeadCell,
  TableCell,
  Dialog,
  DialogTitle,
} from '../../../../2-component';
import { AuditLog } from '../../auditLog/auditLog';

const SUB_TRADE_SPLITS = `
  splits {
    id
    financialProduct {
      ticker
      settlementDays
      exchange
      translatedName {
        en
      }
    }
    settlementDate
    state
    isSellAll
    moneyAllocatedCents
    type
    subAccount {
      account {
        type
        user {
          id
          firstName
        }
      }
    }
    pair {
      financialProduct {
        ticker
      }
    }
    bulkTradeRun {
      id
    }
    verifiedAt
    verifiedByOrganizationUser {
      firstName
      lastName
    }
  }
`;

export const FETCH_SUB_TRADE_REQUESTS_QUERY = (isWithSplits = true) => `
  query fetchSubTradeRequests($input: FetchSubTradeRequestsInput!) {
    fetchSubTradeRequests(input: $input) {
      subTradeRequests {
        id
        financialProduct {
          ticker
          settlementDays
          exchange
          translatedName {
            en
          }
        }
        settlementDate
        state
        isSellAll
        moneyAllocatedCents
        type
        subAccount {
          account {
            type
            custodianAccountNumber
            user {
              id
              firstName
            }
          }
        }
        preSplitMoneyAllocatedCents
        isProcessedForPair
        pair {
          id
          financialProduct {
            ticker
          }
        }
        bulkTradeRun {
          id
        }
        verifiedAt
        verifiedByOrganizationUser {
          firstName
          lastName
        }

        ${isWithSplits ? SUB_TRADE_SPLITS : ''}
      }
      totalCount
    }
  }
`;

export const FETCH_SUB_TRADES = (isWithSplits = true) => gql`${FETCH_SUB_TRADE_REQUESTS_QUERY(isWithSplits)}`;

export interface TradeToVerify {
  id: string;
  selected: boolean;
}

export const SubTradeRequestsTableHeader = ({
  expandable,
  verifiable,
  edittable,
  isAllTradesToVerifySelected,
  onTradeVerifySelectAll,
}: {
  expandable?: boolean;
  verifiable?: boolean;
  edittable?: boolean;
  isAllTradesToVerifySelected?: boolean;
  onTradeVerifySelectAll?: (isChecked: boolean) => void;
}) => {
  const { t } = useTranslation(['components', 'shared']);

  return (
    <>
      <TableRow>
        { (expandable || (verifiable && edittable)) && (
          <TableHeadCell>
            { verifiable && edittable && (
              <Tooltip title={t('components:generateTrades.table.selectAll')} placement='top'>
                <Checkbox
                  checked={isAllTradesToVerifySelected}
                  customStyle={{ ml: 0.5 }}
                  onChange={(checked: boolean) => {
                    if (onTradeVerifySelectAll) onTradeVerifySelectAll(checked);
                  }}
                />
              </Tooltip>
            )}
          </TableHeadCell>
        )}
        <TableHeadCell>
          {t('components:generateTrades.table.account')}
        </TableHeadCell>
        <TableHeadCell>
          {t('components:generateTrades.table.type')}
        </TableHeadCell>
        <TableHeadCell right>
          {t('components:generateTrades.table.amount')}
        </TableHeadCell>
        <TableHeadCell>
          {t('components:generateTrades.table.security')}
        </TableHeadCell>
        <TableHeadCell>
          {t('components:generateTrades.table.settlementDays')}
        </TableHeadCell>
        <TableHeadCell>
          {t('components:generateTrades.table.exchange')}
        </TableHeadCell>
        <TableHeadCell>
          {t('components:generateTrades.table.settlementDate')}
        </TableHeadCell>
        <TableHeadCell>
          {t('components:generateTrades.table.state')}
        </TableHeadCell>
        {verifiable && (
          <TableHeadCell>
            {t('components:generateTrades.table.verifiedAt')}
          </TableHeadCell>
        )}
      </TableRow>
    </>
  );
};

const SubTradeRequestsTable = ({
  id,
  isRefetch,
  sourceId,
  onTradesToVerifyUpdate,
  afterCancel,
  edittable = true,
}: {
  id: string;
  isRefetch?: boolean;
  sourceId?: string;
  onTradesToVerifyUpdate?: (trades: TradeToVerify[]) => void;
  afterCancel?: () => void;
  edittable?: boolean,
}) => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation('shared');

  const [expandable, setExpandable] = useState(false);
  const [verifiable, setVerifiable] = useState(false);
  const [auditOpen, setAuditOpen] = useState(false);
  const [activeSubTradeRequest, setActiveSubTradeRequest] = useState<any>(null);

  const [tradesToVerify, setTradesToVerify] = useState<TradeToVerify[]>([]);
  const [initialTradesToVerifyState, setInitialTradesToVerifyState] = useState<TradeToVerify[]>([]);

  const {
    data, loading, refetch, previousData,
  } = useQuery(FETCH_SUB_TRADES(true), {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        filter: {
          bulkTradeRequestId: id,
          ...(sourceId && { sourceId }),
        },
        pagination: {
          sortField: 'subAccount',
          perPage: 100,
        },
      },
    },
    skip: !id,
  });

  useEffect(() => {
    if (isRefetch) {
      refetch();
    }
  }, [isRefetch, refetch]);

  useEffect(() => {
    const { subTradeRequests } = data?.fetchSubTradeRequests ?? [];

    setExpandable(subTradeRequests?.some((trade: any) => !!trade?.splits?.length));
    setVerifiable(permissions.includes('write:sub_trade_request_verification') && (activeOrganization?.requireSubTradeRequestApproval ?? false));

    const initialTradesToVerifyStateLocal = [];

    if (subTradeRequests?.length) {
      for (const subTradeRequest of subTradeRequests) {
        if (subTradeRequest?.splits?.length) {
          for (const split of subTradeRequest.splits) {
            initialTradesToVerifyStateLocal.push({ id: split?.id, selected: !!split?.verifiedAt && !!split?.verifiedBy });
          }
        }

        if (!subTradeRequest?.splits?.length) {
          initialTradesToVerifyStateLocal.push({ id: subTradeRequest?.id, selected: !!subTradeRequest?.verifiedAt && !!subTradeRequest?.verifiedBy });
        }
      }

      setTradesToVerify(cloneDeep(initialTradesToVerifyStateLocal));
      setInitialTradesToVerifyState(cloneDeep(initialTradesToVerifyStateLocal));
    }
  }, [data, permissions, activeOrganization]);

  const filterTradesToVerify = (items: TradeToVerify[]) => {
    const filteredTradesToVerify: TradeToVerify[] = [];

    items.forEach((item: TradeToVerify) => {
      const initialTradeToVerifyState = initialTradesToVerifyState.find((initTrade: TradeToVerify) => initTrade.id === item.id);

      if (initialTradeToVerifyState?.selected !== item?.selected) {
        filteredTradesToVerify.push(item);
      }
    });

    if (onTradesToVerifyUpdate) onTradesToVerifyUpdate(filteredTradesToVerify);
  };

  const onTradeVerify = (item: TradeToVerify) => {
    const index = tradesToVerify.findIndex((tradeToVerify: any) => item?.id === tradeToVerify?.id);
    tradesToVerify[index].selected = item?.selected;

    setTradesToVerify([...tradesToVerify]); // keeping the state of selected sub-trades
    filterTradesToVerify([...tradesToVerify]); // preparing an array of sub-trades whose state has changed
  };

  const selectAllTradesToVerify = (isChecked: boolean) => {
    const updatedTradesToVerify = tradesToVerify.map((trade: TradeToVerify) => ({ ...trade, selected: isChecked }));

    setTradesToVerify([...updatedTradesToVerify]);
    filterTradesToVerify([...updatedTradesToVerify]);
  };

  const isAllTradesToVerifySelected = tradesToVerify?.every((tradeToVerify: TradeToVerify) => tradeToVerify.selected) ?? false;

  return (
    <Table sx={{ borderBottom: 'hidden' }}>
      <TableBody>
        <SubTradeRequestsTableHeader
          expandable={expandable}
          verifiable={verifiable}
          edittable={edittable}
          isAllTradesToVerifySelected={isAllTradesToVerifySelected}
          onTradeVerifySelectAll={(isChecked: boolean) => selectAllTradesToVerify(isChecked)}
        />
        { loading && !previousData && [...Array(5)].map((x: any, i: number) => (
          <TableRow key={i}>
            <TableCell><Skeleton width='100%' /></TableCell>
            <TableCell><Skeleton width='100%' /></TableCell>
            <TableCell><Skeleton width='100%' /></TableCell>
            <TableCell><Skeleton width='100%' /></TableCell>
            <TableCell><Skeleton width='100%' /></TableCell>
            <TableCell><Skeleton width='100%' /></TableCell>
            <TableCell><Skeleton width='100%' /></TableCell>
            <TableCell><Skeleton width='100%' /></TableCell>
            { verifiable && (<TableCell><Skeleton width='100%' /></TableCell>)}
            { (expandable || (verifiable && edittable)) && (<TableCell><Skeleton width='100%' /></TableCell>)}
          </TableRow>
        ))}
        {(data || previousData)?.fetchSubTradeRequests?.subTradeRequests?.map((trade: any) => (
          <SubTradeRequestsTableRow
            trade={trade}
            expandable={expandable}
            verifiable={verifiable}
            edittable={edittable}
            tradesToVerify={tradesToVerify}
            onTradeVerify={(item: TradeToVerify) => onTradeVerify(item)}
            onClick={() => {
              setAuditOpen(true);
              setActiveSubTradeRequest(trade);
            }}
          />
        ))}
        <Dialog open={auditOpen} onClose={() => setAuditOpen(false)}>
          <DialogTitle onClose={() => setAuditOpen(false)}>
            {t('shared:auditLog')}
          </DialogTitle>
          { activeSubTradeRequest && (<AuditLog objectId={activeSubTradeRequest.id ?? ''} objectType='SubTradeRequest' options={{}}/>) }
        </Dialog>
      </TableBody>
    </Table>
  );
};

export default SubTradeRequestsTable;
