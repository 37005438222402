import { useState } from 'react';
import { SxProps } from '@mui/material';
import {
  Grid, Box, Typography,
} from '../../1-primative';
import {
  HelpText, Radio, RadioGroup,
} from '../../2-component';
import { useSelectionTileTokens } from './selectionTile.tokens';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { CompletionBox } from '../completionBox/completionBox';

interface SelectionTileProps {
  direction?: 'row' | 'column';
  type?: 'checkbox' | 'radio' | 'tile' | 'completion';
  value: string | number;
  options: Array<{
    label: string,
    subtitle?: string,
    value: string | number,
  }>;
  onChange: any,
  error?: boolean,
  errorText?: string,
  component?: 'SUGGESTED' | 'OTHER' | 'DEFAULT',
  selectedComponent?: 'SUGGESTED' | 'OTHER' | 'DEFAULT',
  infoTooltip?: string,
  testId?: string,
  label?: string
  disabled?: boolean
}

export const SelectionTile = ({
  direction = 'column', type = 'radio', value, infoTooltip, options, onChange, error, errorText, component, selectedComponent, testId, label, disabled = false,
}: SelectionTileProps) => {
  const tokens = useThemeTokens(useSelectionTileTokens());
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const { comp } = tokens;
  const { sys } = useThemeTokens();

  const boxStyle: SxProps = {
    border: `1px solid ${comp.selectionTile.borderColor}`,
    borderRadius: comp.selectionTile.borderRadius,
    pl: '12px',
    pr: '12px',
    pb: '2px',
    pt: '2px',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      borderColor: comp.selectionTile.hoverBorderColor,
      backgroundColor: comp.selectionTile.hoverBackgroundColor,
    },
  };

  if (type === 'radio') {
    return (
      <>
        <RadioGroup testId={testId} value={value} infoTooltip={infoTooltip} label={label} onChange={onChange} fullWidth disabled={disabled}>
          <Grid container direction={direction} spacing={2} width='100%' sx={{ flexGrow: 1 }}>
            {(options || []).map((option, index) => (
              <Grid data-testid={`option-${index}`} item xs key={index}>
                <Box sx={boxStyle} onClick={() => onChange({ target: { value: option.value } })}>
                  <Radio data-testid="label" value={option.value} label={option.label} size='small' />
                  {option.subtitle && (
                    <Typography data-testid="subtitle" variant='bodySmall' sx={{
                      ml: '28px', mb: '6px', color: comp.selectionTile.subtitleColor, mt: -1,
                    }}>{option.subtitle}</Typography>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
        {error && <HelpText text={errorText} tone='error' />}
      </>
    );
  }
  if (type === 'completion') {
    return (
      <>
        {(options || []).map((option, index) => (
          <CompletionBox
            testId={testId}
            title={option.label}
            state='next'
            variant='edit'
            type={type}
            onAdd={() => {
              setSelectedIndex(index);
              onChange({ target: { value: option.value } });
            }}
            key={option.value}
            selected={selectedComponent === component && selectedIndex === index}
          >
            <Typography variant='bodyLarge' data-testid='completion-box-subtitle' sx={{ color: sys.color.onSurfaceVariant }}>{option.subtitle}</Typography>
          </CompletionBox>
        ))}
      </>
    );
  }
  return (
    // TODO: Checkbox
    <></>
  );
};

export default SelectionTile;
