import { useContext, useState } from 'react';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  BackupTable, Cancel, CheckCircle,
} from '@mui/icons-material';
import { CardContent, IconButton } from '../../../../2-component';
import { Box, Typography } from '../../../../1-primative';
import SubTradeRequestsTable, { FETCH_SUB_TRADES, TradeToVerify } from './subTradeRequestsTable';
import { UserContext, usePermissions } from '../../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { useLocalization } from '../../../../../util/useLocalization';
import { DownloadSubTradeRequests } from './downloadSubTradeRequests';

const CANCEL_BULK_TRADE = gql`
  mutation transitionBulkTradeRequest($input: TransitionBulkTradeRequestInput!) {
    transitionBulkTradeRequest(input: $input) {
      bulkTradeRequest {
        id
      }
    }
  }
`;

const CANCEL_SUB_TRADE_REQUESTS = gql`
  mutation cancelSubTradeRequests($input: CancelSubTradeRequestsInput!) {
    cancelSubTradeRequests(input: $input)
  }
`;

export const VERIFY_SUB_TRADE_REQUESTS = gql`
  mutation verifySubTradeRequests($input: VerifySubTradeRequestsInput!) {
    verifySubTradeRequests(input: $input)
  }
`;

const BulkTradeRequests = ({
  bulkTradeRequestId,
  bulkTradeRequest,
  afterCancel,
  isRefetch,
  isCancelSubTradesPerPortfolioReport,
  sourceId,
  isCancelDisabled,
  portfolioOptimizerCreatedAt,
  openTradePairs,
  showTradePairs,
}: {
  bulkTradeRequestId?: string;
  bulkTradeRequest?: Record<string, any>;
  afterCancel: () => void;
  isRefetch?: boolean;
  isCancelSubTradesPerPortfolioReport?: boolean;
  sourceId?: string;
  isCancelDisabled?: boolean;
  portfolioOptimizerCreatedAt?: Date;
  openTradePairs: () => void;
  showTradePairs?: boolean;
}) => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);

  const { t } = useTranslation(['components', 'shared']);
  const { localizedDateTime } = useLocalization();
  const { showToast } = useGlobalToast();
  const [tradesToVerify, setTradesToVerify] = useState<TradeToVerify[]>([]);

  const [transitionBulkTradeRequest] = useMutation(CANCEL_BULK_TRADE, {
    variables: {
      input: {
        bulkTradeRequestId,
        transition: 'cancel',
      },
    },
    onCompleted: () => afterCancel(),
  });

  const [cancelSubTradeRequests] = useMutation(CANCEL_SUB_TRADE_REQUESTS, {
    variables: {
      input: {
        bulkTradeRequestId,
        sourceId,
      },
    },
    onCompleted: () => afterCancel(),
  });

  const [verifySubTradeRequests] = useMutation(VERIFY_SUB_TRADE_REQUESTS, {
    variables: {
      input: {
        organizationId: activeOrganization.id,
        subTradeRequestIds: tradesToVerify.map((trade: TradeToVerify) => trade?.id),
      },
    },
    onCompleted: () => {
      setTradesToVerify([]);
      showToast({ severity: 'success', message: t('components:generateTrades.verifyTradesResult') });
    },
    refetchQueries: [FETCH_SUB_TRADES()],
  });

  const cancel = async () => {
    if (isCancelSubTradesPerPortfolioReport && sourceId) {
      cancelSubTradeRequests();
    } else {
      transitionBulkTradeRequest();
    }
  };

  const isShowVerificationBtn = permissions.includes('write:sub_trade_request_verification') && (activeOrganization?.requireSubTradeRequestApproval ?? false);
  const isShowCancelTradesBtn = permissions.includes('transition:sub_trade_request') && (!(isCancelSubTradesPerPortfolioReport && sourceId) ? true : isCancelSubTradesPerPortfolioReport && sourceId);

  return (
    <>
      {bulkTradeRequestId ? (
        <>
          <CardContent>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography variant='headingXSmall'>
                  {t('components:generateTrades.activeTradeRequests')}
                </Typography>

                {!!portfolioOptimizerCreatedAt && (
                  <Typography variant='headingXSmall'>
                    ({t('components:generateTrades.generatedByPortfolioOptimizer', { portfolioOptimizerCreatedAt: localizedDateTime(portfolioOptimizerCreatedAt) })})
                  </Typography>
                )}
              <Box display='flex' alignItems='center' justifyContent='end' gap={1}>
                {isShowVerificationBtn && (
                  <IconButton
                    onClick={() => {
                      if (tradesToVerify.length) verifySubTradeRequests();
                    }}
                    disabled={!tradesToVerify.length}
                    label={t('components:generateTrades.verifyTrades')}
                  >
                    <CheckCircle />
                  </IconButton>
                )}

                {isShowCancelTradesBtn && (
                  <DownloadSubTradeRequests filter={{ bulkTradeRequestId, sourceId }} />
                )}
                {showTradePairs && (
                  <IconButton
                    onClick={openTradePairs}
                    label={t('showPairs')}
                  >
                    <BackupTable />
                  </IconButton>
                )}

                {isShowCancelTradesBtn && (
                  <IconButton disabled={isCancelDisabled} onClick={() => cancel()} label={t('components:generateTrades.cancelTrades')}>
                    <Cancel />
                  </IconButton>
                )}
              </Box>
            </Box>
          </CardContent>

          <SubTradeRequestsTable
            id={bulkTradeRequestId}
            sourceId={sourceId}
            isRefetch={isRefetch}
            onTradesToVerifyUpdate={(trades: TradeToVerify[]) => setTradesToVerify(trades)}
            afterCancel={() => afterCancel()}
          />
        </>
      ) : (
        <CardContent sx={{ pb: '16px !important' }}>
          <Box display='flex' flexDirection='row' alignItems='left' justifyContent='center'>
            <InfoRoundedIcon sx={{ mr: 1 }} />
            <Typography variant='bodyLarge' weight='bold'>
              {t('components:generateTrades.noActiveTradeRequests')}
            </Typography>
          </Box>
        </CardContent>
      )}
    </>
  );
};

export default BulkTradeRequests;
