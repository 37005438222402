import { gql, useQuery } from '@apollo/client';
import {
  Pagination, Table, TableBody, TableCell, TableHead, TableRow, Typography, Box, CircularProgress,
} from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { UserContext, usePermissions } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import { formatMoneyValue } from '../../../util';
import TrueFalse from '../../../components/misc/trueFalse';
import { NoPermissionAlert } from '../../../components/misc/noPermissionAlert';
import { generateClientSourceLink } from '../../rebalanceReport/components/table';
import PortfolioAdjustmentsModal, { PortfolioAdjustmentActiveItem, getObjectType } from '../../rebalanceReport/components/modal';

export const FETCH_OPTIMIZED_PORTFOLIO_REPORTS = gql`
  query fetchOptimizedPortfolioReports($input: FetchOptimizedPortfolioReportsInput!) {
    fetchOptimizedPortfolioReports(input: $input) {
      portfolioReports {
        cashCents
        driftRebalanceRequired
        holdings {
          ticker
        }
        id
        liquidateRebalanceRequired
        transferRebalanceRequired
        pendingSubTradeRequests
        modelPortfolio {
          id
          translatedName {
            en
            fr
          }
        }
        expectedCashCents
        cashAvailableForTradeCents
        holdings {
          expectedValueCents
          expectedPercentage
          financialProduct {
            id
            ticker
            isCash
            currentPriceCents
            name
            taxRank
            translatedName {
              en
              fr
            }
          }
        }
        subAccounts {
          subAccount {
            id
            state
            account {
              taxRank
              type
              user {
                firstName
              }
            }
          }
          expectedCashCents
          cashAvailableForTradeCents
          marketValueCents
          pendingWithdrawCents
        }
        modelPortfolioType
        object{
          ... on Goal {
            id
            statistics {
              marketValueCents
            }
            __typename
            name
            user {
              id
              firstName
              lastName
              organization {
                name
              }
            }
            householdClientGroup {
              id
              name
            }
          }
          ... on SubAccount {
            id
            __typename
            statistics {
              marketValueCents
            }
            goal {
              name
              householdClientGroup {
                id
                name
              }
            }
            account {
              id
              type
              user {
                id
                firstName
                lastName
                organization {
                  name
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

const PortfolioReportsTable = ({ portfolioOptimizerId }: { portfolioOptimizerId: string }) => {
  const pageSize = 15;
  const { t } = useTranslation('rebalanceReport');
  const { activeOrganization } = useContext(UserContext);
  const { permissions } = usePermissions();
  const [page, setPage] = usePageState(1, 'page');
  const [modalOpen, setModalOpen] = useState(false);
  const [activeItem, setActiveItem] = useState<PortfolioAdjustmentActiveItem | undefined>();

  const {
    loading, data, previousData, refetch,
  } = useQuery(FETCH_OPTIMIZED_PORTFOLIO_REPORTS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        organizationId: activeOrganization.id,
        portfolioOptimizerId,
        pagination: {
          perPage: pageSize,
          offSet: (page - 1) * pageSize,
        },
      },
    },
    skip: !['read:rebalance_basic', 'read:portfolio_optimizer'].some((el) => permissions.includes(el)),
    onCompleted: (result: any) => {
      if (activeItem) {
        const currentPortfolioReport = result?.fetchOptimizedPortfolioReports?.portfolioReports?.find((portfolioReport: any) => portfolioReport.id === activeItem?.portfolioReport?.id) ?? null;

        if (!currentPortfolioReport) return;

        setActiveItem({
          type: getObjectType(currentPortfolioReport.object?.__typename),
          id: currentPortfolioReport.object?.id,
          isCashRebalanceRequired: currentPortfolioReport.transferRebalanceRequired || currentPortfolioReport.liquidateRebalanceRequired,
          portfolioOptimizerId,
          portfolioReport: currentPortfolioReport,
        });
      }
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const postponedRefetch = useCallback(
    debounce(() => {
      refetch();
    }, 1000),
    [],
  );

  if (!['read:rebalance_basic', 'read:portfolio_optimizer'].some((el) => permissions.includes(el))) return <NoPermissionAlert missing='read:rebalance_basic|read:portfolio_optimizer' />;

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 15 }} />
        </Box>
      ) : (
        <>
          <Table sx={{ minWidth: 650 }} aria-label='table' data-testid='portfolio-reports-table'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant='overline'>{t('table.client')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='overline'>{t('table.type')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='overline'>{t('table.goal')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='overline'>{t('table.portfolio')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='overline'>{t('table.cashAvailableForTrade')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='overline'>{t('table.expectedCash')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='overline'>{t('table.totalMarketValue')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='overline'>{t('table.driftRebalanceRequired')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='overline'>{t('table.cashTrigger')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='overline'>{t('table.tradeRequests')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='overline'>{t('table.organization')}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(data || previousData)?.fetchOptimizedPortfolioReports?.portfolioReports?.map((line: any) => (
                <TableRow
                  hover
                  onClick={() => {
                    setActiveItem({
                      type: getObjectType(line.object?.__typename),
                      id: line.object?.id,
                      isCashRebalanceRequired: line.transferRebalanceRequired || line.liquidateRebalanceRequired,
                      portfolioOptimizerId,
                      portfolioReport: line,
                    });
                    setModalOpen(true);
                  }}
                  key={line.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
                >
                  <TableCell>{generateClientSourceLink(line)}</TableCell>
                  <TableCell>{t(`types.${line.modelPortfolioType}`)}</TableCell>
                  <TableCell>{line.object?.__typename === 'Goal' ? line.object?.name : line.object?.goal?.name}</TableCell>
                  <TableCell>{line.modelPortfolio?.translatedName?.en}</TableCell>
                  <TableCell>{formatMoneyValue(line.cashAvailableForTradeCents)}</TableCell>
                  <TableCell>{formatMoneyValue(line.expectedCashCents)}</TableCell>
                  <TableCell>{formatMoneyValue(line.object?.statistics?.marketValueCents || 0)}</TableCell>
                  <TableCell>
                    <TrueFalse check={line.driftRebalanceRequired} />
                  </TableCell>
                  <TableCell>
                    <TrueFalse check={line.transferRebalanceRequired || line.liquidateRebalanceRequired} />
                  </TableCell>
                  <TableCell>{line.pendingSubTradeRequests}</TableCell>
                  <TableCell>{line.object?.__typename === 'Goal' ? line.object?.user?.organization?.name : line.object?.account?.user?.organization?.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchOptimizedPortfolioReports?.totalCount ?? 0) / pageSize)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />

          {activeItem && <PortfolioAdjustmentsModal item={activeItem} open={modalOpen} handleClose={() => setModalOpen(false)} onRefetchRequired={() => postponedRefetch()} />}
        </>
      )}
    </>
  );
};

export default PortfolioReportsTable;
