import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatMoneyValue } from '../../../../../util';
import { TradeToVerify } from './subTradeRequestsTable';
import { useLocalization } from '../../../../../util/useLocalization';
import {
  Link as MuiLink,
} from '../../../../1-primative';
import {
  IconButton, TableCell, TableRow, Checkbox, Badge,
} from '../../../../2-component';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';

const SUB_TRADE_REQUEST_STATES_ORDERED: Record<string, number> = {
  INITIATED: 1,
  READY: 2,
  REQUESTED: 3,
  RECONCILED: 4,
  CANCELED: 5,
};

export const GenerateSourceLink = ({ item }: { item: any }) => {
  const { t } = useTranslation(['components', 'shared', 'transfer']);

  const user = item?.subAccount?.account?.user ?? null;
  const type = item?.subAccount?.account?.type ?? null;

  if (!user || !type) return <></>;

  return (
    <MuiLink component={Link} href={`/clients/${user?.id}#tab:trades`} target='_blank'>
      {user?.firstName}'s {t(`accountTypes:${type}`)}
    </MuiLink>
  );
};

const SubTradeRequestsTableRow = ({
  trade,
  expandable,
  verifiable,
  edittable,
  tradesToVerify,
  onTradeVerify,
  onClick,
}: {
  trade: any;
  expandable: boolean;
  verifiable: boolean;
  edittable: boolean;
  tradesToVerify: TradeToVerify[];
  onTradeVerify: (trade: TradeToVerify) => void;
  onClick?: () => void;
}) => {
  const { t } = useTranslation(['components', 'shared', 'transfer']);
  const { localizedDate, localizedDateTime } = useLocalization();
  const { sys } = useThemeTokens();

  const [open, setOpen] = useState(false);

  const getLowestTradeStatus = (row: any): string => {
    if (row?.splits?.length) {
      const states = row.splits.map((split: any) => split.state);

      return t(`transfer:states.${states.sort((a: string, b: string) => SUB_TRADE_REQUEST_STATES_ORDERED[a] - SUB_TRADE_REQUEST_STATES_ORDERED[b])[0]}`);
    }

    return t(`transfer:states.${row.state}`);
  };

  const LocalTableRow = ({ row, nested }: { row: any; nested?: boolean }) => {
    const isShowVerification = verifiable && ['INITIATED', 'READY'].includes(row?.state) && !row?.bulkTradeRun?.id;

    const isTradeSelected = (item: any) => tradesToVerify?.find((tradeToVerify: TradeToVerify) => item?.id === tradeToVerify?.id)?.selected ?? false;

    const isTradeSelectedToVerify = isTradeSelected(row);

    return (
      <TableRow key={row?.id} onClick={onClick} pointer={!!onClick} hover={!!onClick} sx={{
        '&:last-child td, &:last-child th': {
          border: 0,
        },
        backgroundColor: nested ? sys.color.surfaceContainer : undefined,
      }}>
        {!!row?.splits?.length && (isShowVerification || expandable) && (
          <TableCell sx={{ width: 0 }}>
            {!nested && (
              <IconButton aria-label='expand row' size='small' onClick={(e: any) => {
                e.stopPropagation();
                setOpen(!open);
              }}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TableCell>
        )}
        {!row?.splits?.length && ((isShowVerification && edittable) || expandable) && (
          <TableCell sx={{ backgroundColor: nested ? sys.color.surfaceContainer : undefined }}>
            {isShowVerification && !row?.splits?.length && edittable && (
              <Checkbox
                checked={isTradeSelectedToVerify}
                onChange={() => onTradeVerify({ id: row?.id, selected: !isTradeSelectedToVerify })}
                customStyle={{ justifyContent: 'center' }}
                onClick={(e: any) => e.stopPropagation()}
              />
            )}
          </TableCell>
        )}

        <TableCell sx={{ backgroundColor: nested ? sys.color.surfaceContainer : undefined }}>
          <GenerateSourceLink item={row} />
        </TableCell>
        <TableCell sx={{ backgroundColor: nested ? sys.color.surfaceContainer : undefined }}>{row.isSellAll ? 'SELL ALL' : row.type}</TableCell>
        <TableCell sx={{ backgroundColor: nested ? sys.color.surfaceContainer : undefined }} number>{formatMoneyValue(row?.preSplitMoneyAllocatedCents ?? row?.moneyAllocatedCents)}</TableCell>
        <TableCell sx={{ backgroundColor: nested ? sys.color.surfaceContainer : undefined }}>{row.financialProduct?.ticker}</TableCell>
        <TableCell sx={{ backgroundColor: nested ? sys.color.surfaceContainer : undefined }}>{row.financialProduct?.settlementDays}</TableCell>
        <TableCell sx={{ backgroundColor: nested ? sys.color.surfaceContainer : undefined }}>{row.financialProduct?.exchange}</TableCell>
        <TableCell sx={{ backgroundColor: nested ? sys.color.surfaceContainer : undefined }}>{row?.settlementDate && localizedDate(row?.settlementDate)}</TableCell>
        <TableCell sx={{ backgroundColor: nested ? sys.color.surfaceContainer : undefined }}>
          <Badge label={getLowestTradeStatus(row)} color={getLowestTradeStatus(row) === 'RECONCILED' ? 'positive' : 'warning'} />
        </TableCell>
        { isShowVerification && (
          <TableCell sx={{ backgroundColor: nested ? sys.color.surfaceContainer : undefined }}>
            {row.verifiedAt ? `${row.verifiedByOrganizationUser?.firstName} ${row.verifiedByOrganizationUser?.lastName}` : '-'}<br />
            {row.verifiedAt && localizedDateTime(row?.verifiedAt)}
          </TableCell>
        ) }
      </TableRow>
    );
  };

  return (
    <>
      <LocalTableRow row={trade} />
      {!!trade?.splits?.length && open && (
        <>
          {trade?.splits.map((split: any) => (
            <LocalTableRow key={split?.id} row={split} nested />
          ))}
        </>
      )}
    </>
  );
};

export default SubTradeRequestsTableRow;
