import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import { Box, Typography } from '../../../1-primative';
import { ObjectType } from '../../../../providers/statsHooks';
import { PageObjectType } from '../../../5-page';
import { TableHoldings } from './components/tableHoldings';
import { PieChartHoldings } from './components/pieChartHoldings';
import { translateBackend } from '../../../../assets/i18n/config';
import { UserContext } from '../../../../providers/userContextProvider';
import { Holding } from '../../../../interfaces';
import { useClientReportContext } from '../../../../providers/clientReportContextProvider';
import { useHistoricalStats } from '../../../../providers/historicalStatsHooks';
import { calculateTableSplit, PAGE_HEIGHT, PrintContext } from '../../../5-page/viewClientReport/components/printReport';

const objectTypeMapping = (objectType: PageObjectType): ObjectType => {
  const types = {
    [PageObjectType.INDIVIDUAL]: ObjectType.USER,
    [PageObjectType.NON_INDIVIDUAL]: ObjectType.USER,
    [PageObjectType.HOUSEHOLD]: ObjectType.CLIENT_GROUP,
    [PageObjectType.GOAL]: ObjectType.GOAL,
    [PageObjectType.SUB_ACCOUNT]: ObjectType.SUB_ACCOUNT,
    [PageObjectType.ACCOUNT]: ObjectType.ACCOUNT,
  };
  return types[objectType];
};

export const Holdings = ({
  objectId,
  objectType,
  options = {},
  print = false,
  i = 0,
}: {
  objectId: string,
  objectType: PageObjectType,
  options?: any,
  print?: boolean,
  i?: number,
}): JSX.Element => {
  const { t } = useTranslation(['client']);
  const { timePeriod } = useClientReportContext();
  const [printRowSplit, setPrintRowSplit] = useState<number[]>([]);
  const [startOnNew, setStartOnNew] = useState(false);
  const [previousPageHeight, setPreviousPageHeight] = useState(0);
  const { setOptions, options: printOptions } = useContext(PrintContext);

  const [holdings, setHoldings] = useState<Holding[]>([]);
  const { activeCurrency } = useContext(UserContext);

  const {
    statistics, loading, history,
  } = useHistoricalStats(
    {
      type: objectTypeMapping(objectType),
      id: objectId,
      currency: activeCurrency,
      endDate: dayjs(timePeriod.endDate).format('YYYY-MM-DD'),
      startDate: dayjs(timePeriod.startDate).format('YYYY-MM-DD'),
    },
  );

  useEffect(() => {
    if (statistics?.holdings) {
      setHoldings(statistics?.holdings);
    }
  }, [statistics]);

  useEffect(() => {
    const heightLeft = (i === 0 ? PAGE_HEIGHT - 104 : (printOptions[i - 1]?.heightLeftOnLastPage || 0));
    if (heightLeft !== previousPageHeight) {
      setPreviousPageHeight(heightLeft);
    }
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [printOptions, i]);

  useEffect(() => {
    if (holdings.length > 0) {
      const copy = [...printOptions];
      // Title
      const titleHeight = 56;

      const rowSplit = calculateTableSplit({
        headerHeight: 48,
        rowHeight: 49,
        totalRows: holdings.length,
        footerHeight: 49,
        pageHeight: previousPageHeight - titleHeight,
      });
      setPrintRowSplit(rowSplit.splitRows);
      setStartOnNew(rowSplit.startOnNew);

      copy[i] = {
        heightLeftOnLastPage: rowSplit.heightLeft - 40, // bottom margin
        loading: false,
      };

      setOptions(copy);
    }
  /* eslint-disable react-hooks/exhaustive-deps */
  }, [holdings, previousPageHeight]);

  const sortHoldings = () => {
    const sortedHoldings = holdings.sort((a: Holding, b: Holding) => {
      const localeComparison = (a.originalCurrency?.currency ?? a.currency ?? CurrencyCodes.CAD).localeCompare(b.originalCurrency?.currency ?? b.currency ?? CurrencyCodes.CAD);
      return localeComparison === 0 ? (b.totalCents ?? 0) - (a.totalCents ?? 0) : localeComparison;
    });
    return sortedHoldings;
  };

  return (
    <>
      { startOnNew && (<Box sx={{ pageBreakBefore: 'always', mt: '40px' }} />)}
      <Box display="flex" alignItems={'center'}>
        <Typography variant='headingSmall'>{options.customTitle ? translateBackend(options.customTitle) : t('holdings')}</Typography>
      </Box>
      {
        options.display === 'table' ? (
          <>
            {
              print
                ? printRowSplit.map((num, index) => (
                  <Box sx={{ pageBreakBefore: index !== 0 ? 'always' : undefined, marginTop: index !== 0 ? '40px' : undefined }} key={index}>
                    { index !== 0 && (
                      <Typography variant='headingXSmall' mb={2}>
                        {options.customTitle ? translateBackend(options.customTitle) : t('holdings')} {t('shared:continued')}
                      </Typography>
                    )}
                    <TableHoldings
                      data-testid='holdings-table'
                      holdings={sortHoldings().slice(index * num, (index + 1) * num)}
                      loading={loading} history={history}
                      options={options}
                      print={true}
                      showFooter={index + 1 === printRowSplit.length}
                    />
                  </Box>
                )) : (
                  <TableHoldings
                    data-testid='holdings-table'
                    holdings={sortHoldings()}
                    loading={loading} history={history}
                    options={options}
                  />
                )
            }
          </>
        ) : (
          <PieChartHoldings data-testid='holdings-pie-chart' holdings={holdings} loading={loading} options={options} />
        )
      }
    </>
  );
};
