import { getBackendLanguage, translateBackend } from 'assets/i18n/config';
import { Editor } from '@tinymce/tinymce-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '../../1-primative';
import { MenuItem, SelectField } from '../../2-component';

enum Languages {
  en = 'en',
  fr = 'fr',
}

export const HtmlTemplate = ({
  option, options, setOptions, i,
}: { option: any; options: any; setOptions: (x: any) => void; i: number }) => {
  const { t } = useTranslation('components');
  const [language, setLanguage] = useState(getBackendLanguage());
  const tinyMceApiKey = process.env.REACT_APP_TINYMCE_API_KEY;

  const editorConfig = {
    hidden_input: true,
    menubar: true,
    plugins: [
      'variable',
      'insert',
      'advlist',
      'autolink',
      'lists',
      'link',
      'charmap',
      'preview',
      'anchor',
      'searchreplace',
      'visualblocks',
      'code',
      'fullscreen',
      'template',
      'insertdatetime',
      'table',
      'code',
    ],
    toolbar:
      'variable | save | undo redo | blocks | '
      + 'bold italic forecolor | alignleft aligncenter | blocks fontsize'
      + 'alignright alignjustify | bullist numlist outdent indent | '
      + 'template'
      + 'removeformat | help',
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    branding: false,
  };

  const renderEditor = (selectedLanguage: string) => (
    <Editor
      id={`tinyMceEditor-${selectedLanguage}`}
      apiKey={tinyMceApiKey ?? ''}
      value={atob(option.value[selectedLanguage])}
      onEditorChange={(newValue, editor) => {
        const newOptions = [...options];
        newOptions[i] = {
          ...option,
          value: { ...option.value, [selectedLanguage]: btoa(newValue) },
        };
        setOptions(newOptions);
      }}
      init={editorConfig}
    />
  );

  return (
    <Box>
      <Typography variant='bodyMedium' weight='bold' mb={1}>
        {translateBackend(option.label)}
      </Typography>
      <SelectField label={t('languageSelection.language')} testId='languageSelectField' fullWidth sx={{ mb: 2 }} value={language} onChange={(event: any) => setLanguage(event.target.value)}>
        {Object.values(Languages).map((lang) => (
          <MenuItem value={lang}>{t(`pageConfiguration:htmlTemplate.languages.${lang}`)}</MenuItem>
        ))}
      </SelectField>
      {renderEditor(language)}
    </Box>
  );
};
