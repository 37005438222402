/* eslint-disable react-hooks/exhaustive-deps */
import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import {
  useState, useEffect, createContext, useContext,
} from 'react';
import hashSum from 'hash-sum';
import { CountryCodes, CurrencyCodes } from '@onevesthq/ov-enums';
import omitDeep from 'omit-deep-lodash';
import {
  auth0Logout, auth0RefreshToken, auth0RefreshTokenSilently, JWT_CLAIMS_NAMESPACE,
} from './auth0Client';
import { adjust } from '../theme/colors';
import { delay, parseJwt } from '../util';
import {
  EntityTypes, OrganizationUserAccessTypes, Languages, ClientGroup, Role, BareLocalization, Integration,
  EnabledJurisdictions,
} from '../interfaces';
import { useAuthContext } from './ovApolloProvider';
import { availableFeatureFlagsVar } from '../util/localVariables';
import { setBackendLanguage } from '../assets/i18n/config';
import filterHouseholdsWithAccess from '../util/filterHouseholdsWithAccess';
import { CustodianConnection } from '../interfaces/custodianConnection';
import { useGlobalToast } from './globalToastProvider';

interface Widget {
  id: string,
  type: string,
  options: any,
}

interface Tab {
  id: string,
  icon: string,
  label: {
    en: string,
    fr: string,
  },
  widgets: Widget[],
}

export interface PageConfiguration {
  id: string,
  tabs: Tab[],
  options?: any,
}
export interface UserContextProps {
  id?: string,
  firstName?: string,
  lastName?: string,
  email?: string,
  phone?: string,
  language?: Languages,
  accessType?: OrganizationUserAccessTypes,
  role?: Role,
  avatar?: string,
  organization?: {
    id: string,
    name: string,
    subdomain: string,
    theme?: {
      sideBarColor?: string,
      logo?: string,
    },
    supportUrl: string,
    helpCentreUrl: string
  },
  entities?: [{
    entity: {
      id: string,
      affiliateOnly?: boolean,
      type?: EntityTypes,
      entityName?: string,
      firstName?: string,
      lastName?: string,
      primaryEmail?: string,
      households?: ClientGroup[],
    }
  }],
}

export type SetUserContextType = React.Dispatch<React.SetStateAction<UserContextProps>>;

type UserContextType = {
  setUserContext: SetUserContextType,
  userContext: UserContextProps,
  setActiveOrganizationId: React.Dispatch<React.SetStateAction<string | undefined>>,
  activeOrganization: ActiveOrganizationProps,
  setActiveEntity: React.Dispatch<React.SetStateAction<any | undefined>>,
  activeEntity: ActiveEntityProps | undefined,
  activeHousehold: ClientGroup | undefined,
  setActiveHousehold: React.Dispatch<React.SetStateAction<ClientGroup | undefined>>,
  setOrganizationIds: React.Dispatch<React.SetStateAction<string[]>>,
  activeLanguage: Languages | undefined,
  setActiveLanguage: React.Dispatch<React.SetStateAction<Languages>>,
  organizationIds: string[],
  userId: string | undefined,
  setUserId: React.Dispatch<React.SetStateAction<string | undefined>>
  loading: boolean,
  integrations: Integration[],
  custodianConnection: CustodianConnection,
  activeCurrency: string,
  setActiveCurrency: React.Dispatch<React.SetStateAction<string>>,
  baseCurrency: string,
  closed: boolean,
  setClosed: React.Dispatch<React.SetStateAction<boolean>>,
  avatar?: string,
  refetch: () => void,
};
export interface ExternalProvider {
  id: string,
  idVerificationProvider: {
    templateId: string,
    vendor: string,
  },
  bankingConnectorProvider: {
    clientIframeUrl: string,
    vendor: string,
  },
}
export interface ActiveOrganizationProps {
  id?: string,
  name?: string,
  availableFeatureFlags?: string[],
  themeTokens?: any,
  theme?: {
    logo?: string,
    sideBarColor?: string,
    graphColors?: string[],
  }
  externalProvider?: ExternalProvider,
  reviewTransactions?: boolean,
  displayMenuBar?: boolean,
  displayCurrency?: boolean,
  allowPortfolioPerGoal?: boolean,
  useParentIntegrations?: boolean,
  allowPostOptimizationEditing?: boolean,
  applicableLocalization: BareLocalization,
  jurisdictions: EnabledJurisdictions,
  minRecurringDepositCents?: number,
  profileReviewTimeInMonths?: number,
  requireSubTradeRequestApproval?: boolean,
  allowViewSubTradeRequestPairs?: boolean,
  faviconLink?: string,
  browserTabTitle?: string,
  clientHostedLoginURL?: string,
  clientHostedLoginRedirectUri?: string
}

export interface ActiveEntityProps {
  id?: string,
  type?: string,
  entityName?: string,
  firstName?: string,
  lastName?: string,
  primaryEmail?: string,
  households?: ClientGroup[],
  availableFeatureFlags?: string[]
}

const PAGE_CONFIGURATION = `
  id
  type
  options
  tabs {
    id
    icon
    url
    label {
      en
      fr
    }
    widgets {
      id
      type
      options
    }
  }
`;

export const ME = (permissions?: string[]) => gql`
  query me {
    me {
      id
      firstName
      lastName
      language
      email
      phone
      accessType
      avatar
      role {
        id
        translatedName { en fr }
        permissions
        accessiblePages
        publicRoleProfile { id }
        ${permissions && permissions.includes('read:dashboards') ? `
          dashboards {
            id
            name
            widgets {
              width
              height
              x
              y
              title
              format
              type
              reportType
              columns
              sortDesc
              sortField
              filters {
                field
                comparison
                value
              }
              grouping {
                field
                interval
                type
                valueField
                valueAggregation
              }
            }
          }
        ` : ''}
        navigationStyle
        individualPageConfiguration {
          ${PAGE_CONFIGURATION}
        }
        nonIndividualPageConfiguration {
          ${PAGE_CONFIGURATION}
        }
        goalPageConfiguration {
          ${PAGE_CONFIGURATION}
        }
        accountPageConfiguration {
          ${PAGE_CONFIGURATION}
        }
        householdPageConfiguration {
          ${PAGE_CONFIGURATION}
        }
        makeHouseholdDefaultView
        subAccountPageConfiguration {
          ${PAGE_CONFIGURATION}
        }
      }
      organization {
        id
        subdomain
        name
        themeTokens
        supportUrl
        helpCentreUrl
        defaultSignUpRole { id }
      }
      ${permissions && permissions.includes('read:organization_user_entities') ? `
        entities {
          entity {
            id
            type
            affiliateOnly
            entityName
            firstName
            lastName
            primaryEmail
            households {
              id
              relationships {
                user {
                  id
                }
                accessType
              }
              organization {
                id
              }
            }
          }
        }
      ` : ''}
    }
  }
`;

export const FETCH_ORGANIZATION = (organizationId: string, permissions?: string[]) => gql`
  query fetchOrganization {
    fetchOrganization(organizationId: "${organizationId}") {
      organization {
        id
        name
        availableFeatureFlags
        ${permissions && permissions.includes('read:custodian_connection') ? `
        defaultCustodianConnection {
          id
          name
          default
          type
          enableFetchCustodianStatistics
          enableFetchCustodianSnapshotHistory
          enableFetchCustodianTransactions
          enableFetchCustodianSuitability
          enableFetchCustodianAffiliates
          enableFetchCustodianUpcomingTransactions
          enableFetchCustodianStatements
          enableFetchCustodianCustomFields
          enableFetchCustodianProjectedIncome
          enableFetchCustodianCustomFields
          organization{
            id
          }
          accountTypeSettings {
            type
            baseCurrency
            availableCurrencies
            isMultiCurrencyEnabled
          }
        }
        ` : ''}
        themeTokens
        useParentIntegrations
        allowPostOptimizationEditing
        requireSubTradeRequestApproval
        allowViewSubTradeRequestPairs
        minRecurringDepositCents
        profileReviewTimeInMonths
        theme {
          sideBarColor
          logo
        }
        applicableLocalization {
          countries
          languages
          defaultLanguage
          defaultCurrency
          dateFormat
        }
        jurisdictions { all only }
        reviewTransactions
        faviconLink
        browserTabTitle
        externalProvider {
          id
          idVerificationProvider {
            templateId
            vendor
          }
          bankingConnectorProvider {
            clientIframeUrl
            vendor
          }
        }
        displayMenuBar
        displayCurrency
        allowPortfolioPerGoal
        clientHostedLoginURL
        clientHostedLoginRedirectUri
      }
    }
  }
`;

export const CREATE_ENTITY = gql`
  mutation createEntity($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        affiliateOnly
        type
        entityName
        firstName
        lastName
        primaryEmail
        households {
          id
          organization {
            id
          }
        }
      }
    }
  }
`;

const FETCH_INTEGRATIONS = gql`
  query fetchIntegrations($input: FetchIntegrationsInput!) {
    fetchIntegrations(input: $input) {
      integrations {
        id
        configuration
        provider
        type
      }
      totalCount
    }
  }
`;

const handleError = async () => {
  await auth0Logout();
};

/* fallback defaults when no localization is set */
const LOCALIZATION_DEFAULTS: BareLocalization = {
  name: 'defaults',
  countries: [CountryCodes.CA],
  languages: ['en', 'fr'],
  defaultCurrency: CurrencyCodes.CAD,
  defaultLanguage: 'en',
  dateFormat: 'YYYY-MM-DD',
};

const ACTIVE_ORG_SKELETON = {
  applicableLocalization: LOCALIZATION_DEFAULTS,
  jurisdictions: { all: true },
};

const UserContext = createContext<UserContextType>({
  userContext: {},
  setUserContext: () => { },
  userId: undefined,
  setUserId: () => { },
  activeOrganization: ACTIVE_ORG_SKELETON,
  setActiveOrganizationId: () => { },
  activeEntity: {},
  setActiveEntity: () => { },
  activeHousehold: undefined,
  setActiveHousehold: () => { },
  organizationIds: [],
  activeLanguage: undefined,
  setActiveLanguage: () => { },
  setOrganizationIds: () => { },
  loading: true,
  integrations: [],
  custodianConnection: {
    accountTypeSettings: [],
  },
  activeCurrency: '',
  setActiveCurrency: () => { },
  baseCurrency: '',
  closed: false,
  setClosed: () => { },
  refetch: () => { },
});

function restoreOrgDataFromLocalStorage() {
  try {
    return JSON.parse(localStorage.getItem('activeOrganizationData') || '{}');
  } catch {
    return {};
  }
}

const savedOrganizationId = localStorage.getItem('activeOrganizationId') || undefined;
const savedOrganization = restoreOrgDataFromLocalStorage();

const UserContextProvider = ({ children }: { children: any }) => {
  const { authToken } = useAuthContext();
  const payload = parseJwt(authToken);
  const payloadPermissions = payload[`${JWT_CLAIMS_NAMESPACE}/orgUserPermissions`] ?? payload?.permissions;
  const { data, refetch } = useQuery(ME(payloadPermissions), { onError: handleError });
  const { setAuthToken } = useAuthContext();
  const { localStorage } = window;
  const { setToastTheme } = useGlobalToast();
  const [userContext, setUserContext] = useState<UserContextProps>({});
  const [userId, setUserId] = useState<string | undefined>();
  const [activeHousehold, setActiveHousehold] = useState<ClientGroup | undefined>(undefined);
  const [activeOrganizationId, setActiveOrganizationId] = useState<string | undefined>(savedOrganizationId);
  const [activeOrganization, setActiveOrganization] = useState<ActiveOrganizationProps>(savedOrganization);
  const [closed, setClosed] = useState(localStorage.getItem('closed') === 'true');
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [custodianConnection, setCustodianConnection] = useState<CustodianConnection>({});
  const [activeEntity, setActiveEntity] = useState<ActiveEntityProps>();
  const [activeLanguage, setActiveLanguage] = useState<Languages>(Languages.ENGLISH);
  const [activeCurrency, setActiveCurrency] = useState<string>(savedOrganization.applicableLocalization?.defaultCurrency ?? 'CAD');
  const [baseCurrency, setBaseCurrency] = useState<string>(savedOrganization.applicableLocalization?.defaultCurrency ?? 'CAD');
  const [organizationIds, setOrganizationIds] = useState<string[]>([]);
  const [fetchOrg, { data: organizationData }] = useLazyQuery(FETCH_ORGANIZATION(activeOrganizationId ?? '', payload?.permissions));
  const [fetchIntegrations] = useLazyQuery(FETCH_INTEGRATIONS);
  const [loading, setLoading] = useState(true);
  const [faviconLink, setFaviconLink] = useState('');
  const [browserTabTitle, setBrowserTabTitle] = useState('');

  const [createEntity] = useMutation(CREATE_ENTITY);

  /* When permissions change, force user to login again (keep the permissions within JWT token in sync) */
  const detectPermissionsChange = (newPerms: string[]) => {
    const newPermissionsHash = hashSum(newPerms);
    const savedPermissionsHash = localStorage.getItem('permissionsHash');
    if (savedPermissionsHash && savedPermissionsHash !== newPermissionsHash) {
      auth0RefreshToken({ orgSubdomain: userContext.organization?.subdomain });
    }
    localStorage.setItem('permissionsHash', newPermissionsHash);
  };

  /* user profile (ME) has been loaded */
  useEffect(() => {
    if (data) {
      setLoading(false);
      setUserContext({ ...data.me });
      detectPermissionsChange(data.me.role.permissions);
      setActiveLanguage(data.me?.language ?? Languages.ENGLISH);
    }
  }, [data]);

  /* update backend language */
  useEffect(() => {
    setBackendLanguage(activeLanguage);
  }, [activeLanguage]);

  useEffect(() => {
    if (faviconLink) {
      let link: any = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = faviconLink;
    }
    if (browserTabTitle) {
      const tabTitle = document.getElementById('org-title');
      if (tabTitle) {
        tabTitle.textContent = browserTabTitle;
      }
    }
  }, [faviconLink, browserTabTitle]);

  /* user profile (ME) has been loaded AND there's no organization set - use their default */
  useEffect(() => {
    if (data?.me?.organization?.id) {
      if (!activeOrganizationId) {
        setActiveOrganizationId(data.me.organization.id);
      }
    }
    if (data?.me?.entities?.length) {
      if (!activeEntity?.id) {
        const urlPath = window.location.pathname;
        const urlEntity = data.me.entities.find((e: any) => urlPath.includes(e.entity.id || ''))?.entity;
        const entity = urlEntity ?? data.me.entities[0].entity;
        setActiveEntity(entity);

        const householdsWithAccess = filterHouseholdsWithAccess((entity?.households ?? []), entity?.id);
        if (householdsWithAccess.length > 0) setActiveHousehold(householdsWithAccess[0]);
      }
    }
    if (data?.me?.role?.permissions?.includes('read:integrations')) {
      fetchIntegrations({
        variables: {
          input: { filter: { organizationId: activeOrganizationId } },
        },
        onCompleted: async (res: any) => { setIntegrations(res?.fetchIntegrations?.integrations); },
      });
    }
  }, [data, activeOrganizationId, setActiveOrganizationId, activeEntity, setActiveEntity, setActiveHousehold]);

  /* Automatically creates Individual entity during sign-up */
  useEffect(() => {
    if (
      data?.me?.entities?.length === 0
      && data?.me?.accessType === OrganizationUserAccessTypes.ENTITY
      && data?.me?.organization?.defaultSignUpRole?.id === data?.me?.role?.id
      && data?.me?.role?.permissions?.includes('write:client_basic')
    ) {
      setLoading(true);
      createEntity({
        variables: {
          input: {
            type: EntityTypes.INDIVIDUAL,
            language: Languages.ENGLISH,
            primaryEmail: data?.me?.email,
            phone: data?.me?.phone ?? undefined,
            firstName: data?.me?.firstName ?? undefined,
            lastName: data?.me?.lastName ?? undefined,
            organizationId: data?.me?.organization?.id,
          },
        },
        onCompleted: async (resp) => {
          if (resp.createUser.user.id) {
            // TODO: add GraphQL subscription to confirm it's ready to redirect user
            await delay(2000);
            const newToken = await auth0RefreshTokenSilently({
              redirectToPath: `/clients/${resp.createUser.user.id}?autoOpenWorkflow=true`,
              orgSubdomain: userContext.organization?.subdomain,
            });
            if (newToken) setAuthToken(newToken);
            setActiveEntity(resp.createUser.user);
          }
        },
      });
    }
  }, [data]);

  /* active organization set or changed - load org details */
  useEffect(() => {
    if (activeOrganizationId !== undefined) {
      fetchOrg();
      localStorage.setItem('activeOrganizationId', activeOrganizationId);
    }
  }, [activeOrganizationId, fetchOrg, localStorage]);

  /* updated closed */
  useEffect(() => {
    localStorage.setItem('closed', closed ? 'true' : 'false');
  }, [closed]);

  const setOrgPublicSettings = (orgSettings: any) => {
    if (orgSettings?.faviconLink) {
      setFaviconLink(orgSettings?.faviconLink);
    }
    if (orgSettings?.browserTabTitle) {
      setBrowserTabTitle(orgSettings?.browserTabTitle);
    }
  };

  /* organization profile (FETCH_ORGANIZATION) has been loaded */
  useEffect(() => {
    if (organizationData?.fetchOrganization?.organization) {
      const org: any = omitDeep(organizationData.fetchOrganization.organization, '__typename');
      const baseColor = org.theme?.sideBarColor || '#22394F';

      const graphColors = [
        baseColor,
        adjust(baseColor, -10),
        adjust(baseColor, -20),
        adjust(baseColor, -30),
        adjust(baseColor, -40),
        adjust(baseColor, 10),
        adjust(baseColor, 20),
        adjust(baseColor, 30),
        adjust(baseColor, 40),
        adjust(baseColor, 50),
      ];

      org.theme = { ...org.theme, graphColors };
      if (!org.applicableLocalization) org.applicableLocalization = LOCALIZATION_DEFAULTS;
      setActiveOrganization(org as ActiveOrganizationProps);
      setToastTheme(org?.themeTokens?.comp);

      availableFeatureFlagsVar(org.availableFeatureFlags);
      setCustodianConnection(org?.defaultCustodianConnection);
      localStorage.setItem('activeOrganizationData', JSON.stringify(org));
      setBaseCurrency(org.applicableLocalization.defaultCurrency);
      setActiveCurrency(org.applicableLocalization.defaultCurrency);
      setOrgPublicSettings(org);
    }
  }, [organizationData, localStorage]);

  return (
    <UserContext.Provider value={{
      userContext,
      setUserContext,
      userId,
      setUserId,
      activeOrganization,
      setActiveOrganizationId,
      activeEntity,
      setActiveEntity,
      activeHousehold,
      setActiveHousehold,
      activeLanguage,
      setActiveLanguage,
      organizationIds,
      setOrganizationIds,
      loading,
      integrations,
      custodianConnection,
      activeCurrency,
      setActiveCurrency,
      baseCurrency,
      closed,
      setClosed,
      refetch,
    }}>
      {localStorage.getItem('activeOrganizationData') && children}
    </UserContext.Provider>
  );
};

const UserContextConsumer = UserContext.Consumer;

const usePermissions = () => {
  const userContext = useContext(UserContext);
  const [permissions, setPermissions] = useState<string[]>(userContext.userContext?.role?.permissions || []);
  const [accessiblePages, setAccessiblePages] = useState<string[]>(userContext.userContext?.role?.accessiblePages || []);

  useEffect(() => {
    if (userContext.userContext) {
      setPermissions(userContext.userContext.role?.permissions || []);
      setAccessiblePages(userContext.userContext.role?.accessiblePages || []);
    }
  }, [userContext]);

  return { permissions, accessiblePages };
};

export {
  UserContext, UserContextConsumer, usePermissions,
};

export default UserContextProvider;
