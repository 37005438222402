import { useTranslation } from 'react-i18next';
import { ClientReportTemplate, ClientReportTemplate as ClientReportTemplateType } from '../../../../interfaces';
import {
  Box, Typography,
} from '../../../1-primative';
import {
  Card,
  CardContent,
} from '../../../2-component';
import { SelectionTile } from '../../../3-pattern';

export enum SchedulerFrequency {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY',
}

export const Frequency = ({
  template,
  setFrequency,
}: {
  template: ClientReportTemplate,
  setFrequency: (newFreq: ClientReportTemplateType['scheduler']['frequency']) => void
}) => {
  const { t } = useTranslation('clientReportTemplate');

  return (
    <Card>
      <CardContent>
        <Typography variant='titleMedium'>Report generation frequency</Typography>
        <Box py={1} my={1}>
          <SelectionTile
            value={template.scheduler.frequency}
            direction='row'
            options={[
              { label: t('frequencies.monthly'), subtitle: t('frequencies.onceAMonth'), value: 'MONTHLY' },
              { label: t('frequencies.quarterly'), subtitle: t('frequencies.onceAQuarter'), value: 'QUARTERLY' },
              { label: t('frequencies.yearly'), subtitle: t('frequencies.onceAYear'), value: 'YEARLY' },
            ]}
            onChange={(e: any) => setFrequency(e.target.value)}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
