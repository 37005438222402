/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { translateBackend } from 'assets/i18n/config';
import { round } from 'lodash';
import dayjs from 'dayjs';
import { invalidFields } from '../utils';
import {
  Button, Typography, Form, Box, SubAccountSelect, AmountField, Grid, SelectField, MenuItem, PageObjectType, DateField,
} from '../../../..';
import { TransferContext } from '../../../withdrawWorkflow/withdrawWorkflow';
import { BankAccountSelectField } from '../../../../3-pattern/bankAccountSelectField/bankAccountSelect';
import WithdrawalBreakdown from './withdrawalBreakdown';
import { usePermissions, UserContext } from '../../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { validateTransfer, ValidateTransferResponse } from '../../../../accountRestrictions';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import {
  TransferErrorBanner,
} from '../../../../transferErrorBanner';

export const WithdrawForm = ({
  options, onNext,
}: {
  options: any, onNext: any,
}) => {
  const { t } = useTranslation(['workflowCompletions', 'transfer']);
  const { showToast } = useGlobalToast();
  const { permissions } = usePermissions();
  const { transferData, setTransferData } = useContext(TransferContext);
  const [focused, setFocused] = useState<string[]>([]);
  const { userId: paramsUserId } = useParams();
  const { activeEntity, userContext } = useContext(UserContext);
  const userId = paramsUserId ?? activeEntity?.id;
  const { sys } = useThemeTokens();
  const hyperLinkColor = sys.color.negativeOutline;
  const supportUrl = userContext.organization?.supportUrl || 'https://onevest.zendesk.com/hc/en-us/requests/new';
  const IS_RRSP = ['RRSP', 'RRSP_SPOUSAL'].includes(transferData.subAccount?.account?.type);
  const IS_RRSP_AND_LLP_OR_HBP_SELECTED = IS_RRSP && ['HOME_BUYERS_PLAN', 'LIFELONG_LEARNING_PLAN'].includes(transferData.withdrawalReason);
  const IS_RESP = ['RESP', 'RESP_ADULT', 'RESP_FAMILY', 'RESP_FAMILY_JOINT', 'RESP_SINGLE', 'RESP_SINGLE_JOINT'].includes(transferData.subAccount?.account?.type);
  const IS_LOCKED_IN = ['LIRA', 'LRSP', 'RLSP'].includes(transferData.subAccount?.account?.type);
  const IS_INCOME_FUND_ACCOUNT = ['RRIF', 'RIF_SPOUSAL', 'LIF', 'PRIF', 'LRIF', 'RLIF'].includes(transferData.subAccount?.account?.type);
  const disableForm = (IS_RRSP && transferData.withdrawalReason === 'LIFELONG_LEARNING_PLAN')
  || (IS_RRSP && transferData.withdrawalReason === 'HOME_BUYERS_PLAN')
  || IS_RESP || IS_LOCKED_IN || IS_INCOME_FUND_ACCOUNT;
  const submit = () => {
    if (IS_RRSP_AND_LLP_OR_HBP_SELECTED) return;
    const fields = invalidFields(options, transferData);
    setFocused(fields);
    const transferValidation: ValidateTransferResponse = validateTransfer({
      accountTo: transferData?.subAccount?.account?.type,
      transferType: 'electronicFundsTransferOut',
      canOverrideTransferRestrictions: permissions.includes('write:override_transfer_restrictions'),
    });
    if (!transferValidation.isValid) {
      showToast({ message: transferValidation.message, severity: 'error' });
      return;
    }
    if (fields.length === 0) {
      onNext();
    }
  };

  const subAccountFilter = () => {
    switch (transferData.objectType) {
      case PageObjectType.INDIVIDUAL:
        return { userId: transferData.objectId };
      case PageObjectType.NON_INDIVIDUAL:
        return { userId: transferData.objectId };
      case PageObjectType.ACCOUNT:
        return { accountId: transferData.objectId };
      case PageObjectType.GOAL:
        return { goalId: transferData.objectId };
      case PageObjectType.HOUSEHOLD:
        return { clientGroupId: transferData.objectId };
      default:
        return { userId };
    }
  };
  useEffect(() => {
    if (transferData.amountCents > transferData.subAccount?.statistics?.availableFundsToWithdrawCents) {
      setTransferData({ ...transferData, amountCents: transferData.subAccount?.statistics?.availableFundsToWithdrawCents });
    }
  }, [transferData, setTransferData]);
  return (
    <Form onSubmit={submit}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>{translateBackend(options?.subtitle)}</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          {options?.subAccount?.enabled && (
            <SubAccountSelect
              label={translateBackend(options?.subAccount?.label)}
              onSubAccountSelect={(e: any) => setTransferData({ ...transferData, subAccount: e })}
              selectedSubAccount={transferData.subAccount}
              filter={subAccountFilter()}
              filterNotAvailableWithdraw
              sx={{ mt: 2, mb: 2 }}
              onBlur={() => setFocused([...focused, 'subAccount'])}
              error={!transferData.subAccount?.id && focused.includes('subAccount') && options?.subAccount?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.bankAccount?.enabled && (
            <BankAccountSelectField
              setBankAccount={(e: any) => setTransferData({ ...transferData, bankAccount: e })}
              bankAccount={transferData.bankAccount}
              userId={userId!}
              sx={{ mb: 2 }}
              onBlur={() => setFocused([...focused, 'bankAccount'])}
              error={!transferData.bankAccount?.id && focused.includes('bankAccount') && options?.bankAccount?.required !== 'NOT_REQUIRED'}
              transactionType="WITHDRAWAL"
            />
          )}
          {options?.amountCents?.enabled && (
            <AmountField
              sx={{ mb: 2 }}
              label={translateBackend(options?.amountCents?.label)}
              useFormatAmountValueCents
              setAmount={(e: any) => setTransferData({ ...transferData, amountCents: round(parseFloat(e) * 100, 2) })}
              amount={transferData.amountCents}
              onBlur={() => setFocused([...focused, 'amountCents'])}
              error={!transferData.amountCents && focused.includes('amountCents') && options?.amountCents?.required !== 'NOT_REQUIRED'}
            />
          )}
          <SelectField
              label='Frequency'
              onChange={(e: any) => setTransferData({ ...transferData, frequency: e.target.value })}
              value={transferData.frequency}
              fullWidth
              sx={{ mb: 2 }}
              onBlur={() => setFocused([...focused, 'frequency'])}
              error={!transferData.frequency && focused.includes('frequency') && options?.frequency?.required !== 'NOT_REQUIRED'}
          >
            <MenuItem value='ONE_TIME'>{t('transfer:frequencyOption.ONE_TIME')}</MenuItem>
            <MenuItem value='WEEKLY'>{t('transfer:frequencyOption.WEEKLY')}</MenuItem>
            <MenuItem value='BI_WEEKLY'>{t('transfer:frequencyOption.BI_WEEKLY')}</MenuItem>
            <MenuItem value='MONTHLY'>{t('transfer:frequencyOption.MONTHLY')}</MenuItem>
            <MenuItem value='SEMI_MONTHLY'>{t('transfer:frequencyOption.SEMI_MONTHLY')}</MenuItem>
            <MenuItem value='QUARTERLY'>{t('transfer:frequencyOption.QUARTERLY')}</MenuItem>
          </SelectField>
          {['WEEKLY', 'BI_WEEKLY', 'MONTHLY', 'SEMI_MONTHLY', 'QUARTERLY'].includes(transferData.frequency) && (
              <DateField
                  onChange={(date: any) => setTransferData({ ...transferData, scheduledDate: dayjs(date?.toString()).format('YYYY-MM-DD') })}
                  label={t('transfer:withdrawal.starting')}
                  minDate={dayjs().add(1, 'day')}
                  fullWidth
                  sx={{ mb: 2 }}
                  value={transferData.scheduledDate}
                  error={!transferData.scheduledDate && options?.scheduledDate?.required !== 'NOT_REQUIRED'}
              />
          )}
          {options?.withdrawalReason?.enabled && (
            <SelectField
              label={translateBackend(options?.withdrawalReason?.label)}
              onChange={(e: any) => setTransferData({ ...transferData, withdrawalReason: e.target.value })}
              value={transferData.withdrawalReason}
              fullWidth
              sx={{ mb: 2 }}
              onBlur={() => setFocused([...focused, 'withdrawalReason'])}
              error={!transferData.withdrawalReason && focused.includes('withdrawalReason') && options?.withdrawalReason?.required !== 'NOT_REQUIRED'}
            >
              <MenuItem value='COMPLETED_MY_GOAL'>{t('transfer:withdrawal.withdrawalReasonOptions.COMPLETED_MY_GOAL')}</MenuItem>
              <MenuItem value='DISSATISFIED_WITH_ONEVEST'>{t('transfer:withdrawal.withdrawalReasonOptions.DISSATISFIED_WITH_ONEVEST')}</MenuItem>
              <MenuItem value='INVESTING_IS_NOT_FOR_ME'>{t('transfer:withdrawal.withdrawalReasonOptions.INVESTING_IS_NOT_FOR_ME')}</MenuItem>
              <MenuItem value='POOR_RETURNS'>{t('transfer:withdrawal.withdrawalReasonOptions.POOR_RETURNS')}</MenuItem>
              {['RRSP', 'RRSP_SPOUSAL'].includes(transferData.subAccount?.account?.type) && (
                <MenuItem value='HOME_BUYERS_PLAN'>{t('transfer:withdrawal.withdrawalReasonOptions.HOME_BUYERS_PLAN')}</MenuItem>
              )}
              {['RRSP', 'RRSP_SPOUSAL'].includes(transferData.subAccount?.account?.type) && (
                <MenuItem value='LIFELONG_LEARNING_PLAN'>{t('transfer:withdrawal.withdrawalReasonOptions.LIFELONG_LEARNING_PLAN')}</MenuItem>
              )}
              <MenuItem value='OTHER'>{t('transfer:withdrawal.withdrawalReasonOptions.OTHER')}</MenuItem>
            </SelectField>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <WithdrawalBreakdown
            accountId={transferData.subAccount?.account?.id}
            availableToWithdrawCents={transferData.subAccount?.statistics?.availableFundsToWithdrawCents}
            withdrawAmountCents={transferData.amountCents}
          />
          { IS_RRSP && transferData.withdrawalReason === 'LIFELONG_LEARNING_PLAN' && (<TransferErrorBanner
              sys={sys}
              htmlString={t('transfer:withdrawal.withdrawRrspAccountLlpPlanError', { color: hyperLinkColor, supportUrl })}
          />)}
          { IS_RRSP && transferData.withdrawalReason === 'HOME_BUYERS_PLAN' && (<TransferErrorBanner
              sys={sys}
              htmlString={t('transfer:withdrawal.withdrawRrspAccountHbpPlanError', { color: hyperLinkColor, supportUrl })}
          />)}
          { IS_RESP && (<TransferErrorBanner
            sys={sys}
            htmlString={t('transfer:withdrawal.withdrawRespAccountError', { color: hyperLinkColor, supportUrl })}
          />)}
          { IS_LOCKED_IN && (<TransferErrorBanner
            sys={sys}
            htmlString={t('transfer:withdrawal.withdrawLockedInAccountError', { color: hyperLinkColor, supportUrl })}
          />)}
          { IS_INCOME_FUND_ACCOUNT && (<TransferErrorBanner
              sys={sys}
              htmlString={t('transfer:withdrawal.withdrawIncomeFundsAccountError', { color: hyperLinkColor, supportUrl, accountType: t(`accountTypes:${transferData.subAccount?.account?.type}`) })}
          />)}
        </Grid>
      </Grid>
      <Box display='flex' justifyContent='end'>
        <Button disabled={disableForm} label={t('continue')} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
      </Box>
    </Form>
  );
};

export default WithdrawForm;
