import accountsDetail from './accountsDetail.json';
import goalsDetails from './goalsDetails.json';
import accountsReview from './accountsReview.json';
import buildModels from './buildModels.json';
import bulkTrader from './bulkTrader.json';
import bulkImport from './bulkImport.json';
import configureModels from './configureModels.json';
import client from './client.json';
import clients from './clients.json';
import nonIndividualClients from './nonIndividualClients.json';
import corporations from './corporations.json';
import components from './components.json';
import customReports from './customReports.json';
import document from './document.json';
import navMenu from './navMenu.json';
import reconciliation from './reconciliation.json';
import reports from './reports.json';
import devSettings from './devSettings.json';
import entityTypes from './entityTypes.json';
import relatedEntityTypes from './relatedEntityTypes.json';
import relatedEntitySelect from './relatedEntitySelect.json';
import orgSettings from './orgSettings.json';
import rebalance from './rebalance.json';
import rebalanceReportV1 from './rebalanceReportV1.json';
import rebalanceReport from './rebalanceReport.json';
import rebalances from './rebalances.json';
import shared from './shared.json';
import accountTypes from './accountTypes.json';
import household from './household.json';
import schedule from './schedule.json';
import taxLossHarvesting from './taxLossHarvesting.json';
import statementsReview from './statementsReview.json';
import subaccount from './subaccount.json';
import annualSuitabilityReview from './annualSuitabilityReview.json';
import feeAndBilling from './feeAndBilling.json';
import affiliationTypes from './affiliationTypes.json';
import subAccountsDetail from './subAccountsDetail.json';
import bankAccountsReview from './bankAccountsReview.json';
import createGoalWizard from './createGoalWizard.json';
import createSubAccountWizard from './createSubAccountWizard.json';
import createAccountWizard from './createAccountWizard.json';
import feePayment from './feePayment.json';
import householdFeePaymentModal from './householdFeePaymentModal.json';
import institutionsReview from './institutionsReview.json';
import addHouseholdMemberWizard from './addHouseholdMemberWizard.json';
import editHouseholdMemberWizard from './editHouseholdMemberWizard.json';
import addRelatedEntityWizard from './addRelatedEntityWizard.json';
import workflowCompletions from './workflowCompletions.json';
import addClientToHouseholdWizard from './addClientToHouseholdWizard.json';
import addIdVerificationWizard from './addIdVerificationWizard.json';
import portfolioOptimizer from './portfolioOptimizer.json';
import pageConfiguration from './pageConfiguration.json';
import transfer from './transfer.json';
import errorBoundaries from './errorBoundaries.json';
import entityMenu from './entityMenu.json';
import authentication from './authentication.json';
import paymentInstruction from './paymentInstruction.json';
import householdSelection from './householdSelection.json';
import employmentInfo from './employmentInfo.json';
import clientReportTemplate from './clientReportTemplate.json';
import financialProjection from './financialProjection.json';
import transferRestrictions from './transferRestriction.json';
import geoNames from './geoNames.json';
import newsAndInsight from './newsAndInsight.json';
import projectedIncomeReport from './projectedIncomeReport.json';
import taxId from './taxId.json';
import modelPortfolios from './modelPortfolios.json';
import formTypes from './formTypes.json';

const fr = {
  accountsDetail,
  accountsReview,
  buildModels,
  bulkTrader,
  bulkImport,
  configureModels,
  client,
  clients,
  nonIndividualClients,
  corporations,
  components,
  customReports,
  document,
  employmentInfo,
  navMenu,
  reconciliation,
  reports,
  devSettings,
  entityTypes,
  relatedEntityTypes,
  relatedEntitySelect,
  orgSettings,
  rebalance,
  rebalanceReportV1,
  rebalances,
  shared,
  accountTypes,
  household,
  schedule,
  taxLossHarvesting,
  statementsReview,
  subaccount,
  annualSuitabilityReview,
  feeAndBilling,
  affiliationTypes,
  goalsDetails,
  subAccountsDetail,
  bankAccountsReview,
  createGoalWizard,
  createSubAccountWizard,
  createAccountWizard,
  feePayment,
  householdFeePaymentModal,
  institutionsReview,
  addHouseholdMemberWizard,
  editHouseholdMemberWizard,
  addRelatedEntityWizard,
  workflowCompletions,
  addClientToHouseholdWizard,
  rebalanceReport,
  addIdVerificationWizard,
  portfolioOptimizer,
  pageConfiguration,
  transfer,
  errorBoundaries,
  entityMenu,
  authentication,
  paymentInstruction,
  householdSelection,
  clientReportTemplate,
  financialProjection,
  transferRestrictions,
  geoNames,
  newsAndInsight,
  projectedIncomeReport,
  taxId,
  modelPortfolios,
  formTypes,
};

export const namespaces = Object.keys(fr);
export const defaultNS = 'base';

export default fr;
