import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import NewProduct from './components/newProduct';
import EditProduct from './components/editProduct';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { formatMoneyValue } from '../../../util';
import { usePageState } from '../../../util/usePageState';
import { textColumnStyles } from '../../../util/styles';
import { Exchanges } from '../../../interfaces/financialProduct';
import {
  Typography, Box,
  Skeleton,
} from '../../1-primative';
import {
  Table, TableRow, TableCell, TableBody, Pagination, Switch, TextField,
  MenuItem, Card, CardContent, SelectField, TableHeadCell, Badge,
} from '../../2-component';
import {
  AssetClassSelect,
  FilterModal,
} from '../../3-pattern';
import TaxRank from '../../4-module/widgets/portfolio/components/taxRank';

export const FETCH_FINANCIAL_PRODUCTS = gql`
  query fetchFinancialProducts($input: FetchFinancialProductsInput!) {
    fetchFinancialProducts(input: $input) {
      financialProducts {
        id
        url
        translatedName {
          en
          fr
        }
        ticker
        exchange
        currentPriceCents
        autoUpdatePrices
        primaryAssetClass {
          id
          translatedName {
            en
          }
        }
        secondaryAssetClass {
          id
          translatedName {
            en
          }
        },
        tertiaryAssetClass {
          id
          translatedName {
            en
          }
        },
        substituteFinancialProduct {
          id
          ticker
          translatedName {
            en
          }
        },
        schedule{
          id
          name
          nextDate
        }
        waivedFeePercentage
        taxRank
        volatile
        settlementDays,
        state
        isPartial
        cusip
        isin
        ric
      }
      totalCount
    }
  }
`;

export const ProductShelf = () => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation(['configureModels']);
  const [productOpen, setProductOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [page, setPage] = usePageState(1, 'page');
  const pageSize = 15;
  const [filterByExchange, setFilterByExchange] = usePageState('', 'exchange');
  const [filterByPrimaryAssetClassId, setFilterByPrimaryAssetClassId] = usePageState('', 'primaryClass');
  const [filterBySecondaryAssetClassId, setFilterBySecondaryAssetClassId] = usePageState('', 'secondaryClass');
  const [filterByHasSubstituteProduct, setFilterByHasSubstituteProduct] = usePageState(false, 'hasSubstitute');
  const [filterByIsScheduled, setFilterByIsScheduled] = usePageState(false, 'isScheduled');
  const [filterByIsAutoUpdatePrices, setFilterByIsAutoUpdatePrices] = usePageState(false, 'isAutoUpdatePrices');
  const [searchString, setSearchString] = usePageState('', 'q');
  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_FINANCIAL_PRODUCTS, {
    skip: !activeOrganization.id,
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          searchText: searchString,
          exchange: filterByExchange || undefined,
          primaryAssetClassId: filterByPrimaryAssetClassId || undefined,
          secondaryAssetClassId: filterBySecondaryAssetClassId || undefined,
          hasSubstituteProduct: filterByHasSubstituteProduct || undefined,
          isScheduled: filterByIsScheduled || undefined,
          autoUpdatePrices: filterByIsAutoUpdatePrices || undefined,
        },
        pagination: {
          sortField: 'ticker', sortDesc: false, perPage: pageSize, offset: (page - 1) * pageSize,
        },
      },
    },
  });

  const exchanges = Object.keys(Exchanges).filter((key) => isNaN(Number(key))).sort();

  if (error) (<Typography>Error</Typography>);

  return (
    <Card loading={loading}>
      <CardContent>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <TextField
            label=''
            sx={{ width: '300px', m: 1 }}
            value={searchString}
            onChange={(event: any) => {
              setSearchString(event.target.value);
              setPage(1);
            }}
            leadingIcon='search'
          />
          <Box display='flex'>
            <FilterModal>
              <Box display='flex' flexDirection='column' gap={2}>
                <SelectField label={t('filters.exchange')} fullWidth
                  value={filterByExchange || ''}
                  onChange={(e: any) => {
                    setFilterByExchange(e.target.value);
                    setPage(1);
                  }}
                >
                  {exchanges.map((option) => (
                    <MenuItem key={option} value={option}>
                    {option}
                    </MenuItem>
                  ))}
                </SelectField>
                <AssetClassSelect
                  value={filterByPrimaryAssetClassId}
                  setAssetClass={(e) => {
                    setFilterByPrimaryAssetClassId(e.target.value);
                    setPage(1);
                  }}
                  label={t('filters.primaryAssetClass')}
                />
                <AssetClassSelect
                  value={filterBySecondaryAssetClassId}
                  setAssetClass={(e) => {
                    setFilterBySecondaryAssetClassId(e.target.value);
                    setPage(1);
                  }}
                  label={t('filters.secondaryAssetClass')}
                />
                <Switch
                  checked={filterByHasSubstituteProduct}
                  onChange={async (checked: boolean) => {
                    setFilterByHasSubstituteProduct(checked);
                    setPage(1);
                  }}
                  label={t('filters.hasSubstitute')}
                />
                <Switch
                  checked={filterByIsScheduled}
                  label={t('filters.isScheduled')}
                  onChange={async (checked) => {
                    setFilterByIsScheduled(checked);
                    setPage(1);
                  }}
                />
                <Switch
                  checked={filterByIsAutoUpdatePrices}
                  onChange={async (checked) => {
                    setFilterByIsAutoUpdatePrices(checked);
                    setPage(1);
                  }}
                  label={t('filters.autoUpdatePrices')}
                />
              </Box>
            </FilterModal>
            {permissions.includes('write:financial_products') && (
              <NewProduct afterCreate={refetch} />
            )}
          </Box>
        </Box>
      </CardContent>
      <Table aria-label="table">
        <TableBody>
          <TableRow>
            <TableHeadCell>{t('table.ticker')}</TableHeadCell>
            <TableHeadCell>{t('table.name')}</TableHeadCell>
            <TableHeadCell>{t('table.exchange')}</TableHeadCell>
            <TableHeadCell>{t('table.primaryAssetClass')}</TableHeadCell>
            <TableHeadCell>{t('table.secondaryAssetClass')}</TableHeadCell>
            <TableHeadCell>{t('table.substituteProduct')}</TableHeadCell>
            <TableHeadCell>{t('table.taxRank')}</TableHeadCell>
            <TableHeadCell>{t('table.tradeSchedule')}</TableHeadCell>
            <TableHeadCell right>{t('table.currentPrice')}</TableHeadCell>
            <TableHeadCell right>{t('table.autoUpdatePrices')}</TableHeadCell>
          </TableRow>
          { loading && !previousData && [...Array(15)].map((x: any, i: number) => (
            <TableRow key={i}>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
              <TableCell><Skeleton width='100%' /></TableCell>
            </TableRow>
          ))}
          {(data || previousData)?.fetchFinancialProducts?.financialProducts?.map((product: any) => (
            <TableRow
              hover
              onClick={() => {
                if (permissions.includes('write:financial_products')) {
                  setSelectedProduct(product);
                  setProductOpen(true);
                }
              }}
              key={product.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none', cursor: 'pointer' }}
            >
              <TableCell component="th" scope="row">
                {product.ticker}
              </TableCell>
              <TableCell>
                <Typography sx={textColumnStyles}>{product.translatedName.en}</Typography>
              </TableCell>
              <TableCell><Badge label={product.exchange} /></TableCell>
              <TableCell><Typography sx={textColumnStyles}>{product.primaryAssetClass?.translatedName?.en}</Typography></TableCell>
              <TableCell><Typography sx={textColumnStyles}>{product.secondaryAssetClass?.translatedName?.en}</Typography></TableCell>
              <TableCell title={product.substituteFinancialProduct?.translatedName?.en}>{product.substituteFinancialProduct?.ticker}</TableCell>
              <TableCell component="th" scope="row">
                { product.taxRank && (<TaxRank taxRank={product.taxRank} />) }
              </TableCell>
              <TableCell>{product?.schedule ? t('table.planned') : t('table.noSchedule')}</TableCell>
              <TableCell number>{formatMoneyValue(product.currentPriceCents)}</TableCell>
              <TableCell right>{product?.autoUpdatePrices ? t('table.yes') : t('table.no')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={Math.ceil(((data || previousData)?.fetchFinancialProducts?.totalCount ?? 0) / pageSize)}
        page={page}
        onChange={(_e, newPage) => setPage(newPage)}
        sx={{
          p: 1,
          textAlign: 'right',
          '.MuiPagination-ul': {
            justifyContent: 'end',
          },
        }}
      />
      {permissions.includes('write:financial_products') && (
        <EditProduct afterUpdate={() => {
          setProductOpen(false);
          refetch();
        }} productToUpdate={selectedProduct} open={productOpen} handleClose={() => setProductOpen(false)} />
      )}
    </Card>
  );
};

export default ProductShelf;
