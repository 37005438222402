/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import dayjs from 'dayjs';
import { isNil } from 'lodash/fp';
import { useContext, useEffect, useState } from 'react';
import { ScriptableContext } from 'chart.js';
import convert from 'color-convert';
import {
  Typography, Box, Grid, Skeleton,
} from '../../1-primative';
import {
  Card, CardContent, Popover, CircularProgress, LineChart,
} from '../../2-component';
import { useLocalization } from '../../../util/useLocalization';
import { formatMoneyValue, formatPercentValue } from '../../../util';
import { ObjectType, useStats } from '../../../providers/statsHooks';
import ArrowDownRightRed from '../../../assets/images/icons-outline/arrow-down-right-red.svg';
import ArrowUpRightGreen from '../../../assets/images/icons-outline/arrow-up-right-green.svg';
import { usePageState } from '../../../util/usePageState';
import { useThemeTokens } from '../../../providers/themeTokenProvider';

import EmptyChart from '../../../assets/images/custom/emptyChart.svg';
import { RateOfReturnInformation } from './rateOfReturnInformation';
import { translateBackend } from '../../../assets/i18n/config';
import InfoButton from '../../2-component/infoButton/infoButton';
import MarketValueIndicatorIcon from '../../../assets/images/market-value-indicator-icon.svg';

// TODO: Replace with new components
import { SimpleDateRangePicker } from '../../../components/fields/simpleDateRangePicker';
import { PageObjectType } from '../../5-page';
import { GoalCompletion } from './goalCompletion';
import { HouseholdMembers } from './householdMembers/householdMembers';
import { ChangeTheme } from './changeTheme/changeTheme';
import { UserContext } from '../../../providers/userContextProvider';
import { ValueSuperscriptedCents } from '../../3-pattern';
import { AccountHighlights } from './accountHighlights/accountHighlights';
import { useLineChartTokens } from '../../2-component/lineChart/lineChart.tokens';
import { RecentTransactions } from './recentTransactions/recentTransactions';

type HistoricalTimeFrames = 'custom' | '1M' | '3M' | '1Y' | 'YTD' | 'MAX';

const startDateFunc = (timeFrame: HistoricalTimeFrames): string | undefined => {
  if (timeFrame === 'YTD') return dayjs().startOf('year').format('YYYY-MM-DD');
  if (timeFrame.endsWith('M')) return dayjs().subtract(Number(timeFrame.slice(0, -1)), 'month').format('YYYY-MM-DD');
  if (timeFrame.endsWith('Y')) return dayjs().subtract(Number(timeFrame.slice(0, -1)), 'year').format('YYYY-MM-DD');
  return undefined; // meaning inception date aka "MAX" time
};

const objectTypeMapping = (objectType: PageObjectType): ObjectType => {
  const types = {
    [PageObjectType.INDIVIDUAL]: ObjectType.USER,
    [PageObjectType.NON_INDIVIDUAL]: ObjectType.USER,
    [PageObjectType.HOUSEHOLD]: ObjectType.CLIENT_GROUP,
    [PageObjectType.GOAL]: ObjectType.GOAL,
    [PageObjectType.SUB_ACCOUNT]: ObjectType.SUB_ACCOUNT,
    [PageObjectType.ACCOUNT]: ObjectType.ACCOUNT,
  };
  return types[objectType];
};

export const MarketValueChart = ({
  type, id, needUpdate, onlyUserId, options = {},
}: { type: PageObjectType, id: string, needUpdate?: number, onlyUserId?: string, options?: any }) => {
  const netContributionIndicatorImageSize = 16;
  const { t } = useTranslation(['client', 'components']);
  const { localizedDate } = useLocalization();
  const [timeFrame, setTimeFrame] = usePageState<HistoricalTimeFrames>(options.defaultTimeRange || 'MAX', 'assets/timeframe');
  const [customStartDate, setCustomStartDate] = usePageState<string>('', 'assets/startdate');
  const [customEndDate, setCustomEndDate] = usePageState<string>('', 'assets/enddate');
  const [customDateRangePopover, setCustomDateRangePopover] = useState<HTMLButtonElement | null>(null);
  const [hideGraph] = useState(false);
  const { sys, comp } = useThemeTokens(useLineChartTokens());
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const { activeCurrency, activeOrganization } = useContext(UserContext);
  const [mouseIsOverChart, setMouseIsOverChart] = useState<boolean>(false);
  const [activeMarketValueCents, setActiveMarketValueCents] = useState<number | undefined>();
  const [activeNetContributionCents, setActiveNetContributionCents] = useState<number | undefined>();
  const dateTimeFormatOption: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
  };

  const showNetContributionOnHover = options.displayNetContributions && mouseIsOverChart && activeNetContributionCents !== undefined;
  const getNetContributionWithCurrency = (value: number, currency = 'USD'): string => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(value / 100);

  const tooltipLine = {
    id: 'tooltipLine',
    afterDraw: (chart: any) => {
      const chartPointNetContributionIcon = new Image();
      chartPointNetContributionIcon.src = MarketValueIndicatorIcon;
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const ctx3 = chart.ctx;
        ctx3.save();
        const activePoint = chart.tooltip._active[0];
        const netContributionPoint = chart.tooltip._active[1] || null;

        // Get the index of the active tooltip
        const { index } = activePoint;
        const dateLabel = chart.data.labels[index];

        // Market Value Cents
        const hoveredMarketValue = chart.data.datasets[0].data[index];
        setActiveMarketValueCents(hoveredMarketValue);
        if (options?.displayNetContributions) {
          const hoveredNetContribution = chart.data.datasets[1].data[index];
          setActiveNetContributionCents(hoveredNetContribution);
        }

        ctx3.beginPath();
        ctx3.setLineDash([2, 2]);

        ctx3.moveTo(activePoint.element.x, chart.chartArea.top + 5);
        ctx3.lineTo(activePoint.element.x, activePoint.element.y);
        ctx3.lineWidth = 1;
        ctx3.strokeStyle = comp.lineChart.secondaryLine;
        ctx3.stroke();

        // Add text above the dashed line
        const width = ctx3.canvas.offsetWidth;
        ctx3.font = '14px Interphases Pro';
        ctx3.fillStyle = comp.lineChart.secondaryLine;
        ctx3.textAlign = activePoint.element.x <= 42 ? 'left' : activePoint.element.x >= width - 42 ? 'right' : 'center';
        ctx3.fillText(new Date(dateLabel).toLocaleDateString('en-US', dateTimeFormatOption), activePoint.element.x, chart.chartArea.top - 15);

        ctx3.restore();
        ctx3.beginPath();
        ctx3.setLineDash([2, 2]);

        ctx3.moveTo(activePoint.element.x, activePoint.element.y);
        ctx3.lineTo(activePoint.element.x, chart.chartArea.bottom);
        ctx3.lineWidth = 1;
        ctx3.strokeStyle = comp.lineChart.secondaryLine;
        ctx3.stroke();

        // Draw the net contribution icon on top of the line
        if (options.displayNetContributions && netContributionPoint) {
          ctx3.drawImage(
            chartPointNetContributionIcon,
            (netContributionPoint.element.x - 7), // Adjust positioning as needed
            (netContributionPoint.element.y - 7), // Adjust positioning as needed
            netContributionIndicatorImageSize, // Width of the image
            netContributionIndicatorImageSize, // Height of the image
          );
        }

        ctx3.restore();
      }
    },
  };
  const overrideLineOptions = {
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
  };

  useEffect(() => {
    setTimeFrame(options.defaultTimeRange);
  }, [options.defaultTimeRange]);

  useEffect(() => {
    if (timeFrame !== 'custom') {
      setStartDate(startDateFunc(timeFrame));
      setEndDate(undefined);
    }
    if (customStartDate && customEndDate) {
      setStartDate(dayjs(customStartDate).format('YYYY-MM-DD'));
      setEndDate(dayjs(customEndDate).format('YYYY-MM-DD'));
    }
  }, [timeFrame, customStartDate, customEndDate]);

  const useCustodianData = [ObjectType.USER, ObjectType.ACCOUNT, ObjectType.CLIENT_GROUP].includes(objectTypeMapping(type)) && options.useExternalStatistics;
  const {
    statistics, loading, history, historyLoading, refetchAll, object,
  } = useStats(
    {
      type: objectTypeMapping(type),
      id,
      startDate,
      endDate,
      onlyUserId,
      useCustodianData,
      fetchHistory: options.displayMarketValueHistory,
      currency: activeCurrency,
    },
  );
  const hasSideBar = () => {
    if (type === PageObjectType.ACCOUNT && !options.displayAccountHighlights && !options.showRecentTransactions) return false;
    if (type === PageObjectType.GOAL && (options.showCompletionPercentage === false || (object && !object.targetAmountCents))) return false;
    return [PageObjectType.GOAL, PageObjectType.ACCOUNT, PageObjectType.HOUSEHOLD, PageObjectType.SUB_ACCOUNT].includes(type);
  };

  const sideBar = () => {
    switch (type) {
      case (PageObjectType.GOAL):
        return (options?.showCompletionPercentage !== false) && (
          <>
            <GoalCompletion totalMarketValue={statistics?.marketValueCents ?? 0} goalValue={object.targetAmountCents} />
            { options.setModelPortfolio && (
              <ChangeTheme objectId={id} objectType={type} options={options} />
            )}
          </>
        );
      case (PageObjectType.ACCOUNT):
        return (
          <>
            {
              options.displayAccountHighlights ? (
                <AccountHighlights objectId={id} options={options} useCustodianData={useCustodianData} />
              ) : (
                options.showRecentTransactions && (
                  <RecentTransactions objectId={id} objectType={type} singleColumn options={{ useCustodianStatistics: options.useExternalStatistics }} />
                )
              )
            }
          </>
        );
      case (PageObjectType.HOUSEHOLD):
        return (
          <HouseholdMembers objectId={id} useCustodianData={useCustodianData} options={options} />
        );
      case (PageObjectType.SUB_ACCOUNT):
        return (
          <Box>
            <ChangeTheme objectId={id} objectType={type} options={options} />
          </Box>
        );
      default:
        return null;
    }
  };

  /* When the needUpdate counter gets incremented */
  useEffect(() => {
    if (!loading && !historyLoading) refetchAll();
  }, [needUpdate]);

  const showTimeWeightedReturn = !!options.showTimeWeightedReturn && !isNil(statistics?.timeWeightedReturn);
  const showMoneyWeightedReturn = !!options.showMoneyWeightedReturn && !isNil(statistics?.moneyWeightedReturn);
  const showSimpleRateOfReturn = !!options.showSimpleRateOfReturn && !isNil(statistics?.simpleReturnAmountCents);
  const showExchangeRates = (statistics?.foreignExchangeRates ?? []).length > 0 && !!options.displayFxRate;
  const foreignExchangeRates = (statistics?.foreignExchangeRates ?? []);

  const getChartHeight = (breakpoint: 'xs' | 'sm') => {
    let baseValue = breakpoint === 'xs' ? 141 : 101;
    if (showSimpleRateOfReturn) baseValue += 19;
    if (showTimeWeightedReturn || showMoneyWeightedReturn) baseValue += 28;
    if (!showSimpleRateOfReturn && !showTimeWeightedReturn && !showMoneyWeightedReturn) baseValue -= 4;

    return `calc(100% - ${baseValue}px)`;
  };
  const labels = history?.filter((x: any) => x.marketValueCents !== 0)?.map((x: any) => localizedDate(x.date)) ?? [];
  const rgb = convert.hex.rgb(comp.lineChart.color || '#000000');

  // this method pads (adds extra zeros) the net contribution cents array
  function getNetContributionsCentsArray(marketValueCentsArray: number[]): number[] {
    const netContributionCentsArray = history.filter((x: any) => x.marketValueCents !== 0).map((x: any) => x.netContributionCents);
    const lengthDifference = marketValueCentsArray.length - netContributionCentsArray.length;
    if (lengthDifference > 0) {
      return [...netContributionCentsArray, ...new Array(lengthDifference).fill(0)];
    }
    return netContributionCentsArray;
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={hasSideBar() ? 8 : 12}>
        <Card
          sx={{
            height: options.displayMarketValueHistory ? '450px' : undefined,
            ...(options.displayMarketValueHistory ? {} : { background: 'transparent', boxShadow: 'none' }),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
          fullWidthOnMobile
        >
          <CardContent sx={{ ...(options.displayMarketValueHistory ? {} : { paddingX: { sm: 0 } }) }}>
            <Grid container justifyContent='space-between'>
              <Grid item xs={12} sm={5}>
                <Box display='flex'>
                  <Typography data-testid='market-value-chart-total-assets' sx={{ color: sys.color.outline, mr: 1 }} variant='labelLarge'>{t('assetsOverview.totalAssets')}</Typography>
                  {(showTimeWeightedReturn || showMoneyWeightedReturn || showSimpleRateOfReturn || showExchangeRates) && (
                    <InfoButton
                      data-testid='market-value-chart-total-assets-info'
                      items={[
                        {
                          show: showTimeWeightedReturn,
                          information: translateBackend(options.timeWeightedReturnDefinition),
                          title: options.timeWeightedReturnLabel ? translateBackend(options.timeWeightedReturnLabel) : t('performanceMetricsModal.timeWeightedReturns'),
                        },
                        {
                          show: showMoneyWeightedReturn,
                          information: translateBackend(options.moneyWeightedReturnDefinition),
                          title: options.moneyWeightedReturnLabel ? translateBackend(options.moneyWeightedReturnLabel) : t('performanceMetricsModal.moneyWeightedReturns'),
                        },
                        {
                          show: showSimpleRateOfReturn,
                          information: translateBackend(options.simpleRateOfReturnDefinition),
                          title: options.simpleRateOfReturnLabel ? translateBackend(options.simpleRateOfReturnLabel) : t('performanceMetricsModal.simpleRateOfReturn'),
                        },
                        {
                          show: showExchangeRates,
                          information: <>
                            {foreignExchangeRates.map((elem: any) => <>
                              {t('performanceMetricsModal.fxRate.body', { from: elem.from, to: elem.to, rate: elem.rate })}<br />
                            </>)}
                          </>,
                          title: t('performanceMetricsModal.fxRate.title'),
                        },
                      ]}
                      title={t('performanceMetricsModal.title')}
                    />
                  )}
                </Box>
                {loading ? (
                  <>
                    <Skeleton width='200px' height='33px' variant='text' />
                    <Skeleton width='100px' height='19px' variant='text' />
                  </>
                ) : (
                  <>
                    <Box display='flex' gap={1}>
                      <ValueSuperscriptedCents
                        data-testid='market-value-chart-value-cent'
                        value={mouseIsOverChart ? activeMarketValueCents : statistics?.marketValueCents}
                        mainVariant='headingLarge'
                        centsVariant='titleLarge'
                        currency={activeCurrency as CurrencyCodes}
                        color={mouseIsOverChart ? comp.lineChart.color : undefined}
                      />
                      {activeOrganization.displayCurrency && <Typography sx={{ color: mouseIsOverChart ? comp.lineChart.color : undefined }} variant='titleLarge'>{`${activeCurrency}`}</Typography>}
                    </Box>
                    <Box minHeight="50px" maxHeight="50px" sx={{ marginTop: '0.25em' }} boxSizing="border-box">
                      {!showNetContributionOnHover && showSimpleRateOfReturn ? (
                          <Typography
                              data-testid='market-value-chart-simple-rate-of-return'
                              variant='bodySmall'
                              sx={{
                                color: (statistics?.simpleReturnAmountCents ?? 0) >= 0 ? sys.color.positive : sys.color.negative,
                              }}
                          >
                            <img
                                style={{ verticalAlign: 'bottom' }}
                                src={(statistics?.simpleReturnAmountCents ?? 0) >= 0 ? ArrowUpRightGreen : ArrowDownRightRed} alt="arrow-icon"
                            />
                            {formatMoneyValue(statistics?.simpleReturnAmountCents)} ({formatPercentValue(statistics?.simpleReturnPercent ?? 0)})
                          </Typography>
                      ) : <Box sx={{ height: '1px' }} /> }
                      {!showNetContributionOnHover && (showTimeWeightedReturn || showMoneyWeightedReturn)
                          && <Box mt={1}>
                            <RateOfReturnInformation rateOfReturns={[
                              {
                                title: options.timeWeightedReturnLabel ? translateBackend(options.timeWeightedReturnLabel) : t('assetsOverview.timeWeightedRoRAbbreviation'),
                                stat: formatPercentValue(statistics?.timeWeightedReturn ?? 0),
                                show: showTimeWeightedReturn,
                                testId: 'market-value-chart-time-weighted-rate-of-return',
                              },
                              {
                                title: options.moneyWeightedReturnLabel ? translateBackend(options.moneyWeightedReturnLabel) : t('assetsOverview.moneyWeightedRoRAbbreviation'),
                                stat: formatPercentValue(statistics?.moneyWeightedReturn ?? 0),
                                show: showMoneyWeightedReturn,
                                testId: 'market-value-chart-money-weighted-rate-of-return',
                              },
                            ]} />
                          </Box>
                      }
                      {showNetContributionOnHover
                        && <Box display="flex" alignItems="center">
                          <img src={MarketValueIndicatorIcon} height={`${netContributionIndicatorImageSize}px`} width={`${netContributionIndicatorImageSize}px`} alt="market-value-indicator" />
                          <Typography variant='bodyLarge' ml={sys.spacing.md}>
                            {`${t('components:marketValueChart.netContributions')} ${getNetContributionWithCurrency(activeNetContributionCents ?? 0, activeCurrency as CurrencyCodes)}`}
                          </Typography>
                        </Box>
                      }
                    </Box>
                  </>
                )}
              </Grid>
              <Grid item mt={3} xs={12} sm={7}>
                {options.displayMarketValueHistory && <Grid container justifyContent={{ xs: 'center', sm: 'end' }} spacing={1}>
                  <Grid item display={{ xs: 'none', sm: 'block' }}>
                    {timeFrame !== 'custom' ? (
                      <Box
                        data-testid='market-value-chart-date-range-custom'
                        onClick={(event: any) => { setCustomDateRangePopover(event.currentTarget); }}
                        sx={{
                          height: comp.lineChart?.timeFrameButtonHeight ?? '22px',
                          borderRadius: sys.borderRadius.sm,
                          display: 'inline-flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                          color: sys.color.onSurface,
                          backgroundColor: sys.color.surface,
                          '&:hover': { backgroundColor: `${sys.color.outlineVariant} !important` },
                        }}
                      >
                        <Typography
                          margin='0.2em 1em'
                        >
                          {t('assetsOverview.customDateRange')}
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        data-testid='market-value-chart-date-range-custom'
                        onClick={(event: any) => { setCustomDateRangePopover(event.currentTarget); }}
                        sx={{
                          height: comp.lineChart?.timeFrameButtonHeight ?? '22px',
                          borderRadius: sys.borderRadius.sm,
                          display: 'inline-flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                          color: sys.color.surface,
                          backgroundColor: sys.color.onSurface,
                        }}
                      >
                        <Typography
                          margin='0.2em 1em'
                          sx={{ color: sys.color.surface }}
                        >
                          <>
                            {localizedDate(customStartDate)} - {localizedDate(customEndDate)}
                          </>
                        </Typography>
                      </Box>
                    )}
                  </Grid>

                  {(['1M', '3M', '1Y', 'YTD', 'MAX'] as HistoricalTimeFrames[]).map((tf) => (
                    <Grid item key={tf}>
                      <Box
                        data-testid={`market-value-chart-date-range-${tf}`}
                        onClick={() => {
                          setTimeFrame(tf);
                          setCustomStartDate('');
                          setCustomEndDate('');
                        }}
                        sx={{
                          height: comp.lineChart?.timeFrameButtonHeight ?? '22px',
                          borderRadius: sys.borderRadius.sm,
                          display: 'inline-flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                          color: timeFrame === tf ? sys.color.surface : sys.color.onSurface,
                          backgroundColor: timeFrame === tf ? sys.color.onSurface : sys.color.surface,
                          '&:hover': { backgroundColor: timeFrame === tf ? sys.color.onSurface : `${sys.color.outlineVariant} !important` },
                        }}
                      >
                        <Typography
                          sx={{
                            margin: '0.2em 1em',
                            color: timeFrame === tf ? sys.color.surface : sys.color.onSurface,
                          }}
                          key={tf}
                        >
                          {t(`assetsOverview.${tf}`)}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>}
              </Grid>
            </Grid>
          </CardContent>
          {options.displayMarketValueHistory && <Box sx={{
            height: { xs: getChartHeight('xs'), sm: getChartHeight('sm') },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
            {historyLoading ? (<Box height='100%' display='flex' alignItems='center'><CircularProgress /></Box>) : (
              <Box sx={{ width: '100%', height: '100%' }}>
                {(history ?? []).length < 2 || hideGraph ? (
                  <Box sx={{
                    width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
                  }}>
                    <img
                      alt='empty-chart'
                      src={EmptyChart}
                      style={{ maxWidth: '300px' }}
                    />
                    <Typography variant='headingLarge' sx={{ mt: 2 }}>
                      {t('assetsOverview.emptyChart')}
                    </Typography>
                  </Box>
                ) : (
                  <LineChart
                    labels={labels}
                    plugins={[tooltipLine]}
                    overrideOptions={overrideLineOptions}
                    overrideData={{
                      labels,
                      datasets: ([
                        { label: t('marketValue'), data: history.filter((x: any) => x.marketValueCents !== 0).map((x: any) => x.marketValueCents) },
                        ...options.displayNetContributions ? [
                          { label: t('netContribution'), data: getNetContributionsCentsArray(history.filter((x: any) => x.marketValueCents !== 0).map((x: any) => x.marketValueCents)) },
                        ] : [],
                      ]).map((x: any, index: number) => ({
                        fill: x?.label !== 'netContribution',
                        label: x?.label,
                        data: x?.data,
                        borderColor: x?.label === 'netContribution' ? (options.displayNetContributions && mouseIsOverChart ? comp.lineChart.secondaryLine : '#00000000') : comp.lineChart.color,
                        borderDash: x?.label === 'netContribution' ? [8, 4] : [],
                        borderWidth: x?.label === 'netContribution' ? 1.5 : undefined,
                        backgroundColor: (context: ScriptableContext<'line'>) => {
                          const { ctx } = context.chart;
                          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                          gradient.addColorStop(0, `rgba(${rgb[0]},${rgb[1]},${rgb[2]},0.3)`);
                          gradient.addColorStop(1, `rgba(${rgb[0]},${rgb[1]},${rgb[2]},0)`);
                          return gradient;
                        },
                        tension: x?.label === 'netContribution' ? 0 : comp.lineChart.tension,
                      })),
                    }}
                    mouseMove={() => {
                      setMouseIsOverChart(true);
                    }}
                    mouseOut={() => {
                      setMouseIsOverChart(false);
                    }}
                  />
                )}
              </Box>
            )}
            <Popover
              id={id}
              data-testid='market-value-chart-custom-date-range-popover'
              open={!!customDateRangePopover}
              anchorEl={customDateRangePopover}
              onClose={() => setCustomDateRangePopover(null)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <SimpleDateRangePicker
                date1={customStartDate ? dayjs(customStartDate) : null}
                date2={customEndDate ? dayjs(customEndDate) : null}
                setDates={(date1: any, date2: any) => {
                  setCustomStartDate(date1.format('YYYY-MM-DD'));
                  setCustomEndDate(date2.format('YYYY-MM-DD'));
                  setTimeFrame('custom');
                  setCustomDateRangePopover(null);
                }}
              />
            </Popover>
          </Box>}
        </Card>
      </Grid>
      {hasSideBar() && (
        <Grid item xs={12} md={4}>
          {sideBar()}
        </Grid>
      )}
    </Grid>
  );
};

export default MarketValueChart;
