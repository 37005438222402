import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CurrencyCodes } from '@onevesthq/ov-enums';
import AutoRenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import { useContext, useEffect, useState } from 'react';
import { useHouseholdContext } from '../../../pages/household';
import { MemberTag } from '../../../pages/household/components/memberTag';
import { useClientContext } from '../../../pages/client';
import { Typography, Box } from '../../1-primative';
import { Card, Tooltip, CardContent } from '../../2-component';
import { formatMoneyValue, formatPercentValue } from '../../../util';
import { Account, AccountStates, Holding } from '../../../interfaces';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { ovAnalyticsEvents } from '../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../providers/analyticsProvider';
import MultiCurrency from '../../../assets/images/custom/multiCurrency.svg';
import { currencySymbol, getCurrencyFlag } from '../../../util/currency';
import { UserContext } from '../../../providers/userContextProvider';
import { PageObjectType } from '../../5-page';

type Tag = {
  text: string,
  sequenceNumber: number | 'HOUSEHOLD'
};

const AccountItem = ({
  account, tag, enableTooltip = true, displayCurrency, useCustodianData, objectType,
}:
{ account: Account, tag?: Tag, objectId: string, enableTooltip?: boolean, displayCurrency?: boolean, useCustodianData?: boolean, objectType: PageObjectType, }) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { activeCurrency } = useContext(UserContext);
  const { t } = useTranslation(['client', 'accountTypes', 'accountsDetail']);
  const { sys } = useThemeTokens();
  const navigate = useNavigate();
  const clientContext = useClientContext();
  const householdContext = useHouseholdContext();
  const [groupedMarketValue, setGroupedMarketValue] = useState<{ currency?: CurrencyCodes, marketValueCents?: number }[]>([]);
  const statistics = useCustodianData ? account.custodianStatistics : account.statistics;
  // The simpleReturnAmount is actually coming in cents.
  // TODO: update history-service to return simpleReturnAmountCents and deprecate simpleReturnAmount
  const simpleReturnAmountCents = useCustodianData ? statistics?.simpleReturnAmountCents : statistics?.simpleReturnAmount;

  const getPercentageOfTotalAsset = (): number => {
    const totalMarketValueCents: number = objectType === PageObjectType.HOUSEHOLD ? householdContext?.totalMarketValueCents ?? 0 : clientContext?.totalMarketValueCents || 0;
    const percentage = (statistics?.marketValueCents ?? 0) / totalMarketValueCents;
    return Number.isNaN(percentage) ? 0 : percentage;
  };

  const subAccounts = account.subAccounts?.filter((x: any) => x.state !== 'INACTIVE');
  const awaitingSubAccountCount: number = account.subAccounts?.filter((x) => x.state === 'AWAITING_APPROVAL').length ?? 0;

  useEffect(() => {
    if (statistics?.holdings && !useCustodianData) {
      const marketValueByCurrency = statistics.holdings.reduce((acc: { currency: CurrencyCodes, marketValueCents: number }[], holding: Holding) => {
        if (holding.originalCurrency && holding.originalCurrency.currency !== activeCurrency) {
          const { originalCurrency } = holding;
          const currencyHolding = acc.find((elem) => elem.currency === originalCurrency.currency);
          if (currencyHolding) {
            currencyHolding.marketValueCents += originalCurrency.totalCents ?? 0;
          } else {
            acc.push({
              currency: originalCurrency.currency ?? CurrencyCodes.CAD,
              marketValueCents: originalCurrency.totalCents ?? 0,
            });
          }
        }
        return acc;
      }, []);
      marketValueByCurrency.sort((a, b) => (a.currency > b.currency ? 1 : (a.currency < b.currency ? -1 : 0)));
      setGroupedMarketValue(marketValueByCurrency);
    } else if (statistics?.originalCurrencies && useCustodianData) {
      const marketValueByCurrency = statistics.originalCurrencies.filter((elem) => elem.currency !== activeCurrency);
      marketValueByCurrency.sort((a: any, b: any) => (a.currency > b.currency ? 1 : (a.currency < b.currency ? -1 : 0)));
      setGroupedMarketValue(marketValueByCurrency);
    }
  }, [statistics, activeCurrency, useCustodianData]);
  return (
    <Card
      hover
      onClick={() => {
        sendAnalytic(ovAnalyticsEvents.homepageAccountSelect);
        navigate(`account/${account.id}`);
      }}
      sx={{
        cursor: 'pointer',
        backgroundColor: account.state === AccountStates.INACTIVE ? sys.color.disabled : sys.color.surface,
      }}
    >
      <CardContent data-testid={`account-${account?.type}`}>
        <Box display="flex" justifyContent="space-between" sx={{
          position: 'relative', top: '-4px', right: '-4px', height: '15px',
        }}>
          <Box display="flex" flexDirection="row">
          </Box>
          <Box display="flex" flexDirection="row" >
            { awaitingSubAccountCount > 0 && enableTooltip && (
              <Tooltip title={t('goalsSummary.subAccountNeedsApproval', { count: awaitingSubAccountCount })}>
                <Box
                  sx={{
                    height: '8px',
                    width: '8px',
                    borderRadius: '50%',
                    backgroundColor: sys.color.negative,
                  }}
                />
              </Tooltip>
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" marginTop="-15px" marginBottom="15px">
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant='titleMedium'>{t(`accountTypes:${account.type}`)}</Typography>
              <Typography variant='bodySmall' ml={0.5} colorVariant='variant'>{
                account.custodianAccountNumber
                  ? `#${account.custodianAccountNumber}`
                  : (
                    <>
                      <AutoRenewRoundedIcon sx={{ fontSize: '14px', verticalAlign: 'middle' }} />
                      { t('pending') }
                    </>
                  )
                }</Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" mt={0.5}>
              { tag ? <MemberTag sequenceNumber={tag.sequenceNumber} name={tag.text} /> : (<Box height='16.5px' width={1} />) }
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="end">
            <Box display='flex' gap={1} alignItems='center'>
              {displayCurrency && account?.baseCurrency && (
                <Box
                sx={{
                  height: 20,
                  width: 20,
                  borderRadius: 10,
                  overflow: 'hidden',
                }}
              >
                <img alt={account.baseCurrency} src={getCurrencyFlag(account.baseCurrency as CurrencyCodes)} style={{ objectFit: 'cover', height: '100%' }}/>
              </Box>
              )}
              {(groupedMarketValue.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {groupedMarketValue.map((elem) => (
                        <Typography key={elem.currency} variant='trimmedSmall' color={sys.color.onPrimary}>
                          {`${elem.currency} ${formatMoneyValue(elem.marketValueCents, '', 2, false)}`}
                        </Typography>
                      ))}
                    </>
                  }
                  placement='top'
                >
                  <Box sx={{ width: '20px', height: '20px' }}>
                    <img src={MultiCurrency} alt='multi-currency' style={{ width: '20px', height: '20px' }} />
                  </Box>
                </Tooltip>
              ))}
              <Typography variant='titleMedium'>
                {formatMoneyValue(statistics?.marketValueCents ?? 0, currencySymbol[statistics?.currency ?? CurrencyCodes.CAD])}
              </Typography>
            </Box>
            <Typography
              variant='bodySmall'
              color={account.state === AccountStates.INACTIVE ? sys.color.onDisabled : (simpleReturnAmountCents || 0) >= 0 ? sys.color.positive : sys.color.negative}
            >
              {`${formatMoneyValue(simpleReturnAmountCents ?? 0, currencySymbol[statistics?.currency ?? CurrencyCodes.CAD])} (${formatPercentValue(statistics?.simpleReturnPercent ?? 0)})`}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box display="flex" justifyContent="space-between" marginTop={householdContext?.orgSettings ? '48px' : '26px'}>
            <Typography variant='bodySmall' sx={{ color: account.state === AccountStates.INACTIVE ? sys.color.onDisabled : sys.color.onSurfaceVariant }}>
              {t('accountsSummary.subAccountsLinked', { count: subAccounts?.length ?? 0 })}
            </Typography>
            <Typography variant='bodySmall' sx={{ color: account.state === AccountStates.INACTIVE ? sys.color.onDisabled : sys.color.onSurfaceVariant }}>
              {t('accountsSummary.ofTotalAssets', { percentage: formatPercentValue(getPercentageOfTotalAsset(), 0) })}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AccountItem;
