import { gql } from '@apollo/client';

export const FETCH_SUB_ACCOUNT = gql`
  query fetchSubAccount($subAccountId: ObjectID!) {
    fetchSubAccount(subAccountId: $subAccountId) {
      subAccount {
        id
        theme {
          id
          translatedName {
            en
            fr
          }
          translatedDescription { en fr }
        }
        suggestedFinancialProduct {
          id
          translatedName {
            en
            fr
          }
        }
        financialProduct {
          id
          translatedName {
            en
            fr
          }
          url
        }
      }
    }
  }
`;

export const UPDATE_GOAL = gql`
  mutation updateGoal($input: UpdateGoalInput!) {
    updateGoal(input: $input) {
      goal {
        id
        suggestedFinancialProduct {
          id
        }
      }
    }
  }
`;

export const FETCH_GOAL = gql`
  query fetchGoal($goalId: ObjectID!) {
    fetchGoal(goalId: $goalId) {
      goal {
        id
        
        suggestedFinancialProduct {
          id
          translatedName {
            en
            fr
          }
        }
        financialProduct {
          id
          translatedName {
            en
            fr
          }
          theme {
            id
            translatedName {
              en
              fr
            }
          }
          url
        }
      }
    }
  }
`;

export const UPDATE_SUB_ACCOUNT = gql`
  mutation updateSubAccount($input: UpdateSubAccountInput!) {
    updateSubAccount(input: $input) {
      subAccount {
        id
        suggestedFinancialProduct {
          id
        }
      }
    }
  }
`;

export const FETCH_MODEL_PORTFOLIOS = gql`
  query fetchModelPortfolios($input: FetchModelPortfoliosInput!) {
    fetchModelPortfolios(input: $input) {
      modelPortfolios {
        id
        translatedName {
          en
          fr
        }
        theme { name }
      }
    }
  }
`;
