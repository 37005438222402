import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../1-primative';
import { SelectField, MenuItem } from '../../2-component';
import { translateBackend } from '../../../assets/i18n/config';

export const FETCH_ACCOUNT_TYPES = gql`
  query fetchAccountTypes {
    fetchAccountTypes{
      accountTypes {
        value
        translatedName{
          en
        }
      }
    }
  }
`;

export const FETCH_ACCOUNT_TYPES_WITH_FILTER = gql`
  query fetchAccountTypes($input: FetchAccountTypesInput!) {
    fetchAccountTypes(input: $input){
      accountTypes {
        value
        translatedName{
          en
        }
      }
    }
  }
`;

export const AccountTypeSelect = ({
  onChange,
  value, label, size = 'small',
  omitAny = false,
  sortAlphebeticaly = false,
  onBlur,
  error,
  entityId,
  householdId,
}:{
  onChange: (event: any) => void, value?: string, label?: string, size?: 'small' | 'medium',
  omitAny?: boolean,
  sortAlphebeticaly?: boolean,
  onBlur?: any,
  error?: any,
  entityId?: string,
  householdId?: string,
}) => {
  const { t } = useTranslation(['client', 'components']);
  const {
    loading,
    error: er,
    data,
  } = useQuery(entityId || householdId ? FETCH_ACCOUNT_TYPES_WITH_FILTER : FETCH_ACCOUNT_TYPES, {
    variables: {
      input: {
        filter: {
          entityId: entityId ?? undefined,
          householdId: householdId ?? undefined,
        },
      },
    },
    fetchPolicy: 'no-cache',
  });

  if (er) (<Typography>Error</Typography>);
  let accountTypesData: any[] = [];
  if (data) {
    accountTypesData = accountTypesData.concat(data.fetchAccountTypes.accountTypes);
    if (sortAlphebeticaly && accountTypesData !== undefined && accountTypesData.length > 0) {
      accountTypesData = accountTypesData.sort((a: any, b: any) => (translateBackend(a.translatedName).localeCompare(translateBackend(b.translatedName))));
    }
  }
  return (
    <>
      <SelectField
        clearable
        testId='account-type-select'
        label={label ?? t('client:accountType')}
        fullWidth
        value={value ?? 'ANY'}
        onChange={(e: any) => onChange(e.target.value)}
        size={size}
        onBlur={onBlur}
        error={error}
      >
        {
          loading ? <MenuItem>...</MenuItem> : (
            accountTypesData.map((a: any) => (
              <MenuItem key={a.value} value={a.value}>
                {a.translatedName.en || a.value}
              </MenuItem>
            ))

          )
        }
        {!omitAny && (<MenuItem value='ANY'>{t('components:any')}</MenuItem>)}
      </SelectField>
    </>
  );
};
