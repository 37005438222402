import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { SupervisedUserCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { useContext } from 'react';
import remarkGfm from 'remark-gfm';
import {
  Box, Grid, Skeleton, Typography,
} from '../../../1-primative';
import { InitiateTransfer } from '../initiateTransfer';
import { entityName, entityNameWithGreeting } from '../../../../util';
import {
  FETCH_USER, FETCH_GOAL, FETCH_ACCOUNT, FETCH_SUB_ACCOUNT, FETCH_HOUSEHOLD,
} from './clientName.queries';
import { PageObjectType } from '../../../5-page';
import { OrganizationUserAccessTypes, getSubAccountName } from '../../../../interfaces';
import { Button } from '../../../2-component';
import { EditButton } from '../editButton/editButton';
import './clientName.css';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';
import { UserContext } from '../../../../providers/userContextProvider';
import filterHouseholdsWithAccess from '../../../../util/filterHouseholdsWithAccess';
import { translateBackend } from '../../../../assets/i18n/config';

export const ClientName = ({
  objectId, objectType, options = {}, setActiveWorkflowCompletionId,
}: {
  objectId: string, objectType: PageObjectType, options?: any, setActiveWorkflowCompletionId?: (id: string) => void,
}) => {
  const { t } = useTranslation(['accountTypes', 'client']);
  const navigate = useNavigate();
  const { sys } = useThemeTokens();
  const { userContext, setActiveHousehold } = useContext(UserContext);

  const { loading, data } = useQuery(FETCH_USER, {
    variables: {
      userId: objectId,
    },
    fetchPolicy: 'no-cache',
    skip: ![PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType),
  });

  const { loading: goalLoading, data: goalData } = useQuery(FETCH_GOAL, {
    variables: {
      goalId: objectId,
    },
    fetchPolicy: 'no-cache',
    skip: ![PageObjectType.GOAL].includes(objectType),
  });

  const { loading: accountLoading, data: accountData } = useQuery(FETCH_ACCOUNT, {
    variables: {
      accountId: objectId,
    },
    fetchPolicy: 'no-cache',
    skip: ![PageObjectType.ACCOUNT].includes(objectType),
  });

  const { loading: subAccountLoading, data: subAccountData } = useQuery(FETCH_SUB_ACCOUNT, {
    variables: {
      subAccountId: objectId,
    },
    fetchPolicy: 'no-cache',
    skip: ![PageObjectType.SUB_ACCOUNT].includes(objectType),
  });

  const { loading: householdLoading, data: householdData } = useQuery(FETCH_HOUSEHOLD, {
    variables: {
      clientGroupId: objectId,
    },
    fetchPolicy: 'no-cache',
    skip: ![PageObjectType.HOUSEHOLD].includes(objectType),
  });

  const name = () => {
    switch (objectType) {
      case PageObjectType.INDIVIDUAL:
        return options.greeting ? entityNameWithGreeting(t('client:welcome'), data?.fetchUser?.user?.firstName) : entityName(data.fetchUser.user, true);
      case PageObjectType.NON_INDIVIDUAL:
        return entityName(data.fetchUser.user, true);
      case PageObjectType.GOAL:
        return goalData.fetchGoal.goal.name;
      case PageObjectType.ACCOUNT:
        return translateBackend(accountData.fetchAccount.account.translatedNickName) || accountData.fetchAccount.account.nickName || t(`accountTypes:${accountData.fetchAccount.account.type}`);
      case PageObjectType.SUB_ACCOUNT:
        return getSubAccountName(subAccountData.fetchSubAccount.subAccount);
      case PageObjectType.HOUSEHOLD:
        return householdData.fetchClientGroup.clientGroup.name;
      default:
        return '';
    }
  };

  const households = userContext.accessType === OrganizationUserAccessTypes.ORGANIZATION
    ? (data?.fetchUser?.user?.households ?? [])
    : filterHouseholdsWithAccess((data?.fetchUser?.user?.households ?? []), objectId);

  if (loading || goalLoading || accountLoading || subAccountLoading || householdLoading) {
    return (
      <Grid container mt={{ xs: 0, md: 4 }}>
        <Grid item xs={12} md={8}>
          {[PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType) && (
            <Skeleton width='110px' height='24px' sx={{ mb: 0.5 }}></Skeleton>
          )}
          <Skeleton width='300px' height='48px'></Skeleton>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display='flex' sx={{ justifyContent: { xs: 'start', md: 'flex-end' }, mt: { xs: 2, md: 0 } }}>
            <Skeleton width='110px' height='48px' sx={{ mr: 1 }}></Skeleton>
            <Skeleton width='110px' height='48px'></Skeleton>
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container mt={{ xs: 0, md: 4 }}>
      <Grid item xs={12} md={options.enableContent && (options.content || options.translatedContent) ? 6 : 12}>
        {
          (((households.length > 0 && userContext.accessType === OrganizationUserAccessTypes.ORGANIZATION)
            || (households.length > 0 && !userContext.role?.makeHouseholdDefaultView)
              || (households.length > 1 && !!userContext.role?.makeHouseholdDefaultView))) ? (
            <Grid item xs={12} mb={0.5}>
              {households.map((household: any) => (
                <Button
                  key={household.id}
                  variant='text'
                  size='xs'
                  label={household.name}
                  leadingIcon={SupervisedUserCircle}
                  sx={{ mr: 1 }}
                  onClick={() => {
                    if (userContext.accessType === OrganizationUserAccessTypes.ORGANIZATION) {
                      navigate(`/households/${household.id}`);
                    } else {
                      // eslint-disable-next-line no-lonely-if
                      if (userContext.role?.makeHouseholdDefaultView) {
                        navigate(`households/${household.id}`);
                        setActiveHousehold(household);
                      } else {
                        navigate(`household/${household.id}`);
                      }
                    }
                  }}
                />
              ))}
            </Grid>
            ) : (
              [PageObjectType.INDIVIDUAL, PageObjectType.NON_INDIVIDUAL].includes(objectType) && (
                <Box height='20px' width='100%' />
              )
            )
        }
      </Grid>
      {options.enableContent && (options.content || options.translatedContent) && (
        <Grid item xs={12} md={6}>
          <Box display='flex' sx={{
            justifyContent: { xs: 'start', md: 'flex-end' }, height: '100%', textAlign: 'justify', alignItems: 'start', mt: { xs: 1, md: 0 }, mb: { xs: 1, md: 0 },
          }}>
            <Typography variant='bodySmall' sx={{ color: sys.color.onSurfaceVariant }} component={'div'}>
              <ReactMarkdown className='markdown' remarkPlugins={[remarkGfm]}>{options.translatedContent?.en ? translateBackend(options.translatedContent) : options.content}</ReactMarkdown>
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid item xs={12} md={8}>
        <Typography variant='displayLargeVariant'>{name()}</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box display='flex' sx={{
          justifyContent: { xs: 'start', md: 'flex-end' }, height: '100%', alignItems: 'end', mt: { xs: 1, md: 0 },
        }}>
          <InitiateTransfer objectId={objectId} objectType={objectType} options={options} />
          {!options.hideEdit && (
            <EditButton objectId={objectId} options={options} objectType={objectType} setActiveWorkflowCompletionId={setActiveWorkflowCompletionId} />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
