import {
  useEffect, useContext, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { translateBackend } from 'assets/i18n/config';
import CustomFieldRenderer from 'ovComponents/3-pattern/customField/customFieldRenderer';
import InfoDialog from 'ovComponents/2-component/infoDialog/infoDialog';
import { additionalInfo } from 'ovComponents/4-module/configurableOptionFields';
import { Alert } from '@mui/material';
import { invalidFields } from '../utils';
import {
  Button, TextField, Typography, DateField, AddressField,
  RadioGroup, Radio, Form, Box, Grid,
} from '../../../..';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';
import { EnabledJurisdictions } from '../../../../../interfaces';

export const PersonalInformationVisual = ({
  options, userData, loading, updateUser, continueFunc, grid = false, updateMode = false, activeCustomFields, applicableJurisdictions,
  workflowCompletion,
}: {
  options: any, userData: any, loading: boolean, updateUser: any, continueFunc: any, grid?: boolean, updateMode?: boolean,
  activeCustomFields?: string[], applicableJurisdictions?: EnabledJurisdictions, workflowCompletion?: any,
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { t } = useTranslation('client');
  const [focused, setFocused] = useState<string[]>([]);
  const [invalidFieldsList, setInvalidFieldsList] = useState<string[]>([]);
  const [updated, setUpdated] = useState<boolean>(false);
  const [customOptionSelected, setCustomOptionSelected] = useState<any[]>([]);
  const submit = () => {
    const fields = invalidFields({
      ...options,
      inProvinceSince: {
        ...options.inProvinceSince,
        requiredIf: (data: any) => data?.physicalAddress?.province === 'QC',
      },
    }, userData);
    if (physicalAddressInvalid) fields.push('physicalAddress');
    setFocused(fields);
    setInvalidFieldsList(fields);
    if (fields.length === 0) {
      sendAnalytic(
        ovAnalyticsEvents.workflowsPersonalInformationContinueButtonSelect,
        {
          workflowStepTitle: options?.title,
          workflowId: workflowCompletion?.workflow?.id,
          workflowName: workflowCompletion?.workflow?.name,
          activeWorkflowCompletionId: workflowCompletion?.id,
          objectId: workflowCompletion?.objectId,
          objectType: workflowCompletion?.objectType,
        },
      );
      continueFunc();
    }
  };

  const update = (newValue: any) => {
    setUpdated(true);
    updateUser(newValue);
  };

  useEffect(() => {
    const customOptions: any[] = [];
    Object.keys(options).forEach((key: string) => {
      if (options[key]?.customField && options[key].enabled && activeCustomFields?.includes(key)) {
        customOptions.push(options[key]);
      }
    });
    setCustomOptionSelected(customOptions);
  }, [options, activeCustomFields]);

  const error = (key: string): boolean => (
    ((!userData?.[key] && focused.includes(key)) || invalidFieldsList.includes(key)) && options?.[key]?.required !== 'NOT_REQUIRED'
  );
  const subTitleStyle = {
    display: 'inline-flex',
    alignItems: 'end',
  };

  const physicalAddressOutOfJurisdiction: boolean = useMemo(() => {
    if (!options?.physicalAddress?.enabled) return false;
    if (!userData) return false;
    if (!userData.physicalAddress?.jurisdiction) return false;

    if (!applicableJurisdictions) return false;

    if (applicableJurisdictions.all === true) return false;
    if (!applicableJurisdictions.only || applicableJurisdictions.only.length === 0) return false;

    return !applicableJurisdictions.only.includes(userData.physicalAddress?.jurisdiction);
  }, [options.physicalAddress, userData, applicableJurisdictions]);

  const physicalAddressInvalid = physicalAddressOutOfJurisdiction && options?.physicalAddress?.required === 'ERROR';

  useEffect(() => {
    setInvalidFieldsList((prev) => {
      const without = prev.filter((key) => key !== 'physicalAddress');
      if (physicalAddressOutOfJurisdiction) without.push('physicalAddress');
      return without;
    });
  }, [physicalAddressOutOfJurisdiction]);

  const lockMessage = () => (options.customLockedMessage ? translateBackend(options.customLockedMessage) : t('pageConfiguration:notEditableMessage'));

  return (
    <Form onSubmit={submit}>
      {options?.title && (
        <Typography variant='displayLarge' sx={{ mt: 1 }}>
          {translateBackend(options?.title)}
          {additionalInfo(options?.title?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.title?.additionalInfo) ?? ''} />)}
        </Typography>
      )}
      {options?.subtitle && (
        <Typography style={subTitleStyle} variant='bodyLarge' component={'div'} sx={{ mb: 3, table: { width: '100%' } }}>
          <Box display='inline-block'>
            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
          </Box>
          {additionalInfo(options?.subtitle?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.subtitle?.additionalInfo) ?? ''} />)}
        </Typography>
      )}
      <Grid container spacing={2}>
        {options?.firstName?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              data-testid='legal-first-name'
              onChange={(e: any) => update({ ...userData, firstName: e.target.value })}
              disabled={loading || options?.firstName?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.firstName?.label)}
              fullWidth
              value={userData.firstName ?? ''}
              onBlur={() => setFocused([...focused, 'firstName'])}
              error={error('firstName')}
              locked={options?.firstName?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              infoTooltip={additionalInfo(options?.firstName?.additionalInfo)}
            />
          </Grid>
        )}
        {options?.middleName?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              data-testid='legal-middle-name'
              onChange={(e: any) => update({ ...userData, middleName: e.target.value })}
              disabled={loading || options?.middleName?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.middleName?.label)}
              fullWidth
              value={userData.middleName ?? ''}
              onBlur={() => setFocused([...focused, 'middleName'])}
              error={error('middleName')}
              locked={options?.middleName?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              infoTooltip={additionalInfo(options?.middleName?.additionalInfo)}
            />
          </Grid>
        )}
        {options?.lastName?.enabled && (
          <Grid item xs={12} md={grid ? 4 : 12}>
            <TextField
              data-testid='legal-last-name'
              onChange={(e: any) => update({ ...userData, lastName: e.target.value })}
              disabled={loading || options?.lastName?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.lastName?.label)}
              fullWidth
              value={userData.lastName ?? ''}
              onBlur={() => setFocused([...focused, 'lastName'])}
              error={error('lastName')}
              locked={options?.lastName?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              infoTooltip={additionalInfo(options?.lastName?.additionalInfo)}
            />
          </Grid>
        )}
        {options?.physicalAddress?.enabled && (
          <Grid item xs={12}>
            <AddressField
              address={userData.physicalAddress}
              onChange={(e: any) => update({ ...userData, physicalAddress: e })}
              disabled={loading || options?.physicalAddress?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.physicalAddress?.label)}
              fullWidth
              lockMessage={lockMessage()}
              defaultAddressProvider={options?.physicalAddress?.defaultAddressProvider}
              manualAddressEntry={options?.physicalAddress?.manualAddressEntry}
              infoTooltip={additionalInfo(options?.physicalAddress?.additionalInfo)}
              onFocus={() => setFocused([...focused, 'physicalAddress'])}
              error={error('physicalAddress')}
              data-testid='physical-address'
            />
            {physicalAddressOutOfJurisdiction && (
              <Alert
                severity={options?.physicalAddress?.required === 'ERROR' ? 'error' : 'info'}
                sx={{ my: 2 }}>
                {
                  translateBackend(options?.physicalAddress.addressOutOfJurisdictionMessage)
                    .replace(
                      '{{ALLOWED_JURISDICTIONS}}',
                      (applicableJurisdictions?.only ?? [])?.map((j) => j.replace(/^(CA|US)_/, '')).join(', '),
                    )
                }
              </Alert>
            )}

          </Grid>
        )}
        {options?.inProvinceSince?.enabled && userData.physicalAddress?.province === 'QC' && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <DateField
              data-testid='in-province-since'
              onChange={(date: any) => update({ ...userData, inProvinceSince: dayjs(date?.toString()).format('YYYY-MM-DD') })}
              disabled={loading || options?.inProvinceSince?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.inProvinceSince?.label)}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.inProvinceSince}
              onBlur={() => setFocused([...focused, 'inProvinceSince'])}
              infoTooltip={additionalInfo(options?.inProvinceSince?.additionalInfo)}
              error={error('inProvinceSince')}
            />
          </Grid>
        )}
        {options?.dateOfBirth?.enabled && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <DateField
              data-testid='date-of-birth'
              onChange={(date: any) => update({ ...userData, dateOfBirth: dayjs(date?.toString()).format('YYYY-MM-DD') })}
              disabled={loading || options?.dateOfBirth?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.dateOfBirth?.label)}
              lockMessage={lockMessage()}
              fullWidth
              value={userData.dateOfBirth}
              onBlur={() => setFocused([...focused, 'dateOfBirth'])}
              infoTooltip={additionalInfo(options?.dateOfBirth?.additionalInfo)}
              error={error('dateOfBirth')}
            />
          </Grid>
        )}
        {options?.primaryEmail?.enabled && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <TextField
              data-testid='primary-email'
              onChange={(e: any) => update({ ...userData, primaryEmail: e.target.value })}
              disabled={loading || options?.primaryEmail?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.primaryEmail?.label)}
              fullWidth
              value={userData.primaryEmail ?? ''}
              onBlur={() => setFocused([...focused, 'primaryEmail'])}
              error={error('primaryEmail')}
              locked={options?.primaryEmail?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              infoTooltip={additionalInfo(options?.primaryEmail?.additionalInfo)}
            />
          </Grid>
        )}
        {options?.phone?.enabled && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <TextField
              data-testid='phone'
              onChange={(e: any) => update({ ...userData, phone: e.target.value })}
              disabled={loading || options?.phone?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.phone?.label)}
              fullWidth
              value={userData.phone ?? ''}
              onBlur={() => setFocused([...focused, 'phone'])}
              error={error('phone')}
              locked={options?.phone?.required === 'NOT_EDITABLE'}
              lockMessage={lockMessage()}
              infoTooltip={additionalInfo(options?.phone?.additionalInfo)}
            />
          </Grid>
        )}
        {options?.preferredMethodOfCommunication?.enabled && (
          <Grid item xs={12}>
            <RadioGroup
              data-testid='preferred-method-of-communication'
              onChange={(e: any) => {
                update({ ...userData, preferredMethodOfCommunication: e.target.value });
                setFocused([...focused, 'preferredMethodOfCommunication']);
              }}
              disabled={loading || options?.preferredMethodOfCommunication?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.preferredMethodOfCommunication?.label)}
              infoTooltip={additionalInfo(options?.preferredMethodOfCommunication?.additionalInfo)}
              fullWidth
              value={userData.preferredMethodOfCommunication}
              onFocus={() => setFocused([...focused, 'preferredMethodOfCommunication'])}
              error={error('preferredMethodOfCommunication')}
            >
              <Radio
                data-testid='preferred-method-of-communication-email'
                value='EMAIL'
                disabled={loading || options?.preferredMethodOfCommunication?.required === 'NOT_EDITABLE'}
                label={t('edit.preferredMethodOfCommunicationOptions.EMAIL')}
              />
              <Radio
                data-testid='preferred-method-of-communication-phone'
                value='PHONE'
                disabled={loading || options?.preferredMethodOfCommunication?.required === 'NOT_EDITABLE'}
                label={t('edit.preferredMethodOfCommunicationOptions.PHONE')}
              />
              <Radio
                data-testid='preferred-method-of-communication-text'
                value='TEXT'
                disabled={loading || options?.preferredMethodOfCommunication?.required === 'NOT_EDITABLE'}
                label={t('edit.preferredMethodOfCommunicationOptions.TEXT')}
              />
            </RadioGroup>
          </Grid>
        )}
        {options?.gender?.enabled && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <RadioGroup
              data-testid='gender-select'
              onChange={(e: any) => {
                update({ ...userData, gender: e.target.value });
                setFocused([...focused, 'gender']);
              }}
              disabled={loading || options?.gender?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.gender?.label)}
              infoTooltip={additionalInfo(options?.gender?.additionalInfo)}
              fullWidth
              value={userData.gender}
              error={error('gender')}
            >
              <Radio data-testid='gender-select-male' value='MALE' disabled={loading || options?.gender?.required === 'NOT_EDITABLE'} label={t('edit.genderOptions.MALE')} />
              <Radio data-testid='gender-select-female' value='FEMALE' disabled={loading || options?.gender?.required === 'NOT_EDITABLE'} label={t('edit.genderOptions.FEMALE')} />
            </RadioGroup>
          </Grid>
        )}
        {options?.language?.enabled && (
          <Grid item xs={12} md={grid ? 6 : 12}>
            <RadioGroup
              data-testid='language-select'
              onChange={(e: any) => {
                update({ ...userData, language: e.target.value });
                setFocused([...focused, 'language']);
              }}
              disabled={loading || options?.language?.required === 'NOT_EDITABLE'}
              label={translateBackend(options?.language?.label)}
              infoTooltip={additionalInfo(options?.language?.additionalInfo)}
              fullWidth
              value={userData.language}
              onFocus={() => setFocused([...focused, 'language'])}
              error={error('language')}
            >
              <Radio data-testid='language-select-english' value='ENGLISH' disabled={loading || options?.language?.required === 'NOT_EDITABLE'} label={t('edit.languageOptions.ENGLISH')} />
              <Radio data-testid='language-select-french' value='FRENCH' disabled={loading || options?.language?.required === 'NOT_EDITABLE'} label={t('edit.languageOptions.FRENCH')} />
            </RadioGroup>
          </Grid>
        )}
        <CustomFieldRenderer
          customOptions={customOptionSelected}
          customData={userData}
          update={update}
          grid={grid}
          focused={focused}
          setFocused={setFocused}
          loading={loading}
        />
      </Grid>
      {options?.termsAndConditionsText?.en?.trim() && (
        <Typography data-testid='terms-and-condition' variant='bodyLarge' component='div' sx={{
          mb: 3, mt: 3, table: { width: '100%' }, display: 'flex', alignItems: 'center',
        }}>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{options?.termsAndConditionsText?.en}</ReactMarkdown>
          {additionalInfo(options?.termsAndConditionsText?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.termsAndConditionsText?.additionalInfo) ?? ''} />)}
        </Typography>
      )}
      <Box display='flex' justifyContent='end'>
        <Button data-testid="confirm-button" label={t(updateMode ? 'update' : 'continue')}
          disabled={loading || (!updated && updateMode) || physicalAddressInvalid}
          sx={{ mt: 3, textAlign: 'center' }} type='submit' />
      </Box>
    </Form>
  );
};

export default PersonalInformationVisual;
