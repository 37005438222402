import { ReactNode, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { Box, Typography } from '../../1-primative';
import { Dialog, DialogContent, DialogTitle } from '../dialog/dialog';
import { IconButton } from '../iconButton/iconButton';
import { Hexcode } from '../../0-tokens';

interface InfoButtonProps {
  items: Item[],
  color?: Hexcode,
  title: string,
}

interface Item {
  show: boolean,
  information: string | ReactNode,
  title: string,
}

const InfoButton = ({ items, color, title }: InfoButtonProps) => {
  const { sys } = useThemeTokens();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box sx={{ color: color ?? sys.color.onSurfaceVariant, cursor: 'pointer' }} onClick={() => setOpen(true)}>
        <InfoOutlinedIcon fontSize='small' />
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth>
        <DialogTitle>
          <Box display='flex' justifyContent='space-between' width='100%' alignItems='center'>
            <Typography variant='headingSmall' sx={{ mr: 0.5 }}>{title}</Typography>
            <IconButton onClick={() => setOpen(false)} aria-label="close" size='medium'><CloseIcon /></IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {items.filter((item) => item.show).map((elem: Item, index: number) => (
            <Box sx={{ mt: index > 0 ? 2 : 0 }} key={index}>
              <Typography variant='bodyLarge' sx={{ fontWeight: 500, color: sys.color.onSurface }}>{elem.title}</Typography>
              <Typography variant='bodyMedium' sx={{ mt: 1, fontWeight: 400, color: sys.color.onSurface }}>{elem.information}</Typography>
            </Box>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InfoButton;
