import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNull } from 'lodash/fp';
import { Box } from '../../../../1-primative';
import { Dialog, DialogContent, DialogTitle } from '../../../../2-component';
import { StateStepper } from '../../../../3-pattern';
import SubTradeRequestsTable from '../../portfolio/components/subTradeRequestsTable';

enum BulkTradeRequestStates {
  DRAFT = 'DRAFT',
  REQUESTED = 'REQUESTED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}
export const BulkTradeDialog = ({
  bulkTradeRequest, open, setOpen,
}: {
  bulkTradeRequest: any, open: boolean, setOpen: (o: boolean) => void,
}) => {
  const { t } = useTranslation(['bulkTrader']);
  const [activeStep, setActiveStep] = useState(0);

  const activeStepFinder = (state: string) => {
    switch (state) {
      case BulkTradeRequestStates.DRAFT:
        return 0;
      case BulkTradeRequestStates.REQUESTED:
        return 1;
      case BulkTradeRequestStates.COMPLETED:
        return 2;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (bulkTradeRequest && bulkTradeRequest.stateChanges) {
      let step = activeStepFinder(bulkTradeRequest.state);
      if (isNull(step)) {
        step = activeStepFinder(bulkTradeRequest.stateChanges.slice(-1)[0].from);
      }
      setActiveStep(step || 0);
    }
  }, [bulkTradeRequest]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth='md' fullWidth>
      <DialogTitle onClose={() => setOpen(false)}>
        {t('dialog.title')}
      </DialogTitle>
      <DialogContent>
        <Box pt={2} pb={2}>
          <StateStepper
            steps={[{ name: 'DRAFT' }, { name: 'REQUESTED' }, { name: 'COMPLETED' }]}
            failed={[BulkTradeRequestStates.CANCELED].includes(bulkTradeRequest?.state)}
            activeStep={activeStep}
            currentState={bulkTradeRequest?.state}
            stateChanges={[{ to: BulkTradeRequestStates.DRAFT, createdAt: bulkTradeRequest?.createdAt }, ...(bulkTradeRequest?.stateChanges ?? [])]}
          />
        </Box>
      </DialogContent>
      { bulkTradeRequest?.id && (<SubTradeRequestsTable id={bulkTradeRequest.id} edittable={false} />)}
    </Dialog>
  );
};
