import {
  useContext, useEffect, useState, useRef,
} from 'react';
import { useReactToPrint } from 'react-to-print';
import { CircularProgress } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ViewClientReportVisual } from './viewClientReport.visual';
import { ClientPageInterface } from '../pageConfiguration/pageConfiguration';
import { Box } from '../../1-primative';
import { ClientContext } from '../../../pages/client';
import { Organization } from '../../../interfaces';
import { UserContext } from '../../../providers/userContextProvider';
import { getBackendLanguage } from '../../../assets/i18n/config';
import ClientReportContextProvider from '../../../providers/clientReportContextProvider';
import { PrintReport } from './components/printReport';

const FETCH_CLIENT_REPORT = gql`
  query fetchClientReportTemplate($clientReportTemplateId: ObjectID!) {
    fetchClientReportTemplate(clientReportTemplateId: $clientReportTemplateId) {
      clientReportTemplate {
        translatedName { ${getBackendLanguage()} }
        translatedDescription { ${getBackendLanguage()} }
        reportType
        state
        type
        objectType
        s3key { ${getBackendLanguage()} }
        scheduler {
          frequency
          dayOfMonthOrWeek
          triggerRules {
            field comparison value
          }
        }
        clientReportTemplatePageConfiguration {
          id
          type
          widgets {
            id
            type
            options
          }
          options
        }
        organization { id }
      }
    }
  }
`;

const FETCH_BASE_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        completedAt
        statistics {
          marketValueCents
        }
        organization {
          id
        }
      }
    }
  }
`;

export const ViewClientReport = () => {
  const [clientReport, setClientReport] = useState<ClientPageInterface | undefined>();
  const [printLoading, setPrintLoading] = useState(true);
  const { clientReportTemplateId, objectId, userId: paramsUserId } = useParams<{ clientReportTemplateId: string, objectId?: string, userId?: string }>();
  const { activeEntity } = useContext(UserContext);
  const userId = paramsUserId ?? activeEntity?.id;
  const componentRef = useRef(null);
  const onPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { data, loading } = useQuery(FETCH_CLIENT_REPORT, {
    variables: { clientReportTemplateId },
  });

  const { data: userData, loading: userLoading } = useQuery(FETCH_BASE_USER, {
    variables: { userId: objectId || userId },
    skip: !objectId && !userId,
  });

  useEffect(() => {
    if (data) {
      setClientReport(data.fetchClientReportTemplate.clientReportTemplate);
    }
  }, [data]);

  if (loading || userLoading || !clientReport) {
    return (
      <Box sx={{
        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',
      }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ClientReportContextProvider>
      <ClientContext.Provider value={{
        orgSettings: userData?.fetchUser?.user?.organization as Organization,
        totalMarketValueCents: userData?.fetchUser?.user?.statistics?.marketValueCents ?? 0,
      }}>
        <ViewClientReportVisual clientReport={clientReport} onPrint={onPrint} printLoading={printLoading} />
        <Box sx={{ height: 0, overflow: 'hidden' }}>
          <div ref={componentRef}>
            <PrintReport clientReport={clientReport} setLoading={setPrintLoading}/>
          </div>
        </Box>
      </ClientContext.Provider>
    </ClientReportContextProvider>
  );
};
