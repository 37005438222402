/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { useParams } from 'react-router-dom';
import { translateBackend } from 'assets/i18n/config';
import { round } from 'lodash';
import { invalidFields } from '../utils';
import {
  Button, Typography, Form, Box, SubAccountSelect, AmountField, TextField, SelectField, MenuItem, AccountTypeSelect, DateField, PageObjectType,
} from '../../../..';
import { TransferContext } from '../../../depositWorkflow/depositWorkflow';
import { BankAccountSelectField } from '../../../../3-pattern/bankAccountSelectField/bankAccountSelect';
import { BankSelectField } from '../../../../3-pattern/bankSelectField/bankSelect';
import { usePermissions, UserContext } from '../../../../../providers/userContextProvider';
import { validateTransfer, ValidateTransferResponse } from '../../../../accountRestrictions';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { TransferErrorBanner } from '../../../../transferErrorBanner';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';

interface DepositAccountConfigInterface {
  [key: string]: {
    endOfPriorFiscalYear: Dayjs,
  }
}

const iraEndOfPriorFiscalYear = dayjs().date(15).month(3).endOf('day');

export const depositAccountConfig: DepositAccountConfigInterface = {
  USA_INH_IRA: {
    endOfPriorFiscalYear: iraEndOfPriorFiscalYear,
  },
  USA_INH_RT_IRA: {
    endOfPriorFiscalYear: iraEndOfPriorFiscalYear,
  },
  USA_IRA: {
    endOfPriorFiscalYear: iraEndOfPriorFiscalYear,
  },
  USA_RO_IRA: {
    endOfPriorFiscalYear: iraEndOfPriorFiscalYear,
  },
  USA_RT_IRA: {
    endOfPriorFiscalYear: iraEndOfPriorFiscalYear,
  },
  USA_SEP_IRA: {
    endOfPriorFiscalYear: iraEndOfPriorFiscalYear,
  },
};

export const showFiscalYear = (accountType: string) => {
  const today = dayjs();
  const firstDayOfYear = dayjs().startOf('year');
  const config = depositAccountConfig[accountType];
  if (config) {
    return today.isBefore(config.endOfPriorFiscalYear) && today.isAfter(firstDayOfYear);
  }
  return false;
};

export const DepositForm = ({
  options, onNext,
}: {
  options: any, onNext: any,
}) => {
  const lockedInAccounts = ['LIRA', 'LRSP', 'RLSP'];
  const incomeFundAccounts = ['RRIF', 'RIF_SPOUSAL', 'LIF', 'PRIF', 'LRIF', 'RLIF'];
  const { t } = useTranslation('workflowCompletions');
  const { showToast } = useGlobalToast();
  const { activeOrganization, userContext } = useContext(UserContext);
  const enableInKind: boolean = activeOrganization.availableFeatureFlags?.includes('IN_KIND_TRANSFERS') ?? false;
  const { transferData, setTransferData } = useContext(TransferContext);
  const { permissions } = usePermissions();
  const [focused, setFocused] = useState<string[]>([]);
  const [disableForm, setDisableForm] = useState<boolean>(false);
  const { userId: paramsUserId } = useParams();
  const { sys } = useThemeTokens();
  const { activeEntity } = useContext(UserContext);
  const userId = paramsUserId ?? activeEntity?.id;
  const minRecurringDeposit = (activeOrganization.minRecurringDepositCents && (activeOrganization.minRecurringDepositCents));
  const checkMinimumRecurringDeposit: boolean = (
    transferData.amountCents
    && minRecurringDeposit
    && (transferData.amountCents < minRecurringDeposit)
    && transferData.schedule !== 'ONE_TIME') || false;
  const hyperLinkColor = sys.color.negativeOutline;
  const supportUrl = userContext.organization?.supportUrl || 'https://onevest.zendesk.com/hc/en-us/requests/new';
  const currentYear = dayjs().year();

  useEffect(() => {
    if (transferData.schedule !== 'ONE_TIME') {
      setTransferData({ ...transferData, fiscalYear: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferData.schedule, setTransferData]);
  const submit = () => {
    const fields = invalidFields({
      ...options,
      bankAccount: {
        ...options.bankAccount,
        requiredIf: (data: any) => data?.type === 'EFT',
      },
      fromSubAccount: {
        ...options.fromSubAccount,
        requiredIf: (data: any) => data?.type === 'INTERNAL_TRANSFER',
      },
      institution: {
        ...options.institution,
        requiredIf: (data: any) => data?.type === 'EXTERNAL_TRANSFER',
      },
      accountNumber: {
        ...options.accountNumber,
        requiredIf: (data: any) => data?.type === 'EXTERNAL_TRANSFER',
      },
      accountType: {
        ...options.accountType,
        requiredIf: (data: any) => data?.type === 'EXTERNAL_TRANSFER',
      },
      transferType: {
        ...options.transferType,
        requiredIf: (data: any) => data?.type === 'EXTERNAL_TRANSFER',
      },
      scheduledDate: {
        ...options.scheduledDate,
        requiredIf: (data: any) => data?.schedule !== 'ONE_TIME',
      },
    }, transferData);
    if (fields.length > 0) {
      setFocused([...fields]);
      return;
    }
    const transferValidation: ValidateTransferResponse = validateTransfer({
      accountTo: transferData?.subAccount?.account?.type ?? '',
      transferType: transferData.type === 'EFT' ? 'electronicFundsTransferIn' : transferData.type === 'EXTERNAL_TRANSFER' ? 'externalTransfer' : 'internalTransferTo',
      accountFrom: transferData?.fromSubAccount?.account?.type,
      canOverrideTransferRestrictions: permissions.includes('write:override_transfer_restrictions'),
    });
    if (!transferValidation.isValid) {
      showToast({ message: transferValidation.message, severity: 'error' });
      return;
    }
    if (fields.length === 0) {
      onNext();
    }
  };

  const subAccountFilter = () => {
    switch (transferData.objectType) {
      case PageObjectType.INDIVIDUAL:
        return { userId: transferData.objectId };
      case PageObjectType.NON_INDIVIDUAL:
        return { userId: transferData.objectId };
      case PageObjectType.ACCOUNT:
        return { accountId: transferData.objectId };
      case PageObjectType.GOAL:
        return { goalId: transferData.objectId };
      case PageObjectType.HOUSEHOLD:
        return { clientGroupId: transferData.objectId };
      default:
        return { userId };
    }
  };
  const handleEftValidation = (e: any) => {
    if ([...lockedInAccounts, ...incomeFundAccounts].includes(e.account.type) && transferData.type === 'EFT') {
      setDisableForm(true);
    } else {
      setDisableForm(false);
    }
  };
  const handleInternalTransferFromValidation = (e: any) => {
    if ([...lockedInAccounts, ...incomeFundAccounts].includes(e.account.type) && transferData.type === 'INTERNAL_TRANSFER') {
      setDisableForm(true);
    } else if ([...lockedInAccounts, ...incomeFundAccounts].includes(transferData?.subAccount?.account?.type)) {
      setDisableForm(true);
    } else {
      setDisableForm(false);
    }
  };
  const handleInternalTransferToValidation = (e: any) => {
    if ([...lockedInAccounts, ...incomeFundAccounts].includes(e.account.type) && transferData.type === 'INTERNAL_TRANSFER') {
      setDisableForm(true);
    } else if ([...lockedInAccounts, ...incomeFundAccounts].includes(transferData?.fromSubAccount?.account?.type)) {
      setDisableForm(true);
    } else {
      setDisableForm(false);
    }
  };
  const isNotComingFromSubAccount = options?.subAccount?.enabled && transferData.objectType !== PageObjectType.SUB_ACCOUNT;
  const getErrorBanner = () => {
    if (lockedInAccounts.includes(transferData?.subAccount?.account?.type) && transferData.type === 'EFT') {
      return (
        <TransferErrorBanner
          showHeader={false}
          sys={sys}
          htmlString={t('transfer:depositEftLockedInAccountError', { color: hyperLinkColor, supportUrl })}
        />
      );
    }
    if (incomeFundAccounts.includes(transferData?.subAccount?.account?.type) && transferData.type === 'EFT') {
      return (
        <TransferErrorBanner
          showHeader={false}
          sys={sys}
          htmlString={t('transfer:depositEftIncomeFundsAccountError', { color: hyperLinkColor, supportUrl })}
        />
      );
    }
    if ([...lockedInAccounts, ...incomeFundAccounts].includes(transferData?.fromSubAccount?.account?.type) && transferData.type === 'INTERNAL_TRANSFER') {
      return (
        <TransferErrorBanner
          showHeader={false}
          sys={sys}
          htmlString={t('transfer:depositInternalTransferFromAccountError', { color: hyperLinkColor, supportUrl })}
        />
      );
    }
    if ([...lockedInAccounts, ...incomeFundAccounts].includes(transferData?.subAccount?.account?.type) && transferData.type === 'INTERNAL_TRANSFER') {
      return (
        <TransferErrorBanner
          showHeader={false}
          sys={sys}
          htmlString={t('transfer:depositInternalTransferToAccountError', { color: hyperLinkColor, supportUrl })}
        />
      );
    }
    return <></>;
  };
  return (
    <Form onSubmit={submit}>
      <Typography variant='displayLarge' sx={{ mt: 1 }}>{translateBackend(options?.title)}</Typography>
      <Typography variant='bodyLarge' sx={{ mb: 3 }}>{translateBackend(options?.subtitle)}</Typography>
      {transferData.type === 'EFT' && (
        <>
          {options?.bankAccount?.enabled && (
            <BankAccountSelectField
              sx={{ mb: 2 }}

              setBankAccount={(e: any) => setTransferData({ ...transferData, bankAccount: e })}
              bankAccount={transferData.bankAccount}
              userId={userId!}
              onBlur={() => setFocused([...focused, 'bankAccount'])}
              error={!transferData.bankAccount?.id && focused.includes('bankAccount') && options?.bankAccount?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.subAccount?.enabled && transferData.objectType !== PageObjectType.SUB_ACCOUNT && (
            <SubAccountSelect
              label={translateBackend(options?.subAccount?.label)}
              onSubAccountSelect={(e: any) => {
                handleEftValidation(e);
                setTransferData({ ...transferData, subAccount: e });
              }}
              selectedSubAccount={transferData.subAccount}
              filter={subAccountFilter()}
              sx={{ mb: 2 }}
              onBlur={() => setFocused([...focused, 'subAccount'])}
              error={!transferData.subAccount?.id && focused.includes('subAccount') && options?.subAccount?.required !== 'NOT_REQUIRED'}
            />
          )}
          {(options?.fiscalYear?.enabled && transferData.schedule === 'ONE_TIME' && showFiscalYear(transferData.subAccount?.account?.type ?? '')) && (
            <SelectField
              testId='fiscal-year-select'
              label={translateBackend(options?.fiscalYear?.label)}
              onChange={(e: any) => setTransferData({ ...transferData, fiscalYear: e.target.value })}
              value={transferData.fiscalYear}
              fullWidth
              sx={{ mb: 2 }}
            >
              <MenuItem value={currentYear - 1}>{currentYear - 1}</MenuItem>
              <MenuItem value={currentYear}>{currentYear}</MenuItem>
            </SelectField>
          )}
          {options?.amountCents?.enabled && (
            <AmountField
              sx={{ mb: 2 }}
              label={translateBackend(options?.amountCents?.label)}
              useFormatAmountValueCents
              setAmount={(e: any) => setTransferData({ ...transferData, amountCents: round(parseFloat(e) * 100, 2) })}
              amount={transferData.amountCents}
              onBlur={() => setFocused([...focused, 'amountCents'])}
              error={(!transferData.amountCents && focused.includes('amountCents') && options?.amountCents?.required !== 'NOT_REQUIRED')
                || checkMinimumRecurringDeposit}
              errorText={(checkMinimumRecurringDeposit && minRecurringDeposit && `${t('components:transferModal.minimumRecurringDepositAmount')} ${minRecurringDeposit / 100} $`) || ''}
            />
          )}
          {options?.schedule?.enabled && (
            <SelectField
              testId='schedule-select'
              label={translateBackend(options?.schedule?.label)}
              onChange={(e: any) => setTransferData({ ...transferData, schedule: e.target.value })}
              value={transferData.schedule}
              fullWidth
              sx={{ mb: 2 }}
              onBlur={() => setFocused([...focused, 'schedule'])}
              error={!transferData.schedule && focused.includes('schedule') && options?.schedule?.required !== 'NOT_REQUIRED'}
            >
              <MenuItem data-testid='schedule-select-one-time' value='ONE_TIME'>{t('schedule.ONE_TIME')}</MenuItem>
              <MenuItem data-testid='schedule-select-weekly' value='WEEKLY'>{t('schedule.WEEKLY')}</MenuItem>
              <MenuItem data-testid='schedule-select-bi-weekly' value='BI_WEEKLY'>{t('schedule.BI_WEEKLY')}</MenuItem>
              <MenuItem data-testid='schedule-select-semi-monthly' value='SEMI_MONTHLY'>{t('schedule.SEMI_MONTHLY')}</MenuItem>
              <MenuItem data-testid='schedule-select-monthly' value='MONTHLY'>{t('schedule.MONTHLY')}</MenuItem>
              <MenuItem data-testid='schedule-select-quarterly' value='QUARTERLY'>{t('schedule.QUARTERLY')}</MenuItem>
            </SelectField>
          )}
          {options?.scheduledDate?.enabled && transferData.schedule !== 'ONE_TIME' && (
            <DateField
              dataTestId='scheduled-date'
              onChange={(date: any) => setTransferData({ ...transferData, scheduledDate: dayjs(date?.toString()).format('YYYY-MM-DD') })}
              label={translateBackend(options?.scheduledDate?.label)}
              minDate={dayjs().add(1, 'day')}
              fullWidth
              value={transferData.scheduledDate}
              error={!transferData.scheduledDate && options?.scheduledDate?.required !== 'NOT_REQUIRED'}
            />
          )}
        </>
      )}
      {transferData.type === 'INTERNAL_TRANSFER' && (
        <>
          {options?.fromSubAccount?.enabled && (
            <SubAccountSelect
              label={translateBackend(options?.fromSubAccount?.label)}
              dataTestId='from-sub-account-select'
              // if this page is navigated from the subAccount page. The subAccount its coming from should have its
              // id assigned to the subAccountsToExclude, so it is not shown in the list of subAccount from, since it is
              // the subAccount money would be transferred into subAccountTo
              subAccountsToExclude={!isNotComingFromSubAccount ? [transferData?.subAccount?.id] : []}
              onSubAccountSelect={(e: any) => {
                handleInternalTransferFromValidation(e);
                if (transferData?.subAccount?.id === e.id) {
                  setTransferData({ ...transferData, subAccount: undefined, fromSubAccount: e });
                } else {
                  setTransferData({ ...transferData, fromSubAccount: e });
                }
              }}
              selectedSubAccount={transferData.fromSubAccount}
              userId={userId}
              sx={{ mb: 2 }}
              onBlur={() => setFocused([...focused, 'fromSubAccount'])}
              error={Object.keys(transferData.fromSubAccount).length === 0 && focused.includes('fromSubAccount') && options?.fromSubAccount?.required !== 'NOT_REQUIRED'}
            />
          )}
          {isNotComingFromSubAccount && (
            <SubAccountSelect
              dataTestId='sub-account-select'
              label={translateBackend(options?.subAccount?.label)}
              onSubAccountSelect={(e: any) => {
                handleInternalTransferToValidation(e);
                if (transferData?.fromSubAccount?.id === e.id) {
                  setTransferData({ ...transferData, fromSubAccount: undefined, subAccount: e });
                } else {
                  setTransferData({ ...transferData, subAccount: e });
                }
              }}
              selectedSubAccount={transferData.subAccount}
              filter={subAccountFilter()}
              sx={{ mb: 2 }}
              onBlur={() => setFocused([...focused, 'subAccount'])}
              error={!transferData.subAccount && focused.includes('subAccount') && options?.subAccount?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.amountCents?.enabled && (
            <AmountField
              sx={{ mb: 2 }}
              label={translateBackend(options?.amountCents?.label)}
              useFormatAmountValueCents
              setAmount={(e: any) => setTransferData({ ...transferData, amountCents: round(parseFloat(e) * 100, 2) })}
              amount={transferData.amountCents}
              onBlur={() => setFocused([...focused, 'amountCents'])}
              error={(!transferData.amountCents && focused.includes('amountCents') && options?.amountCents?.required !== 'NOT_REQUIRED')}
            />
          )}
          {transferData?.fromSubAccount?.statistics?.marketValueCents && transferData.amountCents > (transferData?.fromSubAccount?.statistics?.marketValueCents || 0) * 0.97 ? (
            <Typography variant='labelSmall' color='error' mt={1}>{t('depositReview.amountExceeds')}</Typography>
          ) : (<></>)}
        </>
      )}
      {transferData.type === 'EXTERNAL_TRANSFER' && (
        <>
          {options?.subAccount?.enabled && transferData.objectType !== PageObjectType.SUB_ACCOUNT && (
            <SubAccountSelect
              label={translateBackend(options?.subAccount?.label)}
              onSubAccountSelect={(e: any) => setTransferData({ ...transferData, subAccount: e })}
              selectedSubAccount={transferData.subAccount}
              filter={subAccountFilter()}
              sx={{ mb: 2 }}
              onBlur={() => setFocused([...focused, 'subAccount'])}
              error={!transferData.subAccount && focused.includes('subAccount') && options?.subAccount?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.institution?.enabled && (
            <BankSelectField
              label={translateBackend(options?.institution?.label)}
              setInstitution={(e: any) => setTransferData({ ...transferData, institution: e })}
              institution={transferData.institution}
              fullWidth
              sx={{ mb: 2 }}
              onBlur={() => setFocused([...focused, 'institution'])}
              error={!transferData.institution && focused.includes('institution') && options?.institution?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.accountNumber?.enabled && (
            <TextField
              data-testid='account-number'
              label={translateBackend(options?.accountNumber?.label)}
              onChange={(e: any) => setTransferData({ ...transferData, accountNumber: e.target.value })}
              value={transferData.accountNumber}
              fullWidth
              sx={{ mb: 2 }}
              onBlur={() => setFocused([...focused, 'accountNumber'])}
              error={!transferData.accountNumber && focused.includes('accountNumber') && options?.accountNumber?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.accountType?.enabled && (
            <AccountTypeSelect
              label={translateBackend(options?.accountType?.label)}
              onChange={(e: any) => setTransferData({ ...transferData, accountType: e })}
              value={transferData.accountType}
              size='medium'
              onBlur={() => setFocused([...focused, 'institution'])}
              error={!transferData.institution && focused.includes('institution') && options?.institution?.required !== 'NOT_REQUIRED'}
            />
          )}
          {options?.transferType?.enabled && (
            <SelectField
              testId='transfer-type-select'
              label={translateBackend(options?.transferType?.label)}
              onChange={(e: any) => setTransferData({ ...transferData, transferType: e.target.value })}
              value={transferData.transferType}
              fullWidth
              sx={{ mb: 2, mt: 2 }}
              onBlur={() => setFocused([...focused, 'transferType'])}
              error={!transferData.transferType && focused.includes('transferType') && options?.transferType?.required !== 'NOT_REQUIRED'}
            >
              <MenuItem data-testid='transfer-type-select-all-cash' value='ALL_IN_CASH'>{t('depositReview.ALL_IN_CASH')}</MenuItem>
              <MenuItem data-testid='transfer-type-select-partial-cash' value='PARTIAL_CASH'>{t('depositReview.PARTIAL_CASH')}</MenuItem>
              {enableInKind && (<MenuItem data-testid='transfer-type-select-all-in-kind' value='ALL_IN_KIND'>{t('depositReview.ALL_IN_KIND')}</MenuItem>)}
            </SelectField>
          )}
          {options?.amountCents?.enabled && (
            <AmountField
              label={translateBackend(options?.amountCents?.label)}
              useFormatAmountValueCents
              setAmount={(e: any) => setTransferData({ ...transferData, amountCents: round(parseFloat(e) * 100, 2) })}
              amount={transferData.amountCents}
              onBlur={() => setFocused([...focused, 'amountCents'])}
              error={!transferData.amountCents && focused.includes('amountCents') && options?.amountCents?.required !== 'NOT_REQUIRED'}
            />
          )}
          {transferData?.fromSubAccount?.statistics?.marketValueCents && transferData.amountCents > (transferData?.fromSubAccount?.statistics?.marketValueCents || 0) * 0.97 ? (
            <Typography variant='labelSmall' color='error' mt={1}>{t('depositReview.amountExceeds')}</Typography>
          ) : (<></>)}
        </>
      )}
      {getErrorBanner()}
      <Box display='flex' justifyContent='end'>
        <Button dataTestId='deposit-form-continue-button' disabled={checkMinimumRecurringDeposit || disableForm} label={t('continue')} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
      </Box>
    </Form>
  );
};

export default DepositForm;
