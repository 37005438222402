import { Box, Typography } from '../../../1-primative';
import { Section } from './accountHighlights';

export const AccountHighlightsCustomFields = ({
  section,
}: { section: Section }) => {
  if (section.value) {
    return (
      <Box
        display='flex'
        justifyContent='space-between'
      >
        <Typography variant='bodyMedium' colorVariant='variant' sx={{ mr: 2 }}>{section.label}</Typography>
        <Typography variant='bodyMedium' weight='bold'>{section.value}</Typography>
      </Box>
    );
  }
  return (<></>);
};
