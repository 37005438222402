import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pick } from 'lodash';
import { useQuery } from '@apollo/client';
import {
  DragDropContext, Draggable, Droppable, DroppableProvided,
} from 'react-beautiful-dnd';
import { useGlobalToast } from 'providers/globalToastProvider';
import {
  Box, Button, Card, CardContent, Selector, Skeleton, Typography,
  WidgetInterface,
} from '../../../..';
import { NewWidget } from './newWidget';
import { ClientSelectField } from '../../../../2-component/clientSelectField/clientSelect';
import { ClientContext } from '../../../../../pages/client';
import { FETCH_RELATED_OBJECTS } from './pageConfiguration.queries';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { usePageState } from '../../../../../util/usePageState';
import { ClientReportWidget } from './clientReportWidget';
import { translateBackend } from '../../../../../assets/i18n/config';
import { useClientReportContext } from '../../../../../providers/clientReportContextProvider';

export const PageConfigurationVisual = ({
  page, setPage, update, clientReport,
}: {
  page: any, setPage: (e: any) => void, update: (e: any) => void, clientReport?: any,
}) => {
  const {
    timePeriods, setTimePeriod, timePeriod, setObjectContext,
  } = useClientReportContext();
  const [objectId, setObjectId] = usePageState('', 'objectId');
  const [userId, setUserId] = usePageState('', 'userId');
  const [viewFor, setViewFor] = useState({ id: userId, label: '' });
  const [user, setUser] = useState<any>();
  const [activeTab, setActiveTab] = usePageState(0, 'tab');
  const [widgets, setWidgets] = useState<any>([]);
  const [activeWidget, setActiveWidget] = useState<WidgetInterface>({ type: '', options: {} });
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const { showToast } = useGlobalToast();
  const { t } = useTranslation('pageConfiguration');
  const { sys } = useThemeTokens();

  const getItemStyle = (_isDragging: boolean, draggableStyle: any) => ({
    marginBottom: '20px',
    ...draggableStyle,
  });

  const getListStyle = (_isDraggingOver: boolean) => ({
    background: sys.color.background,
    maxWidth: `${page.options?.maxPageWidth || 1080}px`,
    borderRadius: '4px',
    marginTop: '16px',
  });

  const { data: objectData, loading: objectLoading } = useQuery(FETCH_RELATED_OBJECTS, {
    variables: { userId: viewFor?.id },
    skip: !viewFor?.id,
  });

  useEffect(() => {
    if (objectData) {
      setUser(objectData.fetchUser.user);
      const userInfo = pick(objectData.fetchUser.user, ['id', 'firstName', 'middleName', 'lastName', 'entityName']);
      setViewFor(userInfo as any);
      switch (page.type) {
        case 'INDIVIDUAL':
          setObjectId(objectData.fetchUser.user.id);
          break;
        case 'NON_INDIVIDUAL':
          setObjectId(objectData.fetchUser.user.id);
          break;
        case 'GOAL':
          setObjectId(objectData.fetchUser.user.goals[0]?.id);
          break;
        case 'ACCOUNT':
          setObjectId(objectData.fetchUser.user.accounts[0]?.id);
          break;
        case 'SUB_ACCOUNT':
          setObjectId(objectData.fetchUser.user.subAccounts[0]?.id);
          break;
        case 'HOUSEHOLD':
          setObjectId(objectData.fetchUser.user.households[0]?.id);
          break;
        default:
          setObjectId(objectData.fetchUser.user.id);
          break;
      }
    }
    // eslint-disable-next-line
  }, [objectData, page]);

  useEffect(() => {
    setObjectContext({
      objectType: page.type,
      objectId,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectId, page.type]);

  const onDragEnd = (result: any) => {
    const newColumns = [...widgets];
    if (!result.destination) {
      return;
    }
    const item = newColumns[result.source.index];
    newColumns.splice(result.source.index, 1);
    newColumns.splice(result.destination.index, 0, item);
    setWidgets(newColumns);
  };

  const selectOptions = (item: any) => {
    const refineObject: any = {};
    Object.keys(item).forEach((key: string) => {
      refineObject[key] = item[key];
    });
    return refineObject;
  };

  useEffect(() => {
    setWidgets(page.widgets || []);
  }, [page, activeTab]);

  const onSave = async () => {
    update({
      variables: {
        input: {
          clientReportTemplatePageConfigurationId: clientReport.clientReportTemplatePageConfiguration.id,
          widgets: widgets.map((x: any) => ({
            type: x.type,
            options: selectOptions(x.options),
          })),
        },
      },
      onCompleted: () => (showToast({ severity: 'success', message: t('saveSuccessMessage') })),
    });
  };

  const removeWidget = (i: number) => {
    const uWidgets = [...widgets];
    uWidgets.splice(i, 1);
    setWidgets(uWidgets);
  };

  const removeTab = () => {
    const uPage = { ...page };
    uPage.tabs = uPage.tabs.filter((x: any, i: number) => i !== activeTab);
    setActiveTab(0);
    setPage(uPage);
  };

  return (
    <Box>
      <Card>
        <CardContent>
          <Typography variant='headingMedium'>{t('Page Settings')} - {page.type}</Typography>
          <Box display='flex' flexDirection='row' justifyContent='space-between' mt={3}>
            <Box display='flex' justifyContent='start' alignItems='end'>
              <Button label={t('save')} onClick={onSave} />
            </Box>
            <ClientSelectField
              label={t('viewFor')}
              user={viewFor as any}
              setUser={(item: any) => {
                setViewFor(item);
                setUserId(item?.id);
                if (item === undefined) {
                  setObjectId('');
                  setUser('');
                }
              }}
              width='200px'
            />
          </Box>
        </CardContent>
      </Card>
      { user && timePeriods && timePeriods.length === 0 && !objectLoading ? (
        <Box mt={3}>
          <Card>
            <CardContent sx={{ pb: '1rem !important' }}>
              <Typography variant='headingSmall'>{t('noReports')}</Typography>
            </CardContent>
          </Card>
        </Box>
      ) : objectId && user && !objectLoading && timePeriods && timePeriod && timePeriods.length > 0 ? (
        <Box display='flex' sx={{ flexDirection: { xs: 'column', sm: 'row' } }} justifyContent='start' alignItems='start'>
          <Box maxWidth={`${page.options?.maxPageWidth || 1080}px`} width='100%' mt={3}>
            <Box display='flex' justifyContent='space-between'>
              <Box display='flex' justifyContent='start' alignItems='center'>
                <Typography variant='displayLargeVariant' sx={{ mr: 2 }}>{translateBackend(clientReport.translatedName)}</Typography>
                 <Selector
                  options={timePeriods.map((x: any) => ({ label: x.label, type: x.label }))}
                  selectedOptionType={timePeriod.label}
                  onChange={(e: any) => setTimePeriod(timePeriods.find((x: any) => x?.label === e.target.value) || timePeriods[0])}
                />
              </Box>
            </Box>
            <Typography colorVariant='variant' sx={{ mt: 2, mb: 3 }}>{translateBackend(clientReport.translatedDescription)}</Typography>
            <ClientContext.Provider value={{
              orgSettings: user.organization,
              totalMarketValueCents: user.statistics?.marketValueCents ?? 0,
            }}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={'test'}>
                  {(provided: DroppableProvided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {widgets.map((item: any, i: number) => (
                        <Draggable key={item.id} draggableId={`${item.type}-${JSON.stringify(item.options)}`} index={i}>
                          {(dragProvided: any, dragSnapshot: any) => (
                            <div
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                              {...dragProvided.dragHandleProps}
                              style={getItemStyle(
                                dragSnapshot.isDragging,
                                dragProvided.draggableProps.style,
                              )}
                            >
                              <ClientReportWidget type={item.type} i={i} onDelete={removeWidget} onEdit={() => {
                                setActiveIndex(i);
                                setActiveWidget(item);
                                setOpen(true);
                              }} objectId={objectId} objectType={page.type} options={item.options} userId={objectData.fetchUser.user.id} />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </ClientContext.Provider>
            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='baseline' sx={{ mt: 3, width: '100%' }}>
              <Button label={t('deleteTab')} sx={{ ml: 2, mr: 2 }} onClick={removeTab} variant='tonal' color='destructive' />
              <Box display='flex' flexDirection='row' justifyContent='end' sx={{ mt: 3, width: '100%' }}>
                <Button onClick={() => {
                  setActiveWidget({ type: '', options: {} });
                  setOpen(true);
                }} label={t('addWidget')} variant='tonal' />
                <NewWidget
                  setWidgets={setWidgets}
                  widgets={widgets}
                  objectType={page.type}
                  open={open}
                  setOpen={setOpen}
                  activeWidget={activeWidget}
                  index={activeIndex ?? undefined}
                  onSave={() => {
                    setActiveIndex(null);
                    setActiveWidget({ type: '', options: {} });
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : objectLoading && !user ? (
        <Box mt={3}>
          <Card>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Skeleton variant='rounded' height={'80px'} />
            <Skeleton variant='rounded' height={'40px'} />
            <Skeleton variant='rounded' height={'80px'} />
            <Skeleton variant='rounded' height={'40px'} />
            </CardContent>
          </Card>
        </Box>
      ) : (
        <Box textAlign='center' mt={5}>
          <Typography variant='headingMedium'>{t('selectViewFor')}</Typography>
        </Box>
      )}
    </Box>
  );
};
