/* eslint-disable no-param-reassign */
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState, useContext } from 'react';

import { UserContext } from './userContextProvider';

import en from '../assets/i18n/en';
import fr from '../assets/i18n/fr';
import { Statistics } from '../interfaces';

export enum ObjectType {
  USER = 'Client',
  GOAL = 'Goal',
  ACCOUNT = 'Account',
  SUB_ACCOUNT = 'SubAccount',
  CLIENT_GROUP = 'ClientGroup',
}

const CUSTODIAN_HOLDINGS_ATTRIBUTES = `
  financialProduct {
    id: ticker
    ticker
    isCash
    ric
    translatedName {
      en
      fr
    }
    primaryAssetClass{
      translatedName {
        en
        fr
      }
      translatedDescription {
        en
        fr
      }
      riskLevel
    }
    secondaryAssetClass {
      translatedName {
        en
        fr
      }
      translatedDescription {
        en
        fr
      }
      riskLevel
    }
    tertiaryAssetClass {
      translatedName {
        en
        fr
      }
      translatedDescription {
        en
        fr
      }
      riskLevel
    }
  }
  eodPriceCents
  adjustedCostBaseCents
  totalCents
  currentPriceCents
  quantity
  currency
  originalCurrency {
    currency
    totalCents
    currentPriceCents
    adjustedCostBaseCents
    eodPriceCents
  }
`;

const SHARED_CUSTODIAN_STATS = (fetchHistory = undefined, currency = undefined, fetchProjectedIncome = undefined) => {
  const { custodianConnection } = useContext(UserContext);
  const includeProjectedIncome = !!custodianConnection?.enableFetchCustodianProjectedIncome;
  const includeSnapShotHistory = custodianConnection?.enableFetchCustodianSnapshotHistory;

  return `
  custodianStatistics(input: {${currency ? `currency: ${currency},` : ''} startDate: $startDate, endDate: $endDate}) {
    marketValueCents
    currency
    foreignExchangeRates{
      from
      to
      rate
    }
    simpleReturnAmountCents
    simpleReturnPercent
    moneyWeightedReturn
    timeWeightedReturn
    ${fetchProjectedIncome && includeProjectedIncome ? `
      projectedIncome {
        amountCents
        currency
        date
        financialProduct {
          translatedName {
            en
            fr
          }
        }
      }
    ` : ''}
    holdings {
      ${CUSTODIAN_HOLDINGS_ATTRIBUTES}
    }
    holdingsMissingRate {
      ${CUSTODIAN_HOLDINGS_ATTRIBUTES}
    }
    originalCurrencies {
      currency
      marketValueCents
    }
  }
  ${(fetchHistory && includeSnapShotHistory) ? `custodianSnapshotHistory(input: {${currency ? `currency: ${currency},` : ''} startDate: $startDate, endDate: $endDate}) {
    date
    marketValueCents
    netContributionCents
    currency
  }` : ''}
`;
};

const HOLDINGS_ATTRIBUTES = `
  financialProduct {
    id
    url
    translatedName {
      en
      fr
    }
    ticker
    isCash
    ric
    name
    currentPriceCents
    primaryAssetClass{
      id
      key
      translatedName {
        en
        fr
      }
      translatedDescription {
        en
        fr
      }
    }
    secondaryAssetClass {
      id
      key
      translatedName {
        en
        fr
      }
      translatedDescription {
        en
        fr
      }
    }
    tertiaryAssetClass {
      id
      key
      translatedName {
        en
        fr
      }
      translatedDescription {
        en
        fr
      }
    }
  }
  adjustedCostBaseCents
  totalCents
  expectedTotalCents
  currentPriceCents
  quantity
  currency
  originalCurrency {
    currency
    totalCents
    currentPriceCents
    adjustedCostBaseCents
  }
  accountTaxLots {
    id
    priceCents
    quantity
    date
  }
`;

const SHARED_STATS = (forUserId = undefined) => `
  id
  statistics(input: {currency: $currency},${forUserId ? `forUserId:"${forUserId}"` : ''}) {
    marketValueCents
    moneyAvailableCents
    currency
    foreignExchangeRates{
      from
      to
      rate
    }
    holdingsMissingRate {
      ${HOLDINGS_ATTRIBUTES}
    }
    simpleReturnAmount(startDate: $startDate, endDate: $endDate)
    simpleReturnPercent(startDate: $startDate, endDate: $endDate)
    moneyWeightedReturn(startDate: $startDate, endDate: $endDate)
    timeWeightedReturn(startDate: $startDate, endDate: $endDate)
    netContributionCents
    netContributionCentsInFiscalYear
    newContributionCents
    cashOnHoldToWithdrawCents
    cashOnHoldToTradeCents
    holdings {
      ${HOLDINGS_ATTRIBUTES}
    }
    originalCurrencies {
      currency
      # TODO: Add originalCurrencies.marketValueCents in the stats-service
      # marketValueCents
    }
  }
`;

const USER_STATS = (useCustodianData = undefined, fetchHistory = undefined, currency = undefined, fetchProjectedIncome = undefined) => gql`
  query fetchUserStatistics($userId: ObjectID!, $startDate: Date, $endDate: Date, ${!useCustodianData ? '$currency: StatisticsCurrencyTypes' : ''}) {
    fetchUser(userId: $userId) {
      user {
        ${useCustodianData ? SHARED_CUSTODIAN_STATS(fetchHistory, currency, fetchProjectedIncome) : SHARED_STATS()}
      }
    }
  }
`;

export const GOAL_STATS = gql`
  query fetchGoalStatistics($goalId: ObjectID!, $startDate: Date, $endDate: Date, $currency: StatisticsCurrencyTypes) {
    fetchGoal(goalId: $goalId) {
      goal {
        targetAmountCents
        ${SHARED_STATS()}
      }
    }
  }
`;

export const SUB_ACCOUNT_STATS = gql`
  query fetchSubAccountStatistics($subAccountId: ObjectID!, $startDate: Date, $endDate: Date, $currency: StatisticsCurrencyTypes) {
    fetchSubAccount(subAccountId: $subAccountId) {
      subAccount {
        ${SHARED_STATS()}
      }
    }
  }
`;

export const ACCOUNT_STATS = (useCustodianData = undefined, fetchHistory = undefined, currency = undefined, fetchProjectedIncome = undefined) => gql`
  query fetchAccountStatistics($accountId: ObjectID!, $startDate: Date, $endDate: Date, ${!useCustodianData ? '$currency: StatisticsCurrencyTypes' : ''}) {
    fetchAccount(accountId: $accountId) {
      account {
        ${useCustodianData ? SHARED_CUSTODIAN_STATS(fetchHistory, currency, fetchProjectedIncome) : SHARED_STATS()}
        user {
          statistics {
            marketValueCents
          }
        }
      }
    }
  }
`;

export const CLIENT_GROUP_STATS = (onlyUserId = undefined, useCustodianData = undefined, fetchHistory = undefined, currency = undefined, fetchProjectedIncome = undefined) => gql`
  query fetchClientGroupStatistics($clientGroupId: ObjectID!, $startDate: Date, $endDate: Date, ${!useCustodianData ? '$currency: StatisticsCurrencyTypes' : ''}) {
    fetchClientGroup(clientGroupId: $clientGroupId) {
      clientGroup {
        ${useCustodianData ? SHARED_CUSTODIAN_STATS(fetchHistory, currency, fetchProjectedIncome) : SHARED_STATS(onlyUserId)}
      }
    }
  }
`;

// TODO: add originalCurrency to holdings
const HISTORY_STATS = `
  date
  marketValueCents
  netContributionCents
  holdings {
    totalCents
    financialProduct {
      id
    }
  }
`;

export const FETCH_STATS_HISTORY = gql`
  query fetchStatsHistory($input: FetchStatsHistoryInput!) {
    fetchStatsHistory(input: $input) {
      totalCount
      snapshots {
        ${HISTORY_STATS}
      }
    }
  }
`;

const queries: any = {
  Client: USER_STATS,
  Goal: GOAL_STATS,
  Account: ACCOUNT_STATS,
  SubAccount: SUB_ACCOUNT_STATS,
  ClientGroup: CLIENT_GROUP_STATS,
};

const field: any = {
  Client: 'user',
  Goal: 'goal',
  Account: 'account',
  SubAccount: 'subAccount',
  ClientGroup: 'clientGroup',
};

const dailyTypes: any = {
  Client: 'USERS_DAILY',
  Goal: 'GOALS_DAILY',
  Account: 'ACCOUNTS_DAILY',
  SubAccount: 'SUB_ACCOUNTS_DAILY',
  ClientGroup: 'CLIENT_GROUPS_DAILY',
};

const getQuery = ({
  type, onlyUserId, useCustodianData, fetchHistory, fetchProjectedIncome, currency,
}: {
  type: ObjectType, onlyUserId?: string, useCustodianData?: boolean, fetchHistory?: boolean, fetchProjectedIncome?: boolean, currency?: string,
}) => {
  const acceptCustodianData = [ObjectType.USER, ObjectType.ACCOUNT, ObjectType.CLIENT_GROUP].includes(type);
  if (acceptCustodianData) {
    return type !== ObjectType.CLIENT_GROUP
      ? queries[type](useCustodianData, fetchHistory, currency, fetchProjectedIncome)
      : queries[type](onlyUserId, useCustodianData, fetchHistory, currency, fetchProjectedIncome);
  }
  return queries[type];
};

export const useStats = ({
  type, id, startDate, endDate, onlyUserId, useCustodianData, fetchHistory = true, fetchProjectedIncome, currency,
}: {
  type: ObjectType, id: string, startDate?: string, endDate?: string, onlyUserId?: string, useCustodianData?: boolean, fetchHistory?: boolean, fetchProjectedIncome?: boolean, currency?: string,
}) => {
  if (type !== ObjectType.CLIENT_GROUP && onlyUserId) throw new Error('useStats: Only use onlyUserId with type=CLIENT_GROUP');

  const variables: any = {
    Client: { userId: id },
    Goal: { goalId: id },
    Account: { accountId: id },
    SubAccount: { subAccountId: id },
    ClientGroup: { clientGroupId: id },
  };

  const { data, loading, refetch } = useQuery(
    getQuery({
      type, onlyUserId, useCustodianData, fetchHistory, fetchProjectedIncome, currency,
    }),
    {
      variables: {
        ...variables[type],
        startDate,
        endDate,
        currency,
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  );

  const [fetchStatsHistory, { loading: statsHistoryLoading, refetch: historyRefetch }] = useLazyQuery(FETCH_STATS_HISTORY, {
    onCompleted: (historyData: any) => {
      setHistory(historyData.fetchStatsHistory.snapshots);
    },
  });

  const refetchAll = () => {
    refetch();
    if (!useCustodianData && fetchHistory) historyRefetch();
  };

  const [statistics, setStatistics] = useState<Statistics | null>(null);
  const [history, setHistory] = useState<any>([]);
  const [object, setObject] = useState<any>({});
  useEffect(() => {
    if (data) {
      const firstKey = Object.keys(data)[0];
      const statsObject = data[firstKey][field[type]];
      setObject(statsObject);
      if (useCustodianData) {
        statsObject.custodianStatistics?.holdings?.forEach((holding: any) => {
          if (holding.financialProduct.primaryAssetClass) {
            if (!holding.financialProduct.primaryAssetClass.translatedName) {
              holding.financialProduct.primaryAssetClass.translatedName = {
                en: (en.shared as any).notClassified ?? '',
                fr: (fr.shared as any).notClassified ?? '',
              };
            }
            holding.financialProduct.primaryAssetClass.key = holding.financialProduct.primaryAssetClass.translatedName.en;
            holding.financialProduct.primaryAssetClass.id = holding.financialProduct.primaryAssetClass.translatedName.en;
          }
          if (holding.financialProduct.secondaryAssetClass) {
            if (!holding.financialProduct.secondaryAssetClass.translatedName) {
              holding.financialProduct.secondaryAssetClass.translatedName = {
                en: (en.shared as any).notClassified ?? '',
                fr: (fr.shared as any).notClassified ?? '',
              };
            }
            holding.financialProduct.secondaryAssetClass.key = holding.financialProduct.secondaryAssetClass.translatedName.en;
            holding.financialProduct.secondaryAssetClass.id = holding.financialProduct.secondaryAssetClass.translatedName.en;
          }
          if (holding.financialProduct.tertiaryAssetClass) {
            if (!holding.financialProduct.tertiaryAssetClass.translatedName) {
              holding.financialProduct.tertiaryAssetClass.translatedName = {
                en: (en.shared as any).notClassified ?? '',
                fr: (fr.shared as any).notClassified ?? '',
              };
            }
            holding.financialProduct.tertiaryAssetClass.key = holding.financialProduct.tertiaryAssetClass.translatedName.en;
            holding.financialProduct.tertiaryAssetClass.id = holding.financialProduct.tertiaryAssetClass.translatedName.en;
          }
        });
        if (fetchHistory) setHistory(statsObject.custodianSnapshotHistory);
      } else if (statsObject.statistics.simpleReturnAmount) {
        // The simpleReturnAmount is actually coming in cents.
        // TODO: update history-service to return simpleReturnAmountCents and deprecate simpleReturnAmount
        statsObject.statistics.simpleReturnAmountCents = statsObject.statistics.simpleReturnAmount;
      }
      setStatistics(useCustodianData ? statsObject.custodianStatistics : statsObject.statistics);
    }
  }, [data, type, id, useCustodianData, fetchHistory]);

  useEffect(() => {
    if (!useCustodianData && fetchHistory) {
      fetchStatsHistory({
        variables: {
          input: {
            type: dailyTypes[type],
            id,
            filter: {
              afterDate: startDate,
              beforeDate: endDate,
              userId: onlyUserId,
            },
          },
        },
      });
    }
  }, [type, id, startDate, endDate, onlyUserId, useCustodianData, fetchStatsHistory, fetchHistory]);

  return {
    statistics, loading, history, historyLoading: useCustodianData ? loading : statsHistoryLoading, refetchAll, object,
  };
};
