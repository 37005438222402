import { ClientReportTemplate, ClientReportTemplate as ClientReportTemplateType } from 'interfaces';
import { useTranslation } from 'react-i18next';
import {
  SxProps,
} from '@mui/material';
import AddIcon from '@mui/icons-material/AddRounded';
import {
  Box, Stack, Typography,
} from '../../../1-primative';
import {
  Button,
  Card,
  CardContent,
} from '../../../2-component';
import { useThemeTokens } from '../../../../providers/themeTokenProvider';

export const Rules = ({ template }: { template: ClientReportTemplate }) => {
  const { t } = useTranslation('clientReportTemplate');

  const rules: ClientReportTemplateType['scheduler']['triggerRules'] = template.scheduler.triggerRules || [];

  const baseTokens = useThemeTokens();
  const { sys } = baseTokens;

  const boxStyle: SxProps = {
    p: 2,
    border: `1px solid ${sys.color.outline}`,
    borderRadius: sys.borderRadius.xl,
    // TODO editing
    // cursor: 'pointer',
    // '&:hover': {
    //   borderColor: sys.color.outline,
    //   backgroundColor: sys.color.surfaceContainer,
    // },
  };

  return (
    <Card>
      <CardContent>
        <Stack spacing={1}>
          {rules.map((rule, index) => <>
            <Box key={index} sx={boxStyle}>
              <Typography variant='bodyMedium'>
                {rule.field}
              </Typography>
              <Typography variant='bodyMedium'>
                <span style={{ color: 'gray' }}>
                  {t(`comparisons.${rule.comparison}`)}
                </span>
                &nbsp;
                {`${rule.value}`}
              </Typography>
            </Box>
          </>)}
          <Button variant='filled' disabled
            leadingIcon={AddIcon}
            label={t('addRule')}
            onClick={() => { }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};
