import { Account } from './account';
import { FinancialProduct } from './financialProduct';
import { Goal } from './goal';
import { SubAccount } from './subAccount';
import { User } from './user';

export enum TransactionTypes {
  EFT = 'EFT',
  CANCEL_EFT = 'CANCEL_EFT',
  BUY = 'BUY',
  CANCEL_BUY = 'CANCEL_BUY',
  SELL = 'SELL',
  CANCEL_SELL = 'CANCEL_SELL',
  DIVIDEND = 'DIVIDEND',
  EXTERNAL_TRANSFER_IN = 'EXTERNAL_TRANSFER_IN',
  EXTERNAL_TRANSFER_OUT = 'EXTERNAL_TRANSFER_OUT',
  TRANSFER_IN = 'TRANSFER_IN',
  TRANSFER_OUT = 'TRANSFER_OUT',
  CANCEL_TRANSFER = 'CANCEL_TRANSFER',
  CORPORATE_ACTION = 'CORPORATE_ACTION',
  CANCEL_CORPORATE_ACTION = 'CANCEL_CORPORATE_ACTION',
  MANAGEMENT_FEE = 'MANAGEMENT_FEE',
  CANCEL_MANAGEMENT_FEE = 'CANCEL_MANAGEMENT_FEE',
  BORROW_FEE = 'BORROW_FEE',
  INTEREST = 'INTEREST',
  RISK_EXPOSURE_FEE = 'RISK_EXPOSURE_FEE',
  WITHHOLDING_TAX = 'WITHHOLDING_TAX',
  DEREGISTRATION_FEE = 'DEREGISTRATION_FEE',
  DEREGISTRATION_TAX = 'DEREGISTRATION_TAX',
  GST = 'GST',
  HST = 'HST',
  ADJUSTMENT = 'ADJUSTMENT',
  GOV_CONTRIBUTIONS = 'GOV_CONTRIBUTIONS',
  SWITCH_IN = 'SWITCH_IN',
  CANCEL_SWITCH_IN = 'CANCEL_SWITCH_IN',
  CANCEL_SWITCH_OUT = 'CANCEL_SWITCH_OUT',
  DISTRIBUTION = 'DISTRIBUTION',
  CHEQUE = 'CHEQUE',
  DEPOSIT_BUY = 'DEPOSIT_BUY',
  DISTRIBUTION_REINVESTMENT = 'DISTRIBUTION_REINVESTMENT',
  EXTERNAL_TRANSFER_IN_SECURITY = 'EXTERNAL_TRANSFER_IN_SECURITY',
  SWITCH = 'SWITCH',
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
  INCOME = 'INCOME',
  EXPENSE = 'EXPENSE',
  OTHER = 'OTHER',
  TRANSFER_IN_SECURITY = 'TRANSFER_IN_SECURITY',
  TRANSFER_OUT_SECURITY = 'TRANSFER_OUT_SECURITY',
}

export enum SwitchTransactionTypes {
  SWO_COST = 'SWO_COST',
  SWI_COST = 'SWI_COST',
  SWO_MARKET = 'SWO_MARKET',
  SWI_MARKET = 'SWI_MARKET',
}

export enum SwitchTypes {
  AT_COST = 'AT_COST',
  AT_MARKET = 'AT_MARKET',
  SWO_COST = 'SWO_COST',
  CUSTODY_FEE = 'CUSTODY_FEE',
  INCOME_FUND_TRANSFER = 'INCOME_FUND_TRANSFER',
}

export enum TransferSourcesTypes {
  USER = 'USER',
  REPRESENTATIVE = 'REPRESENTATIVE',
  FEE = 'FEE',
  DEREGISTRATION = 'DEREGISTRATION',
  DEREGISTRATION_TAX = 'DEREGISTRATION_TAX',
  WITHHOLDING_TAX = 'WITHHOLDING_TAX',
  DIVIDEND = 'DIVIDEND',
  TO_SUB_ACCOUNT = 'TO_SUB_ACCOUNT',
  FROM_SUB_ACCOUNT = 'FROM_SUB_ACCOUNT',
  TO_ACCOUNT = 'TO_ACCOUNT',
  FROM_ACCOUNT = 'FROM_ACCOUNT',
  EXTERNAL_TRANSFER_IN = 'EXTERNAL_TRANSFER_IN',
  EXTERNAL_TRANSFER_OUT = 'EXTERNAL_TRANSFER_OUT',
  ADJUSTMENT = 'ADJUSTMENT',
  CUSTODIAN_ACTIVITY = 'CUSTODIAN_ACTIVITY',
  CHEQUE = 'CHEQUE',
  GOV_CONTRIBUTIONS = 'GOV_CONTRIBUTIONS',
  DISTRIBUTION = 'DISTRIBUTION',
}

export enum Currencies {
  CAD = 'CAD',
  USD = 'USD',
}

export enum TransactionObjectTypes {
  TRANSFER = 'Transfer',
  SUB_TRADE = 'SubTrade',
  CUSTODIAN_ACTIVITY = 'CustodianActivity',
  ADJUSTMENT = 'Adjustment',
}

export const TRADE_TYPES = [
  TransactionTypes.BUY,
  TransactionTypes.SELL,
  TransactionTypes.CANCEL_BUY,
  TransactionTypes.CANCEL_SELL,
  TransactionTypes.CANCEL_SWITCH_IN,
  TransactionTypes.CANCEL_SWITCH_OUT,
  TransactionTypes.DEPOSIT_BUY,
  TransactionTypes.DISTRIBUTION_REINVESTMENT,
  TransactionTypes.DIVIDEND,
  TransactionTypes.EXTERNAL_TRANSFER_IN_SECURITY,
  TransactionTypes.TRANSFER_IN_SECURITY,
  TransactionTypes.TRANSFER_OUT_SECURITY,
];

export const POSITIVE_VALUE_TYPES = [
  TransactionTypes.SELL,
  TransactionTypes.CANCEL_BUY,
  TransactionTypes.EXTERNAL_TRANSFER_IN,
  TransactionTypes.TRANSFER_IN_SECURITY,
  TransactionTypes.TRANSFER_IN,
  TransactionTypes.DIVIDEND,
  TransactionTypes.CANCEL_SWITCH_IN,
  TransactionTypes.DISTRIBUTION,
];

export const NEGATIVE_VALUE_TYPES = [
  TransactionTypes.BUY,
  TransactionTypes.CANCEL_SELL,
  TransactionTypes.EXTERNAL_TRANSFER_OUT,
  TransactionTypes.TRANSFER_OUT,
  TransactionTypes.BORROW_FEE,
  TransactionTypes.INTEREST,
  TransactionTypes.RISK_EXPOSURE_FEE,
  TransactionTypes.WITHHOLDING_TAX,
  TransactionTypes.DEREGISTRATION_FEE,
  TransactionTypes.DEREGISTRATION_TAX,
  TransactionTypes.GST,
  TransactionTypes.HST,
  TransactionTypes.CANCEL_SWITCH_OUT,
  TransactionTypes.DEPOSIT_BUY,
  TransactionTypes.EXTERNAL_TRANSFER_IN_SECURITY,
  TransactionTypes.DISTRIBUTION_REINVESTMENT,
  TransactionTypes.TRANSFER_OUT_SECURITY,
];

// These transactions should use the EOD price / current price to define the net/new contributions
export const HOLDING_CONTRIBUTION_TRANSACTION_TYPES = [TransactionTypes.DEPOSIT_BUY, TransactionTypes.EXTERNAL_TRANSFER_IN_SECURITY];

export const ADJUSTED_COST_BASE_TYPES = [
  TransactionTypes.DEPOSIT_BUY,
  TransactionTypes.DISTRIBUTION_REINVESTMENT,
  TransactionTypes.EXTERNAL_TRANSFER_IN_SECURITY,
  TransactionTypes.TRANSFER_IN_SECURITY,
  TransactionTypes.TRANSFER_OUT_SECURITY,
];
export const QUANTITY_PRICE_MULTIPLIERS = [TransactionTypes.DEPOSIT_BUY, TransactionTypes.EXTERNAL_TRANSFER_IN_SECURITY];

export const getValueSign = (type: TransactionTypes, adjustmentType?: 'DEPOSIT' | 'WITHDRAW') => {
  if (POSITIVE_VALUE_TYPES.includes(type)) return 1;
  if (NEGATIVE_VALUE_TYPES.includes(type)) return -1;
  if (adjustmentType) return adjustmentType === 'DEPOSIT' ? 1 : -1;
  return 0;
};

export interface Transaction {
  id: string;
  date?: Date;
  subAccount?: SubAccount;
  account?: Account;
  goal?: Goal;
  user?: User;
  financialProduct?: FinancialProduct;
  type: TransactionTypes;
  quantity?: number;
  priceCents?: number;
  valueCents?: number;
  currency?: Currencies;
  description?: string;
  objectType?: TransactionObjectTypes;
  objectId?: string;
}
