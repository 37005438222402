import { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '../../../1-primative';
import { PrintWidget } from './printWidget';
import { translateBackend } from '../../../../assets/i18n/config';
import { useClientReportContext } from '../../../../providers/clientReportContextProvider';

export interface PrintOptions {
  heightLeftOnLastPage: number,
  loading: boolean,
}

type PrintContextType = {
  options: PrintOptions[],
  setOptions: React.Dispatch<React.SetStateAction<PrintOptions[]>>,
};

export const PrintContext = createContext<PrintContextType>({
  options: [],
  setOptions: () => { },
});

export const PAGE_HEIGHT = 725;

export const calculateTableSplit = ({
  headerHeight, rowHeight, totalRows, footerHeight, pageHeight,
}: {
  headerHeight: number, rowHeight: number, totalRows: number, footerHeight: number, pageHeight: number,
}): { splitRows: number[], heightLeft: number, startOnNew: boolean } => {
  const totalHeight = headerHeight + rowHeight * totalRows + footerHeight;
  let startOnNew = false;

  if (pageHeight < (headerHeight + (totalRows * 2) + footerHeight)) {
    startOnNew = true;
    /* eslint-disable no-param-reassign */
    pageHeight = PAGE_HEIGHT;
  }

  if (totalHeight < pageHeight) {
    return { splitRows: [totalRows], heightLeft: pageHeight - totalHeight, startOnNew };
  }

  const leftOver = pageHeight - headerHeight;
  const rowsPerPage = Math.floor(leftOver / rowHeight);

  const nextPages = calculateTableSplit({
    headerHeight,
    rowHeight,
    totalRows: totalRows - rowsPerPage,
    footerHeight,
    pageHeight: PAGE_HEIGHT - 40, // Allow for continued title
  });

  const splitRows = [rowsPerPage, ...nextPages.splitRows];

  return { splitRows, heightLeft: nextPages.heightLeft, startOnNew };
};

export const PrintReport = ({ clientReport, setLoading }: { clientReport: any, setLoading: (loading: boolean) => void }) => {
  const [options, setOptions] = useState<PrintOptions[]>(clientReport.clientReportTemplatePageConfiguration.widgets.map(() => ({
    heightLeftOnLastPage: 0,
    loading: true,
  })));
  const { timePeriod } = useClientReportContext();

  const {
    clientGroupId, goalId, accountId, subAccountId, objectId, userId,
  } = useParams<{
    clientGroupId: string, goalId: string, accountId: string, subAccountId: string, objectId: string, userId: string,
  }>();

  const id = () => {
    const baseId = subAccountId || accountId || goalId;
    return baseId || (clientGroupId || objectId || userId);
  };

  useEffect(() => {
    if (options.map((x) => x.loading).filter((x) => x === true).length === 0) {
      setLoading(false);
    }
  }, [options, setLoading]);

  return (
    <PrintContext.Provider value={{
      options,
      setOptions,
    }}>
      {timePeriod && <Box width='975px' ml='40px' mt='40px'>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='displayLargeVariant' sx={{ mr: 2 }}>{translateBackend(clientReport.translatedName)}</Typography>
          <Typography variant='headingLarge'>{timePeriod.label}</Typography>
        </Box>
        <Typography colorVariant='variant' sx={{ mt: 2, mb: 3 }}>{translateBackend(clientReport.translatedDescription)}</Typography>
        {clientReport.clientReportTemplatePageConfiguration.widgets?.map((item: any, i: number) => (
          <Box key={`${item.type}-${JSON.stringify(item.options)}-${i}`} sx={{
            marginBottom: '40px',
          }}>
            <PrintWidget
              type={item.type}
              i={i}
              objectId={id() || ''}
              options={item.options}
              objectType={clientReport.clientReportTemplatePageConfiguration.type}
              widgetId={item.id}
              userId={objectId || userId}
            />
          </Box>
        ))}
      </Box>}
    </PrintContext.Provider>
  );
};
